export enum TagManagerEvent {
  LeadPopupOpened = 'LeadPopupOpened',
  LeadSubmitted = 'LeadSubmitted',
  InsuranceSuggestionOpened = 'InsuranceSuggestionOpened',
  ProductOverviewOpened = 'ProductOverviewOpened',
  ProductDetailOpened = 'ProductDetailOpened',
  BasketOpened = 'BasketOpened',
  CheckoutOpened = 'CheckoutOpened',
  CheckoutConfirmationOpened = 'CheckoutConfirmationOpened',
  ApplicationLayoutReady = 'ApplicationLayoutReady',
}

export interface ITagManagerData {
  event: TagManagerEvent;
}

export interface IGoogleTagManager {
  push: (data: ITagManagerData) => void;
}
