import React from 'react';
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function SupportIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path d="M4.715 8.62a2.505 2.505 0 0 1 2.094 2.471v4.051a2.105 2.105 0 0 1-2.106 2.105h-.138a4.33 4.33 0 0 1-4.33-4.33v-.261a4.07 4.07 0 0 1 3.646-4.048c.002-.237.015-.563.053-.95.08-.809.274-1.897.736-2.99.462-1.096 1.197-2.209 2.362-3.047C8.201.781 9.772.238 11.87.238c2.097 0 3.683.543 4.874 1.381 1.188.835 1.956 1.945 2.451 3.038.495 1.093.724 2.179.829 2.988.051.394.074.724.083.962a4.07 4.07 0 0 1 3.658 4.049v.782a3.81 3.81 0 0 1-3.391 3.787v.648c0 2.248-1.822 4.592-4.07 4.592h-1.72a1.717 1.717 0 0 1-1.664 1.297h-1.67a1.7 1.7 0 0 1 0-3.4h1.7c.786 0 1.447.539 1.633 1.268h1.721c1.787 0 3.235-1.97 3.235-3.757v-.626h-.104a1.983 1.983 0 0 1-1.983-1.982V10.83c0-1.095.784-2.006 1.821-2.204-.01-.215-.03-.516-.077-.873a9.803 9.803 0 0 0-.761-2.751c-.45-.993-1.133-1.971-2.171-2.7-1.035-.728-2.448-1.229-4.394-1.229-1.947 0-3.342.501-4.35 1.226-1.012.727-1.663 1.702-2.081 2.693-.419.993-.6 1.993-.674 2.748-.036.361-.047.664-.05.88Zm-.411.801a3.234 3.234 0 0 0-3.234 3.235v.261a3.495 3.495 0 0 0 3.495 3.495h.138a1.27 1.27 0 0 0 1.271-1.27v-4.051a1.67 1.67 0 0 0-1.67-1.67Zm15.392 0a1.41 1.41 0 0 0-1.409 1.409v4.435c0 .633.514 1.147 1.148 1.147h.522a2.973 2.973 0 0 0 2.973-2.974v-.782a3.234 3.234 0 0 0-3.234-3.235ZM11.25 21.197a.865.865 0 0 0 0 1.73h1.67a.88.88 0 0 0 .88-.88.85.85 0 0 0-.85-.85h-1.7Z" />
    </SvgIcon>
  );
}
