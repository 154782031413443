import type {
  IAccount,
  ISendSupportMailDto,
  IUpdateUserDto,
  IUserInfo,
  IUserSettingsRequestDto,
} from '@esurance/entities';

import type { ITokenPayload } from './ITokenPayload';

export class RegistrationIsNotCompletedError extends Error {}

export interface IAccountRepository {
  getAll(token?: string): Promise<IAccount[]>;
  getInfo(token?: string): Promise<IUserInfo>;
  userUpdate(data: IUpdateUserDto): Promise<boolean>;
  turnoffPasswordUpdate(): Promise<boolean>;
  sendSupportMail(data: ISendSupportMailDto, orgId: number): Promise<boolean>;

  getTokenPayload(token: string): Promise<ITokenPayload>;

  updateSettings(data: Partial<IUserSettingsRequestDto>): Promise<boolean>;
}
