import _ from 'lodash';
import { injectable } from 'inversify';

import type { INotification, INotificationManager, NotificationCallback } from './interfaces';

// TODO: ESD-5926: this service requires refactoring
//  Add to DI container, cover with tests

@injectable()
export class NotificationManager implements INotificationManager {
  private itemList: INotification[] = [];

  private activeNotification?: INotification;

  private staticNotification?: INotification;

  private listeners: NotificationCallback[] = [];

  public clear(): void {
    this.itemList = [];
  }

  public setStatic(notification?: INotification): void {
    this.staticNotification = notification;
    this.render();
  }

  public getNotification(): INotification | undefined {
    if (this.activeNotification) {
      return this.activeNotification;
    }
    if (this.staticNotification) {
      return this.staticNotification;
    }
    return undefined;
  }

  public subscribe(listener: NotificationCallback): () => void {
    this.listeners.push(listener);
    return () => this.unsubscribe(listener);
  }

  public raise(item: INotification): void {
    this.add(item);
    this.setTimer(item);
  }

  private setTimer(item: INotification): void {
    this.render();
    if (item.closeable) {
      setTimeout(() => {
        this.remove(item);
        this.render();
      }, 10000);
    }
  }

  private add(item: INotification): void {
    if (!this.activeNotification) {
      this.activeNotification = item;
    }
    this.itemList.push(item);
  }

  private remove(item: INotification): void {
    this.activeNotification = undefined;
    _.remove(this.itemList, (i) => i === item);
    if (this.itemList.length > 0) {
      this.activeNotification = this.itemList.pop();
      if (this.activeNotification) {
        this.setTimer(this.activeNotification);
      }
    }
  }

  private render(): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const listener of this.listeners) {
      listener();
    }
  }

  private unsubscribe(listener: NotificationCallback): void {
    const index = this.listeners.findIndex((value) => value === listener);
    if (index >= 0) {
      this.listeners.splice(index, 1);
      return;
    }
    throw new Error('Listener was not found');
  }
}
