import type {
  INewCustomerFlowData,
  IUpsellingFlowData,
  SessionFlowData,
} from '../repositories/interfaces';

export function isUpsellingFlowData(
  flow: SessionFlowData | null | undefined,
): flow is IUpsellingFlowData {
  return !!flow && flow.type === 'existing_customer';
}

export function isNewCustomerFlowData(
  flow: SessionFlowData | null | undefined,
): flow is INewCustomerFlowData {
  return !!flow && flow.type === 'new_customer';
}
