import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type MenuItemStyles = ComponentsOverrides['MuiMenuItem'];

export function createMuiMenuItemStyles(
  overrides?: Partial<MenuItemStyles>,
): Partial<MenuItemStyles> {
  return {
    root: {
      fontSize: '16px',
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
