import React from 'react';
import _ from 'lodash';
import type { Theme } from '@mui/material';
import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SxProps } from '@mui/system';

function format(
  value: string | number,
  precision = 2,
): string {
  const parts = (value || '0').toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
  // precision
  if (parts.length === 1) {
    parts.push('');
  } else if (parts[1].length > precision) {
    const decimal = _.round(parseFloat(`0.${parts[1]}`), precision);
    // eslint-disable-next-line prefer-destructuring
    parts[1] = decimal.toString().split('.')[1];
  }
  parts[1] = _.padEnd(parts[1], precision, '0');
  return precision !== 0 ? parts.join('.') : parts[0];
}

export enum MoneyWithCurrencyVariant {
  H3Title = 'H3Title',
  H6Title = 'H6Title',
  Text = 'Text',
  Caption = 'Caption',
  Body2 = 'Body2',
  None = 'None',
}

export interface IMoneyWithCurrencyProps {
  value: string | number;
  variant?: MoneyWithCurrencyVariant;
  dataTest?: string;
  prefix?: string;
  sx?: SxProps<Theme>;
}

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
});

export function MoneyWithCurrency(
  {
    value,
    variant = MoneyWithCurrencyVariant.Text,
    dataTest,
    prefix,
    sx,
  }: IMoneyWithCurrencyProps,
): JSX.Element {
  const [translate] = useTranslation();

  let typographyVariant: 'body1' | 'h3' | 'h6' | 'caption' | 'body2' | undefined = 'body1';

  if (variant === MoneyWithCurrencyVariant.H3Title) {
    typographyVariant = 'h3';
  } else if (variant === MoneyWithCurrencyVariant.H6Title) {
    typographyVariant = 'h6';
  } else if (variant === MoneyWithCurrencyVariant.Caption) {
    typographyVariant = 'caption';
  } else if (variant === MoneyWithCurrencyVariant.Body2) {
    typographyVariant = 'body2';
  } else if (variant === MoneyWithCurrencyVariant.None) {
    typographyVariant = undefined;
  }

  const formattedValue = format((value || '0').toString().replace(',', ''));

  const currencyFontSize = variant === MoneyWithCurrencyVariant.H3Title
    ? '50%'
    : '0.9rem';

  return (
    <StyledTypography variant={typographyVariant} data-test={dataTest} sx={sx}>
      <Typography fontSize={currencyFontSize} display="inline-block">
        {prefix && (
          `${translate(prefix)} `
        )}
        {translate('currency')}
      </Typography>
      {' '}
      {formattedValue}
    </StyledTypography>
  );
}
