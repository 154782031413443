export function createFixedLengthArray<T>(
  array: T[],
  defaultValue: T,
  requiredLength: number,
): readonly T[] {
  const copy = array.slice(0, requiredLength);

  for (let i = 0; i < requiredLength; i += 1) {
    if (copy[i] === undefined) {
      copy[i] = defaultValue;
    }
  }
  return copy;
}
