import type { Container } from 'inversify';

import type { ITranslatable } from '@esurance/entities';
import type { IApplicationConfigRepository } from '@esurance/constants';
import {
  initConstants as initBaseConstants,
  ApplicationConfigRepository,
} from '@esurance/constants';
import { getTranslatable } from '@esurance/shared';

import type { IAffinityEnvironment } from '../IAffinityEnvironment';

import { ConstantDependency } from './ConstantDependency';

export async function initConstants(
  inversifyContainer: Container,
  config: IAffinityEnvironment,
  applicationConfigRepository: IApplicationConfigRepository = new ApplicationConfigRepository(
    config.ApplicationConfigUrl,
  ),
): Promise<void> {
  await initBaseConstants(inversifyContainer, config, applicationConfigRepository);

  inversifyContainer.bind<string>(ConstantDependency.AffinityName)
    .toConstantValue(config.AffinityName);

  inversifyContainer.bind<number>(ConstantDependency.AffinityBmi)
    .toConstantValue(config.AffinityBmi);

  inversifyContainer.bind<string>(ConstantDependency.AffinityLabel)
    .toConstantValue(config.AffinityLabel);

  inversifyContainer.bind<string>(ConstantDependency.AffinityLogoLink)
    .toConstantValue(config.AffinityLogoLink || '');

  inversifyContainer.bind<string>(ConstantDependency.AffinityLoginUrl)
    .toConstantValue(config.AffinityLoginUrl || '');

  inversifyContainer.bind<ITranslatable>(ConstantDependency.AffinityDseLink)
    .toConstantValue(config.AffinityDseLink || getTranslatable(''));

  inversifyContainer.bind<ITranslatable>(ConstantDependency.AffinityZvLink)
    .toConstantValue(config.AffinityZvLink || getTranslatable(''));

  inversifyContainer.bind<boolean>(ConstantDependency.IsLoginRequiredForCheckout)
    .toConstantValue(config.IsLoginRequiredForCheckout || false);

  inversifyContainer.bind<string>(ConstantDependency.ConsultantHeaderName)
    .toConstantValue(config.ConsultantHeaderName || 'X-Consultant');
}
