import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';

import type { IRecaptchaManager, RecaptchaAction } from './interfaces';

@injectable()
export class RecaptchaManager implements IRecaptchaManager {
  constructor(
    @inject(ConstantDependency.RecaptchaSiteKey)
    private recaptchaSiteKey: string,
  ) { }

  public init(): void {
    if (!this.recaptchaSiteKey) {
      return;
    }
    const recaptureScript = document.createElement('script');
    recaptureScript.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`;
    document.head.appendChild(recaptureScript);
  }

  public async execute(action?: RecaptchaAction): Promise<string | undefined> {
    if (!window.grecaptcha) {
      return undefined;
    }

    const { recaptchaSiteKey } = this;

    try {
      await waitForGrecaptchaReady();
      return await window.grecaptcha
        .execute(recaptchaSiteKey, action ? { action } : undefined);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}

function waitForGrecaptchaReady(): Promise<void> {
  return new Promise<void>(((resolve) => {
    window.grecaptcha.ready(resolve);
  }));
}
