import React from 'react';
import { resolve } from 'inversify-react';

import { EventName, IEventManager, DependencyType } from '@esurance/services';

interface IProps {
  toggle?: boolean;
}
/* TODO:
 * Remove all this
 * Use https://github.com/reactjs/react-modal
 * Use https://reactjs.org/docs/portals.html
 * Add to technical debt to kick out this terrible thing
 */
export class ActiveModalRenderer extends React.Component<IProps> {
  public static defaultProps: IProps = {
    toggle: false,
  };

  @resolve(DependencyType.EventManager)
  private eventManager: IEventManager;

  private modal?: typeof React.Component;

  public registerEventSubscribers(): void {
    this.eventManager.on(EventName.OpenModal, this.setModal.bind(this));
    this.eventManager.on(EventName.CloseModal, () => this.setModal());
    this.eventManager.on(EventName.UnauthorizedError, ActiveModalRenderer.restoreBody);
  }

  public unRegisterEventSubscribers(): void {
    this.eventManager.removeListener(EventName.OpenModal, this.setModal.bind(this));
    this.eventManager.removeListener(EventName.CloseModal, () => this.setModal());
    this.eventManager.removeListener(EventName.UnauthorizedError, ActiveModalRenderer.restoreBody);
  }

  public componentDidMount(): void {
    this.registerEventSubscribers();
  }

  public componentDidUnMount(): void {
    this.unRegisterEventSubscribers();
  }

  public render(): JSX.Element | null {
    const ActiveModal = this.modal;
    return ActiveModal ? (<ActiveModal />) : null;
  }

  private setModal(modal?: typeof React.Component): void {
    const { toggle } = this.props;
    this.modal = modal;
    const visible = !!modal;
    this.forceUpdate();
    if (toggle) {
      this.handleToggle(visible);
    }
  }

  private static get rootElement(): HTMLElement | null {
    return document.getElementById('root');
  }

  private handleToggle = (visible: boolean): void => {
    if (visible) {
      if (ActiveModalRenderer.rootElement) {
        ActiveModalRenderer.rootElement.style.overflow = 'hidden';
      }
    } else {
      ActiveModalRenderer.restoreBody();
    }
  };

  private static restoreBody(): void {
    ActiveModalRenderer.rootElement?.removeAttribute('style');
  }
}
