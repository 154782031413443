import React from 'react';
import _ from 'lodash';

import { QuestionTypes } from '@esurance/legacy-constants';

import type { IBaseProps } from './base';
import QuestionText from './text';
import QuestionList from './list';
import QuestionListText from './list-text';
import PeopleName from './people-name';
import PeopleQuestion from './people-question';
import PeopleOption from './people-option';

interface IProps extends IBaseProps {
  names: string[];
}

export class Question extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const {
      item,
      onChange,
      repeaterAnswer,
      names,
    } = this.props;

    switch (item.type) {
    case QuestionTypes.Input:
      return <QuestionText item={item} onChange={onChange} />;
    case QuestionTypes.Options:
      return <QuestionList item={item} onChange={onChange} />;
    case QuestionTypes.OptionsInput:
      return <QuestionListText item={item} onChange={onChange} />;
    case QuestionTypes.PeopleNameQuestion:
      return (
        <PeopleName
          item={item}
          repeaterAnswer={_.toNumber(repeaterAnswer)}
          onChange={onChange}
        />
      );
    case QuestionTypes.PeopleQuestion:
      return (
        <PeopleQuestion
          item={item}
          names={names}
          onChange={onChange}
        />
      );
    case QuestionTypes.PeopleOption:
      return (
        <PeopleOption
          item={item}
          names={names}
          onChange={onChange}
        />
      );
    default:
      return <div>Something went wrong</div>;
    }
  }
}

export default Question;
