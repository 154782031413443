import type { FieldValue, IValidator } from '../interfaces';

export class NumberValidator implements IValidator {
  constructor(public errorMessage: string = 'input_number-invalid-message') {}

  public validate(value: FieldValue): boolean {
    if (!value) {
      return false;
    }
    const valueIsFullyNumber = /^[0-9]*$/.test(value);
    const valueTypeIsNumber = valueIsFullyNumber && typeof parseInt(value, 10) === 'number';

    if (!value || !valueIsFullyNumber || !valueTypeIsNumber) {
      return false;
    }

    return true;
  }
}
