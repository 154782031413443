import type { Container } from 'inversify';

import type { ICompanySuggestionRepository } from '../repositories';
import { CompanySuggestionRepository } from '../repositories';

import { DependencyType } from './DependencyType';

export function configureContainer(container: Container): void {
  container
    .bind<ICompanySuggestionRepository>(DependencyType.CompanySuggestionRepository)
    .to(CompanySuggestionRepository);
}
