import axios from 'axios';

import type { IApplicationConfig } from '../models';

import type { IApplicationConfigDto, IApplicationConfigRepository } from './interfaces';
import { parseApplicationDto } from './parseApplicationDto';

export class ApplicationConfigRepository implements IApplicationConfigRepository {
  public constructor(
    private applicationConfigUrl: string,
  ) {}

  public async get(): Promise<IApplicationConfig> {
    const dto: IApplicationConfigDto = (await axios.get(this.applicationConfigUrl)).data;
    return parseApplicationDto(dto);
  }
}
