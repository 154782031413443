import React from 'react';
import { resolve } from 'inversify-react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { DependencyType, INotificationManager } from '@esurance/services';

import { Alert } from '../container/alert';

export class NotificationRendererComponent extends React.Component<WithTranslation> {
  private unsubscribe?: () => void;

  @resolve(DependencyType.NotificationManager)
  private notificationManager: INotificationManager;

  public componentDidMount(): void {
    this.unsubscribe = this.notificationManager.subscribe(() => this.forceUpdate());
  }

  public componentWillUnmount(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  public render(): JSX.Element | null {
    const { t: translate } = this.props;
    const notification = this.notificationManager.getNotification();
    if (notification) {
      const content = typeof notification.content === 'string'
        ? translate(notification.content, notification.content)
        : notification.content;

      return (
        <Alert type={notification.type} notification onTop={notification.onTop}>
          {content}
        </Alert>
      );
    }
    return null;
  }
}

export const NotificationRenderer = withTranslation()(NotificationRendererComponent);
