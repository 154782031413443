export const DependencyType = {
  SentryManager: Symbol.for('SentryManager'),

  TranslationManager: Symbol.for('TranslationManager'),

  EventManager: Symbol.for('EventManager'),

  SocketManager: Symbol.for('SocketManager'),

  NotificationManager: Symbol.for('NotificationManager'),

  ConfigRepository: Symbol.for('ConfigRepository'),

  AccountRepository: Symbol.for('AccountRepository'),

  SurveyRepository: Symbol.for('SurveyRepository'),

  ConstantRepository: Symbol.for('ConstantRepository'),

  AxiosManager: Symbol.for('AxiosManager'),

  LocalStorageManagerFactory: Symbol.for('LocalStorageManagerFactory'),

  UserAgentManager: Symbol.for('UserAgentManager'),

  HistoryManager: Symbol.for('HistoryManager'),

  LocalStorage: Symbol.for('LocalStorage'),

  GoogleTagManager: Symbol.for('GoogleTagManager'),

  RecaptchaManager: Symbol.for('RecaptchaManager'),

  URLTrackingParametersManager: Symbol.for('URLTrackingParametersManager'),

  FeatureManager: Symbol.for('FeatureManager'),
} as const;
