import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type AutocompleteStyles = ComponentsOverrides['MuiAutocomplete'];

export function createMuiAutocompleteStyles(
  overrides?: Partial<AutocompleteStyles>,
): Partial<AutocompleteStyles> {
  return {
    inputRoot: {
      ...(overrides?.inputRoot as object),
    },
    listbox: {
      fontSize: '16px',
      ...(overrides?.listbox as object),
    },
    ...overrides,
  };
}
