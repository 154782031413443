import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import './TextArea.styl';

export interface ITextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'prefix'> {
  prefix?: React.ReactNode;
}

export const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>((props, ref) => {
  const {
    className: containerClassName, prefix = null, onFocus, onBlur, ...rest
  } = props;
  const [isFocused, setFocused] = useState(false);

  const textareaContainerClassName = classNames('es-textarea-container', containerClassName, {
    focused: isFocused,
  });

  const prefixElement = prefix && (
    <div className="es-textarea-prefix">
      {prefix}
    </div>
  );

  return (
    <div className={textareaContainerClassName}>
      {prefixElement}
      <textarea
        ref={ref}
        className="es-textarea"

        onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
          setFocused(true);
          if (onFocus) onFocus(event);
        }}

        onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
          setFocused(false);
          if (onBlur) onBlur(event);
        }}

        {...rest}
      />
    </div>
  );
});
