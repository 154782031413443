import type { AlertProps } from '@mui/material';

export function createMaterialAlertTheme(
  overrides?: Partial<AlertProps>,
): Partial<AlertProps> {
  return {
    icon: false,
    ...overrides,
  };
}
