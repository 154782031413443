import type { ReactNode } from 'react';
import React from 'react';

interface IScrollToTopComponentProps {
  children: ReactNode;
}

export class ScrollToTop extends React.Component<IScrollToTopComponentProps> {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): React.ReactNode {
    const { children } = this.props;
    return children;
  }
}
