import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { _t } from '@esurance/shared/service';
import type { IFormField } from '@esurance/domain-components';

interface IProps {
  model: IFormField<string>;
  id: string;
  type?: string;
  label?: string;
  className?: string;
}

/**
 * @deprecated
 */
@observer
export class Input extends React.Component<IProps> {
  private readonly model: IFormField<string>;

  constructor(props: IProps) {
    super(props);
    this.model = props.model;
  }

  public render(): JSX.Element {
    const {
      id,
      type = 'text',
      label,
      className,
    } = this.props;
    const { model } = this;

    const classes = classNames({
      invalid: model.isInvalid,
      touched: model.isTouched,
    });

    const errorClasses = classNames('error', {
      visible: model.isTouched && model.isInvalid,
    });

    return (
      <fieldset className={className}>
        {label && <label htmlFor={id}>{label}</label>}

        {type !== 'textarea' ? (
          <input
            id={id}
            type={type}
            className={classes}
            value={model.value}
            onChange={(e) => model.change(e.target.value)}
            onBlur={model.touch}
          />
        ) : (
          <textarea
            id={id}
            className={classes}
            onChange={(e) => model.change(e.target.value)}
            onBlur={model.touch}
            value={model.value}
          />
        )}

        <div className={errorClasses}>
          {model.isInvalid && model.errorMessage && _t(model.errorMessage)}
        </div>
      </fieldset>
    );
  }
}
