import React from 'react';
import { Field } from 'react-final-form';

import { _t } from '@esurance/shared/service';
import { RadioGroup } from '@esurance/shared/component/form';
import { FormItem } from '@esurance/ui-components';
import { Salutation } from '@esurance/legacy-constants';

import { OwnerIcon } from '../OwnerIcon/OwnerIcon';

interface IProps {
  name: string;
  validate?: (value: string | number | string[]) => string;
  required?: boolean;
}

export function SalutationField(props: IProps): JSX.Element {
  const { name, validate } = props;
  const salutationOptions = [
    {
      title: _t('contact-salutation-sir'),
      value: Salutation.Male,
    },
    {
      title: _t('contact-salutation-madam'),
      value: Salutation.Female,
    },
  ];

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <FormItem
          label={_t('contact_salutation_label')}
          htmlFor={input.name}
          required
        >
          <RadioGroup
            inline
            name={input.name}
            selected={input.value as string}
            options={salutationOptions}
            rendererIcons={(value: string) => renderSalutationIcons(value)}
            onChange={input.onChange}
          />
          {meta.touched && meta.error && (
            <div className="v-error">{meta.error}</div>
          )}
        </FormItem>
      )}
    </Field>
  );

  function renderSalutationIcons(value: string): JSX.Element {
    return (
      <OwnerIcon
        type={value as Salutation}
        color="primary"
      />
    );
  }
}
