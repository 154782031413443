import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import { TimelineItem } from './components/TimelineItem';
import { TimelineGroup } from './components/TimelineGroup';
import './Timeline.styl';

interface IProps {
  className?: string;
  children?: ReactNode;
}

export class Timeline extends React.Component<IProps> {
  public static Item = TimelineItem;

  public static Group = TimelineGroup;

  public render(): JSX.Element {
    const { children, className } = this.props;
    const timelineClassName = classNames('timeline', className || '');
    return <div className={timelineClassName}>{children}</div>;
  }
}
