import React from 'react';
import './DeactivatedCookiesPage.styl';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { resolve } from 'inversify-react';
import DesktopIcon from '@mui/icons-material/DesktopAccessDisabledRounded';
import { sanitize } from 'dompurify';

import { ConstantDependency } from '@esurance/constants';
import { DependencyType, IHistoryManager } from '@esurance/services';

class DeactivatedCookiesPage extends React.Component<WithTranslation> {
  @resolve(DependencyType.HistoryManager)
  private historyManager: IHistoryManager;

  @resolve(ConstantDependency.EsuranceUrl)
  private esuranceUrl: string;

  public handleClick = (): void => {
    this.historyManager.redirectTo(this.esuranceUrl);
  };

  public render(): JSX.Element {
    const { t: translate } = this.props;

    return (
      <>
        <div className="deactivated-cookies-wrap">
          <p className="deactivated-cookies-img-wrap">
            <DesktopIcon fontSize="large" color="primary" />
          </p>
          <p className="deactivated-cookies-text">
            <p dangerouslySetInnerHTML={{ __html: sanitize(translate('deactivated-cookies-msg')) }} />
          </p>
          <p className="deactivated-cookies-buttons-wrap">
            <button
              className="btn primary contact-us"
              type="button"
              onClick={this.handleClick}
            >
              {translate('contact-us')}
            </button>
          </p>
        </div>
      </>
    );
  }
}

export const DeactivatedCookiesPageView = withTranslation()(DeactivatedCookiesPage);
