import React from 'react';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { Tooltip } from '@esurance/ui-components';

export interface ILabelProps {
  id?: string;
  className?: string;
  label?: string;
  extraLabel?: JSX.Element;
  tip?: string;
}

export function Label({
  id,
  className,
  label,
  extraLabel,
  tip,
}: ILabelProps): JSX.Element | null {
  if (!label && !extraLabel) {
    return null;
  }

  const addProps: any = { className };

  if (id) {
    addProps.htmlFor = id;
  }

  return (
    <Box
      component={id ? 'label' : 'div'}
      {...addProps}
    >
      {label}
      {extraLabel}
      {tip && (
        <Tooltip title={tip}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      )}
    </Box>
  );
}
