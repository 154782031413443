import _ from 'lodash';

export function valueToOption<V, O extends { value: V } = { value: V }>(
  value: V | O | null,
  options: O[],
): O | null {
  return _.find<O>(options, (option: O) => {
    if (value !== null && typeof value === 'object') {
      return option.value === (value as O).value;
    }

    return option.value === (value || null);
  }) as O;
}
