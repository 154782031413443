import _ from 'lodash';
import React from 'react';
import './Rating.styl';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

export interface IRatingProps {
  value?: number;
  name?: string;
  id?: string;
  label?: string;
  onChange: (value: number) => void;
}

export interface IRatingState {
  selected: number | null;
}

interface IRatingLable {
  title: string;
  rate: number;
}

class RatingView extends React.Component<IRatingProps & WithTranslation, IRatingState> {
  public state: IRatingState = {
    selected: null,
  };

  public componentDidMount(): void {
    const { value = 0 } = this.props;

    this.setState({
      selected: value > 0 ? Math.round(value * 10) / 10 : 0,
    });
  }

  public render(): JSX.Element {
    return (
      <fieldset className="rating">
        <div className="star-wrapper">{this.renderInputs()}</div>
      </fieldset>
    );
  }

  private renderInputs = (): JSX.Element[] => {
    const { name, id = `${name}rating`, t: translate } = this.props;

    const labels: IRatingLable[] = [
      {
        rate: 5,
        title: translate('product_details-general_info-rating-very_good'),
      },
      {
        rate: 4,
        title: translate('product_details-general_info-rating-good'),
      },
      {
        rate: 3,
        title: translate('product_details-general_info-rating-normal'),
      },
      {
        rate: 2,
        title: translate('product_details-general_info-rating-bad'),
      },
      {
        rate: 1,
        title: translate('product_details-general_info-rating-very_bad'),
      },
    ];

    return _.map(labels, (label: IRatingLable, n: number) => {
      const inputId = `${id}-${n + 1}`;
      return (
        <React.Fragment key={label.rate}>
          <input
            type="checkbox"
            name={name}
            id={inputId}
            value={label.rate}
            onChange={() => this.handleChangeRating(label.rate)}
            {...this.checkedProp(label.rate)}
          />
          <label
            htmlFor={inputId}
            title={label.title}
            aria-label={label.title}
          />
        </React.Fragment>
      );
    });
  };

  private checkedProp = (value: number): { checked: boolean } => {
    const { selected } = this.state;
    return { checked: selected === value };
  };

  private handleChangeRating = (value: number): void => {
    const { selected } = this.state;
    const { onChange } = this.props;

    const rating = value === selected ? 0 : value;

    this.setState({
      selected: rating,
    });

    onChange(rating);
  };
}

export const Rating = withTranslation()(RatingView);
