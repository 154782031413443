import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type {
  IAccountInformation,
  IGetExpensesDto,
  IGetSupportDto,
  ISettingsData,
  ISupportManager,
} from '@esurance/entities';

class Organization {
  private support: ISupportManager;

  private supportOrgId: number;

  public getExpenses(orgId: number): Promise<IGetExpensesDto> {
    return axios
      .get<IGetExpensesDto>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/expenses`,
      )
      .then((response) => response.data);
  }

  public getSupport(
    orgId: number,
  ): Promise<ISupportManager> {
    if (this.supportOrgId === orgId) {
      return Promise.resolve(this.support);
    }
    return axios
      .get<IGetSupportDto>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/support-member`,
      )
      .then((response) => {
        this.supportOrgId = orgId;
        this.support = {
          avatarUrl: response.data.avatar,
          meetingRequestUrl: response.data.meeting_request_url,
          name: response.data.name,
          title: response.data.title,
        };

        return this.support;
      });
  }

  public supportRequestCall(orgId: number): Promise<IGetSupportDto> {
    return axios
      .post<IGetSupportDto>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/user/call-request`,
      )
      .then((response) => response.data);
  }

  public requestInsurance(
    orgId: number | string,
    data: IInsuranceRequestData,
  ): Promise<any> {
    return axios
      .post(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/insurance/new`,
        data,
      )
      .then((response) => response.data);
  }

  public getCompany(
    orgId: number | string,
  ): Promise<ISettingsData> {
    return axios
      .get<ISettingsData>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/settings`,
      )
      .then((response) => response.data);
  }

  public companyUpdate(
    orgId: number | string,
    data: ISettingsData,
  ): Promise<ISettingsData> {
    return axios
      .put<ISettingsData>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/settings`,
        data,
      )
      .then((response) => response.data);
  }

  public getAccountInformation(
    orgId: number | string,
  ): Promise<IAccountInformation[]> {
    return axios
      .get<IAccountInformation[]>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/accounts`,
      )
      .then((response) => response.data);
  }

  public accountInformationUpdate(
    orgId: number | string,
    data: IAccountInformation,
  ): Promise<IAccountInformation> {
    return axios
      .put<IAccountInformation>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/accounts/${data.id}`,
        data,
      )
      .then((response) => response.data);
  }

  public accountInformationDelete(
    orgId: number | string,
    accountId: number | string,
  ): Promise<boolean> {
    return axios
      .delete(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/accounts/${accountId}`,
      )
      .then(() => true);
  }

  public sendAccountInformation(
    data: IAccountInformation,
    orgId: number | string,
  ): Promise<IAccountInformation> {
    return axios
      .post<IAccountInformation>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/accounts`,
        data,
      )
      .then((response) => response.data);
  }

  public postAdditionalContact(
    orgId: number,
    data: IAddContactRequestData,
  ): Promise<boolean> {
    return axios
      .post<true>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/organization_contact`,
        data,
      )
      .then(() => true);
  }
}

interface IAddContactRequestData {
  name?: string;
  postal_code?: string;
  street_name?: string;
  street_number?: string | null;
  town?: string;
  type_name: string;
}

export interface IInsuranceRequestData {
  type_id: number;
  description: string;
}
export const organization = new Organization();
