import type { Component, KeyboardEvent } from 'react';

import type { IQuestionnaireGroup } from '@esurance/entities';

export const enum EventName {
  Test = 'Test',
  RefreshActivityList = 'RefreshActivityList',
  RefreshSettingsAccountInfoList = 'RefreshSettingsAccountInfoList',
  SocketMandateSigned = 'SocketMandateSigned',
  SurveyQuestionSubmitted = 'SurveyQuestionSubmitted',
  QuestionnaireDidUpdate = 'QuestionnaireDidUpdate',
  CartUpdated = 'CartUpdated',
  CartItemRemoved = 'CartItemRemoved',
  CartCleared = 'CartCleared',
  CompanyData = 'CompanyData',
  KeyboardSubmit = 'KeyboardSubmit',
  DatagridUpdateList = 'DatagridUpdateList',
  DatagridListUpdated = 'DatagridListUpdated',
  GoToNextQuestionnaireItem = 'GoToNextQuestionnaireItem',
  OpenModal = 'OpenModal',
  CloseModal = 'CloseModal',
  PriceCalculationSetFocus = 'PriceCalculationSetFocus',
  UnauthorizedError = 'UnauthorizedError',
  RedirectToHomepage = 'RedirectToHomepage',
  ResetPriceParameters = 'ResetPriceParameters',
}

export type EventCallback = (...args: any[]) => void;

export interface IEventToHandlerMap {
  Test: () => void;
  RefreshActivityList: () => void;
  RefreshSettingsAccountInfoList: () => void;
  SocketMandateSigned: () => void;
  StartProlongToken: () => void;
  StopProlongToken: () => void;
  SurveyQuestionSubmitted: (id: string, item: any) => void;
  QuestionnaireDidUpdate: (groups: IQuestionnaireGroup[]) => void;
  CartUpdated: () => void;
  CartItemRemoved: (productId: number) => void;
  CartCleared: () => void;
  CompanyData: (data: any) => void;
  KeyboardSubmit: (evt: KeyboardEvent<Window> | any) => void;
  DatagridUpdateList: () => void;
  DatagridListUpdated: () => void;
  GoToNextQuestionnaireItem: (id: string) => void;
  RequestMeeting: (message: string) => void;
  OpenModal: (modal?: typeof Component) => void;
  CloseModal: (modal?: typeof Component) => void;
  PriceCalculationSetFocus: (priceParameterName: string) => void;
  UnauthorizedError: () => void;
  RedirectToHomepage: () => void;
  ResetPriceParameters: (resetPriceParameters: string[]) => void,
}

export interface IEventManager<EventToHandlerMap = IEventToHandlerMap> {
// eslint-disable-next-line
  emit: (name: EventName, ...args: any[]) => boolean;
  on: <T extends keyof EventToHandlerMap>(
    event: T,
    handler: EventToHandlerMap[T],
  ) => void;
  removeListener: <T extends keyof EventToHandlerMap>(
    event: T,
    handler: EventToHandlerMap[T],
  ) => void;
}
