import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import type { Location, NavigateFunction } from 'react-router';
import type { NavigateOptions } from 'react-router/lib/hooks';

import type { IRoutes } from '@esurance/entities';

import type { IHistoryManager, IHistoryManagerConfig } from './interfaces';

// TODO: need to decide if we need this Manager as it was implemented only to break circular deps
@injectable()
export class HistoryManager implements IHistoryManager {
  public constructor() {
    makeAutoObservable(this);
  }

  private config: IHistoryManagerConfig = {
    basename: '',
  };

  private navigate?: NavigateFunction;

  public location?: Location;

  public init(config: IHistoryManagerConfig): void {
    this.config = config;
  }

  public redirectTo(path: string): void {
    window.location.assign(path);
  }

  private currentRouteValue?: IRoutes;

  public get currentRoute(): IRoutes | undefined {
    return this.currentRouteValue;
  }

  public setCurrentRoute = (route: IRoutes): void => {
    this.currentRouteValue = route;
  }

  public appendToNavigation(appendix: string, locationState?: any): void {
    if (!this.navigate) {
      console.error('History Manager. Failed to navigate. Navigation is not defined.');
      return;
    }
    if (!this.location) {
      console.error('History Manager. Failed to navigate. Location is not defined.');
      return;
    }

    let { pathname } = this.location;
    if (!pathname.endsWith('/')) {
      pathname += '/';
    }

    this.navigate(
      `${pathname}${appendix}`,
      { state: locationState },
    );
  }

  public navigateTo = (to: string, options?: NavigateOptions): void => {
    if (!this.navigate) {
      console.error('History Manager. Failed to replace. Navigation is not defined.');
      return;
    }
    this.navigate(to, options);
  }

  public navigateBack = (): void => {
    if (!this.navigate) {
      console.error('History Manager. Failed to navigateBack. Navigation is not defined.');
      return;
    }
    this.navigate(-1);
  }

  public setNavigateFunction(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public get basename(): string {
    return this.config.basename;
  }
}
