const MOBILE_BREAKPOINT = 600;
const TABLET_BREAKPOINT = 960;
const DESKTOP_BREAKPOINT = 1170;

export const breakpointsValues = {
  xs: 0,
  sm: MOBILE_BREAKPOINT,
  md: TABLET_BREAKPOINT,
  lg: DESKTOP_BREAKPOINT,
  xl: DESKTOP_BREAKPOINT,
};
