import type { ReactNode } from 'react';
import React from 'react';
import { resolve } from 'inversify-react';
import { observer } from 'mobx-react';
import Cookie from 'js-cookie';

import { DependencyType } from '../ioc/DependencyType';
import { IAuthManager, IKeycloakManager } from '../managers/interfaces';

interface IAuthProviderProps {
  onInit?: () => Promise<void> | void;
  children: ReactNode;
}

interface IAuthProviderState {
  isReady: boolean
}

@observer
export class AuthProvider extends React.Component<IAuthProviderProps, IAuthProviderState> {
  @resolve(DependencyType.AuthManager)
  private authManager: IAuthManager;

  @resolve(DependencyType.KeycloakManager)
  private keycloakManager: IKeycloakManager;

  public async componentDidMount() {
    const { onInit } = this.props;

    if (onInit) {
      await onInit();
    }

    this.updateAuthCookie();
  }

  public render(): React.ReactNode {
    const { children } = this.props;

    return this.authManager.isInitialized && children;
  }

  private updateAuthCookie(): void {
    const authCookieName = 'AUTH-TOKEN';
    const accessToken = this.keycloakManager.token;
    if (accessToken) {
      Cookie.set(authCookieName, accessToken);
    } else {
      Cookie.remove(authCookieName);
    }
  }
}
