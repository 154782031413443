export interface ILanguage {
  title: string;
  value: string;
}

export function toLanguageOptions(languages: Readonly<string[]>): ILanguage[] {
  return languages.map((language) => ({
    title: language.toUpperCase(),
    value: language,
  }));
}
