import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type TextFieldStyles = ComponentsOverrides['MuiTextField'];

export function createMuiTextFieldStyles(
  overrides?: Partial<TextFieldStyles>,
): Partial<TextFieldStyles> {
  return {
    root: {
      fontSize: '16px',
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
