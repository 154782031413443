import { requiredRule } from './required-rule';
import { fourteenDaysAgoRule } from './fourteen-days-ago-rule';
import { emailRule } from './email-rule';
import { dateFormatRule } from './date-format-rule';
import { dateRequiredRule } from './date-required-rule';

export const validation = {
  dateFormatRule,
  dateRequiredRule,
  emailRule,
  fourteenDaysAgoRule,
  requiredRule,
};

export { BaseError } from './BaseError';
