import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type {
  IAbstractActivity,
  IActivityAcceptedTerms,
  IActivityClaim,
  IActivityListDto,
  IActivityNewInsurance,
  IActivityOptimization,
  IActivityPolicyInactivation,
  IActivityPolicyStatusChanged,
  SomeActivity,
} from '@esurance/entities';
import {
  ActivityStatusClass,
  ActivitySubTypes,
  ActivityTypes,
} from '@esurance/legacy-constants';

import { helper } from '../service';

function isClaim(item: any): item is IActivityClaim {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.Claim
  );
}
function isNewInsurance(
  item: any,
): item is IActivityNewInsurance {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.NewInsuranceRequest
  );
}

function isOptimization(
  item: any,
): item is IActivityOptimization {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.Optimization
  );
}

function isSignedTerms(
  item: any,
): item is IActivityAcceptedTerms {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.AcceptedTerms
  );
}

function isPolicyInactivation(
  item: any,
): item is IActivityPolicyInactivation {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.PolicyInactivation
  );
}

function isPolicyStatusChanged(
  item: any,
): item is IActivityPolicyStatusChanged {
  return (
    item.type === ActivityTypes.Task
    && item.subtype === ActivitySubTypes.PolicyStatusChanged
  );
}

function mapToActivity(
  item: IActivityListDto,
): IAbstractActivity {
  const result:
  | SomeActivity
  | IAbstractActivity = {
    attachments: item.attachments || [],
    dueDateTime: helper.buildDate(item.updated_at),
    duration: item.duration,
    id: item.id,
    note: item.note,
    product: item.product,
    status: parseInt(item.done.toString(), 10) || ActivityStatusClass.NotDone,
    subject: item.subject,
    subtype: item.subtype,
    type: item.type,
    contact: item.contact,
  };

  if (isClaim(result) && item.claims) {
    result.claims = item.claims;
  }

  if (isNewInsurance(result) && item.product_types) {
    result.product_types = item.product_types;
  }

  if (isOptimization(result) && item.optimizations) {
    result.optimizations = item.optimizations;
  }

  if (isSignedTerms(result) && item.terms) {
    result.terms = item.terms;
  }

  if (isPolicyInactivation(result) && item.inactivation) {
    result.inactivation = item.inactivation;
  }

  if (isPolicyStatusChanged(result) && item.policy) {
    result.policy = item.policy;
  }

  return result;
}

class Activity {
  public getAll(orgId: number): Promise<IAbstractActivity[]> {
    return axios
      .get<IActivityListDto[]>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/activities/public`,
      )
      .then((response) => (response.data ? response.data.map(mapToActivity) : []));
  }

  public updateActivityStatus(
    id: number,
    status: ActivityStatusClass,
  ): Promise<boolean> {
    return axios
      .put(`${diContainer.backofficeApiRoot}/activity/${id}/update_status`, {
        status,
      })
      .then((response) => response.data);
  }
}

export const activity = new Activity();
