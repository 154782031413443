import { isValidDate } from '@esurance/shared/utils/dates';

import { _t } from '../service/i18n';

import type { IValidationResult } from './IValidationResult';

export function dateFormatRule(value: string): IValidationResult {
  return {
    message: _t('survey-invalid-date-format-message'),
    passes: isValidDate(value),
  };
}
