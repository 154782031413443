import { merge } from 'lodash';

import type { IServicesConfig } from '@esurance/ioc-base';
import {
  LANGUAGES,
} from '@esurance/legacy-constants';
import type { IHistoryManagerConfig } from '@esurance/services';

const DEFAULT_CONFIG: Omit<IServicesConfig, 'historyManager'> = {
  translation: {
    languages: [...LANGUAGES],
    loadPath: '/translation/locales/{{ns}}/{{lng}}',
  },
  configRepository: {},
  keycloakManager: true,
};

export type PartialAppConfig = Partial<{
  [P in keyof IServicesConfig]?: Partial<IServicesConfig[P]>;
}> & { historyManager: IHistoryManagerConfig; };

export function getApplicationConfig(config: PartialAppConfig): IServicesConfig {
  const res = merge(DEFAULT_CONFIG, config);

  if (config?.translation?.languages) {
    res.translation.languages = config.translation.languages;
  }

  return res;
}
