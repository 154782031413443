import i18next from 'i18next';

// TODO: check all usages and remove ones that single purpose is setting Authorization header
export function getHeaders(): Record<string, string> {
  const headers: Record<string, string> = {
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (i18next.language) {
    headers['Accept-Language'] = i18next.language;
  }
  return headers;
}
