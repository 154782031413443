import type { IValidator } from '../interfaces';

export class EqualsValue<T = string> implements IValidator<T> {
  constructor(
    private value: T,
    public errorMessage: string = 'input_default-invalid-message',
  ) {}

  public validate(value: T): boolean {
    return value === this.value;
  }
}
