import type { Container } from 'inversify';

import type { IAuthRepository } from '../repositories/interfaces';
import { AuthRepository } from '../repositories';
import type { IKeycloakManager, IAuthManager } from '../managers/interfaces';
import { AuthManager, KeycloakManager } from '../managers';

import { DependencyType } from './DependencyType';

export function configureContainer(container: Container): void {
  container
    .bind<IAuthRepository>(DependencyType.AuthRepository)
    .to(AuthRepository);

  container
    .bind<IKeycloakManager>(DependencyType.KeycloakManager)
    .to(KeycloakManager);

  container
    .bind<IAuthManager>(DependencyType.AuthManager)
    .to(AuthManager);
}
