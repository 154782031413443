import type { ReactNode } from 'react';
import React from 'react';
import type { Container } from 'inversify';
import { Provider as InversifyReactProvider } from 'inversify-react';

export const InversifyContext = React.createContext<{ container: Container | null }>({
  container: null,
});

type Props = {
  container: Container;
  children: ReactNode;
};

export const Provider: React.FC<Props> = (props) => {
  const { container, children } = props;
  return (
    <InversifyContext.Provider value={{ container }}>
      <InversifyReactProvider container={container}>
        {children}
      </InversifyReactProvider>
    </InversifyContext.Provider>
  );
};
