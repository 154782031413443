import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type IconButtonStyles = ComponentsOverrides['MuiIconButton'];

export function createMuiIconButtonStyles(
  overrides?: Partial<IconButtonStyles>,
): Partial<IconButtonStyles> {
  return {
    root: {
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
