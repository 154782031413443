import type {
  INewCustomerFlowDto,
  IUpsellingFlowData,
  IUpsellingFlowDto,
  SessionFlowData,
  SessionFlowDto,
  INewCustomerFlowData,
} from '../interfaces';

export function parseSessionFlowData(
  dto: SessionFlowDto,
): SessionFlowData {
  if (isUpsellingFlowDto(dto)) {
    return parseUpsellingFlow(dto);
  }

  if (isNewCustomerFlowDto(dto)) {
    return parseNewCustomerFlow(dto);
  }

  throw new Error(`parseSessionFlowData. Invalid flow type: ${dto}`);
}

function isUpsellingFlowDto(flow: SessionFlowDto): flow is IUpsellingFlowDto {
  return flow.type === 'existing_customer';
}

function isNewCustomerFlowDto(flow: SessionFlowDto): flow is INewCustomerFlowDto {
  return flow.type === 'new_customer';
}

function parseUpsellingFlow(dto: IUpsellingFlowDto): IUpsellingFlowData {
  return {
    type: dto.type,
    sessionUuid: dto.session_uuid,
    customer: {
      identityUuid: dto.customer_identity_uuid,
      email: dto.customer_email,
      firstName: dto.customer_first_name,
      lastName: dto.customer_last_name,
      phone: dto.customer_phone,
      salutation: dto.customer_salutation,
    },
    userId: dto.user_id,
    organization: {
      id: dto.org_id,
      name: dto.organization_name,
      postalCode: dto.organization_postal_code,
      streetName: dto.organization_street_name,
      streetNumber: dto.organization_street_number,
      town: dto.organization_town,
    },
  };
}

function parseNewCustomerFlow(dto: INewCustomerFlowDto): INewCustomerFlowData {
  return {
    ...dto,
  };
}
