/* eslint-disable class-methods-use-this */

import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';
import type {
  IAccount,
  ISendSupportMailDto,
  IUpdateUserDto,
  IUserInfo,
  IUserInfoDto,
  IUserSettingsRequestDto,
} from '@esurance/entities';

import type {
  ITokenPayload,
  IAccountRepository,
} from '../managers/interfaces';
import {
  RegistrationIsNotCompletedError,
} from '../managers/interfaces';
import {
  axios,
  getHeaders,
} from '../managers';

function mapUserInfo(data: IUserInfoDto): IUserInfo {
  return {
    dateOfBirth: data.date_of_birth,
    email: data.email,
    firstName: data.first_name,
    fullName: data.full_name || `${data.first_name} ${data.last_name}`,
    gender: data.gender,
    id: data.id,
    lastName: data.last_name,
    phoneNumber: data.phone_number,
    title: data.title,
    partnerId: data.partner_id,
    partnerContactId: data.partner_contact_id,
  };
}

@injectable()
export class AccountRepository implements IAccountRepository {
  public constructor(
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) {}

  public getAll(): Promise<IAccount[]> {
    const headers = getHeaders();

    return axios
      .get<IAccount[]>(
        `${this.backofficeApiRoot}/user/organizations`,
        headers ? { headers } : {},
      )
      .then((response) => response.data);
  }

  public getInfo(): Promise<IUserInfo> {
    const headers = getHeaders();

    return axios
      .get<IUserInfoDto>(
        `${this.backofficeApiRoot}/user/info`,
        headers ? { headers } : {},
      )
      .then((response) => mapUserInfo(response.data));
  }

  public userUpdate(data: IUpdateUserDto) {
    return axios
      .put<boolean>(`${this.backofficeApiRoot}/user/update`, data)
      .then((response) => response.data);
  }

  public turnoffPasswordUpdate() {
    return axios
      .put<boolean>(
        `${this.backofficeApiRoot}/user/password/change-refused`,
      )
      .then((response) => response.data);
  }

  public sendSupportMail(
    data: ISendSupportMailDto,
    orgId: number,
  ): Promise<boolean> {
    return axios
      .post<boolean>(
        `${this.backofficeApiRoot}/organization/${orgId}/user/help-email`,
        data,
      )
      .then((response) => response.data);
  }

  public updateSettings(
    data: Partial<IUserSettingsRequestDto>,
  ): Promise<boolean> {
    return axios
      .post<boolean>(
        `${this.backofficeApiRoot}/user/current-user-settings`,
        data,
      )
      .then((response) => response.data);
  }

  public getTokenPayload(token: string): Promise<ITokenPayload> {
    return axios
      .post<ITokenPayload>(`${this.backofficeApiRoot}/user/payload`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((e) => {
        if (e.response.status === 412) {
          throw new RegistrationIsNotCompletedError();
        }
        return e;
      });
  }
}
