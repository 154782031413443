import {
  makeObservable, computed, action, observable,
} from 'mobx';

import type { IFormSelectOption } from '../../components/forms';

import type { IFormField, IFormFieldConfig, IValidator } from './interfaces';
import { FORM_FIELD_MARKER } from './interfaces';
import { Required, RequiredIfAnotherIsEmpty } from './validators';

export class Field<T = string> implements IFormField<T> {
  constructor(
    val: T,
    private validators: IValidator<T>[] = [],
    config?: IFormFieldConfig<T>,
  ) {
    this.val = val;
    this.defaultVal = val;
    this.label = config?.label;
    this.disabled = Boolean(config?.disabled);
    this.options = config?.options;

    makeObservable<
      Field<T>,
      'touched'
      | 'disabled'
      | 'val'
      | 'customError'
      | 'defaultVal'
      >(
        this,
        {
          [FORM_FIELD_MARKER]: observable,
          label: observable,
          touched: observable,
          disabled: observable,
          val: observable,
          customError: observable,
          options: observable,
          defaultVal: observable,

          change: action,
          touch: action,
          reset: action,
          setCustomError: action,
          clearCustomError: action,

          value: computed,
          title: computed,
          isTouched: computed,
          isInvalid: computed,
          isDisabled: computed,
          showErrorMessage: computed,
          errorMessage: computed,
          hasDefaultValue: computed,
          isRequired: computed,
          isChanged: computed,
          isEmpty: computed,
        },
      );
  }

  public get value(): T {
    return this.val;
  }

  public get title(): string {
    const selectedOption = this.options?.find((o) => o.value === this.val);
    return selectedOption ? selectedOption.title : '';
  }

  public get isTouched(): boolean {
    return this.touched;
  }

  public get isInvalid(): boolean {
    if (this.customError) return true;

    if (this.isDisabled) return false;

    for (const v of this.validators) {
      if (!v.validate(this.value)) {
        return true;
      }
    }
    return false;
  }

  public get isDisabled(): boolean {
    return this.disabled;
  }

  public get showErrorMessage(): boolean {
    return this.isTouched && this.isInvalid && Boolean(this.errorMessage);
  }

  public get errorMessage(): string | undefined {
    if (this.customError) return this.customError;

    for (const v of this.validators) {
      if (!v.validate(this.val)) {
        return v.errorMessage;
      }
    }
    return undefined;
  }

  public get hasDefaultValue(): boolean {
    return this.val === this.defaultVal;
  }

  public get isRequired(): boolean {
    const hasRequiredValidator = Boolean(this.validators.find(
      ((validator) => validator instanceof Required),
    ));
    const requiredIfAnotherIsEmptyValidator = this.validators.find(
      ((validator) => validator instanceof RequiredIfAnotherIsEmpty),
    );
    const hasRequiredIfAnotherIsEmptyValidator = Boolean(requiredIfAnotherIsEmptyValidator);
    const isRequiredIfAnotherIsEmptyValidatorValid = Boolean(
      requiredIfAnotherIsEmptyValidator?.validate(this.value),
    );
    return (hasRequiredValidator
      || (hasRequiredIfAnotherIsEmptyValidator && !isRequiredIfAnotherIsEmptyValidatorValid));
  }

  public get isChanged(): boolean {
    return this.val !== this.defaultVal;
  }

  public get isEmpty(): boolean {
    return typeof this.val === 'string' && !this.val;
  }

  public [FORM_FIELD_MARKER] = true;

  public label: string | undefined;

  private touched = false;

  private disabled = false;

  private val: T;

  private customError?: string = undefined;

  public options?: IFormSelectOption<T>[] = undefined;

  private readonly defaultVal: T;

  public change = (newValue: T): void => {
    this.clearCustomError();
    this.val = newValue;
  };

  public touch = (): void => {
    this.touched = true;
  };

  public reset = (): void => {
    this.val = this.defaultVal;
    this.touched = false;
  };

  public setCustomError = (error: string | undefined): void => {
    this.customError = error;
  };

  public clearCustomError = (): void => {
    this.setCustomError(undefined);
  };

  public getValue(): T {
    return this.val;
  }
}
