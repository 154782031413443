export function getIntegraAffinityUrl(): string {
  if (window.location.origin.includes('swica-integra')) {
    return window.location.origin;
  }
  // else: replace first domain segment with swica-integra
  return window.location.origin.replace(
    /\/\/.+?\./, '//swica-integra.',
  );
}

export const isIntegraAffinityUrl = (url: string): boolean => url.includes('swica-integra');
