import './item.styl';

import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Box } from '@mui/material';
import { sanitize } from 'dompurify';

import type { IAbstractActivity } from '@esurance/entities';
import {
  ActivityStatusClass,
  ActivitySubTypes,
  ActivityTypes,
} from '@esurance/legacy-constants';
import { ActivityIcon } from '@esurance/domain-components';

import { _t, _te, toDateString } from '../../../service';

import { Attachements } from './attachments';

export function stopPropagation(e: React.MouseEvent<HTMLElement>): boolean {
  e.preventDefault();
  e.stopPropagation();
  return false;
}

export interface IBaseActivityItemProps {
  item: IAbstractActivity;
  selected: boolean;
  onSelect: (item: IAbstractActivity) => void;
}

interface IProps extends IBaseActivityItemProps {
  content?: React.ReactNode | (() => JSX.Element);
  customInfo?: () => JSX.Element;
  hideDate?: boolean;
  hotAction?: () => JSX.Element;
  type?: ActivityTypes;
  note?: React.ReactNode | (() => JSX.Element);
  disableClamp?: boolean;
  shortContent?: React.ReactNode | (() => JSX.Element);
  subject?: string;
}

// Todo: refactor. Make it clear and simple, and leave comments how it works
// Complexity is 13 (CodeMetrics)

export class BaseActivityItem extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    disableClamp: false,
  };

  public render(): JSX.Element {
    const {
      hideDate,
      item,
      selected,
      disableClamp,
      customInfo,
      type,
    } = this.props;
    const done = item.status === ActivityStatusClass.Done;
    const todo = (
      item.status === ActivityStatusClass.NotDone
      || item.status === ActivityStatusClass.InProgress
    );

    const wrapClassNames = classNames('activity-item', {
      done,
      selected,
      todo,
    });

    const contentClassNames = classNames('content', {
      clamp: !disableClamp,
    });

    const infoSection = customInfo ? (
      customInfo()
    ) : (
      <>
        {this.renderStatus()}
        <span className="separator" />
        {hideDate
          ? null
          : toDateString(item.dueDateTime)}
      </>
    );

    return (
      <section className={wrapClassNames}>
        <div className="info">{infoSection}</div>
        <div className="activity-icon">
          <ActivityIcon
            type={type}
            color="primary"
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={contentClassNames} onClick={this.onSelect}>
          {this.renderContent(item)}
        </div>
      </section>
    );
  }

  // Todo: refactor. Make it clear and simple, and leave comments how it works
  // Complexity is 13 (CodeMetrics)
  private renderContent(item: IAbstractActivity): JSX.Element | null {
    const {
      hotAction,
      subject,
      content,
      note,
      selected,
    } = this.props;
    const title = subject || item.subject;
    const itemContent = this.getContent(content, note, item.note)
      || _te('no-content');
    const contentToDisplay = React.isValidElement(itemContent) ? (
      itemContent
    ) : (
      <div dangerouslySetInnerHTML={{ __html: sanitize(itemContent.toString()) }} />
    );

    if (selected) {
      return (
        <>
          <Box display="flex" justifyContent="space-between">
            {this.renderContentItemHeader(item.type, title)}
            <CloseIcon color="action" />
          </Box>

          <div className="full-notes">
            {contentToDisplay}

            <Attachements files={item.attachments} />
          </div>

          {hotAction && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className="hot-actions" onClick={stopPropagation}>
              {hotAction()}
            </div>
          )}
        </>
      );
    }
    if (item.status === ActivityStatusClass.Done) {
      return this.renderContentItemHeader(item.type, title);
    }
    const classnames = classNames('has-right-part', {
      'password-cta': item.subtype === ActivitySubTypes.ChangePassword,
    });

    return (
      <div className={classnames}>
        <div>
          {this.renderContentItemHeader(item.type, title)}

          <div className="short-notes">
            <div className="inner-wrapper">{contentToDisplay}</div>

            <div className="see-all">{_t('activity_see_all')}</div>
          </div>
        </div>

        {hotAction && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className="hot-actions" onClick={stopPropagation}>
            {hotAction()}
          </div>
        )}
      </div>
    );
  }

  private renderContentItemHeader(
    type: ActivityTypes,
    title: string,
  ): JSX.Element | null {
    const header = <header>{title}</header>;
    return type === ActivityTypes.Notification ? null : header;
  }

  private getContent(
    ...args: (React.ReactNode | (() => JSX.Element))[]
  ): string | JSX.Element | null {
    for (const content of args) {
      if (content && _.isString(content)) {
        return content;
      }
      if (content && _.isFunction(content)) {
        return content();
      }
    }
    return null;
  }

  private renderStatus(): string {
    const { item } = this.props;
    switch (item.status) {
    case ActivityStatusClass.Done:
      return _t('activity_done');
    case ActivityStatusClass.InProgress:
      return _t('activity_in-progress');
    case ActivityStatusClass.NotDone:
      return _t('activity_todo');
    default:
      throw new Error('No such status');
    }
  }

  private onSelect = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    const { item, onSelect } = this.props;
    onSelect(item);
  };
}
