import type { ReactNode } from 'react';
import React from 'react';
import type { Accept, DropEvent, FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUploadRounded';
import {
  Box,
  Button,
  styled,
  Typography,
} from '@mui/material';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { SIZE_15_MB } from '@esurance/legacy-constants';

interface IProps extends WithTranslation {
  description?: string | ReactNode;
  buttonLabel: string;
  multiple?: boolean;
  acceptedTypes?: Accept;
  onDrop: (
    accepted: File[],
    rejected?: FileRejection[],
    event?: DropEvent,
  ) => void;
  maxSize?: number;
}
const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  textAlign: 'left',
}));

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.tertiary,
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(theme.palette.border.regular)}' rx='${theme.shape.borderRadius}' ry='${theme.shape.borderRadius}'  stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  '&:hover': {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(theme.palette.border.regularHover)}' rx='${theme.shape.borderRadius}' ry='${theme.shape.borderRadius}' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
}));

class DragNDropImpl extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    acceptedTypes: {
      'image/jpeg': [],
      'image/png': [],
      'image/bmp': [],
      'image/tiff': [],
      'application/pdf': [],
    },
    multiple: false,
    maxSize: SIZE_15_MB,
  };

  public render(): JSX.Element {
    const {
      acceptedTypes,
      multiple,
      description,
      buttonLabel,
      maxSize,
      t: translate,
    } = this.props;

    return (
      <Dropzone
        accept={acceptedTypes}
        onDrop={this.handleDrop}
        multiple={multiple}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps }) => (
          <Container {...getRootProps()}>
            <input {...getInputProps()} />

            <TitleWrapper>
              <CloudUploadIcon fontSize="inherit" />
              {' '}
              <Typography variant="body1">
                {description}
              </Typography>
            </TitleWrapper>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body1">
                {translate('drag-and-drop')}
                &nbsp;
                {translate('or')}
              </Typography>
              <Button type="button" size="large">
                {buttonLabel}
              </Button>
            </Box>
          </Container>
        )}
      </Dropzone>
    );
  }

  private handleDrop = (
    accepted: File[],
    rejected: FileRejection[],
    event: DropEvent,
  ): void => {
    const { onDrop } = this.props;
    onDrop(accepted, rejected, event);
  };
}

export const DragNDrop = withTranslation()(DragNDropImpl);
