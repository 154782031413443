import './Icon.styl';

import classNames from 'classnames';
import type { MouseEvent } from 'react';
import React from 'react';

import type { IconColor, IconSize, IconType } from '@esurance/legacy-constants';

export interface IIconProps {
  badgeText?: string | number;
  block?: boolean;
  circle?: boolean;
  className?: string;
  color?: IconColor;
  contain?: boolean;
  hoverable?: boolean;
  iconSize?: IconSize;
  linkGrey?: boolean;
  onBlue?: boolean;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
  onRed?: boolean;
  size?: IconSize;
  style?: React.CSSProperties;
  title?: string;
  type: IconType;
}

export function Icon(props: IIconProps): JSX.Element {
  const {
    block,
    size,
    iconSize,
    circle,
    contain,
    linkGrey,
    badgeText,
    hoverable,
    onBlue,
    onRed,
    type,
    color,
    className,
    style,
    title,
  } = props;
  const classes: { [key: string]: boolean } = {
    [`icon-size-${iconSize}`]: !!iconSize,
    [`size-${size}`]: !!size,
    block: !!block,
    circle: !!circle,
    contain: !!contain,
    'grey-link': !!linkGrey,
    'has-badge': !!badgeText,
    hoverable: !!hoverable,
    'on-blue': !!onBlue,
    'on-red': !!onRed,
  };
  const c = classNames('iconna', type, color || '', classes, className || '');

  const handleClick = (e: MouseEvent<HTMLSpanElement>): void => {
    if (
      Object.prototype.hasOwnProperty.call(props, 'onClick')
      && props.onClick
    ) {
      props.onClick(e);
    }
  };

  interface IIconSpan extends React.HtmlHTMLAttributes<HTMLSpanElement> {
    'data-badge'?: string | number;
  }
  const dynamicProps: IIconSpan = {};

  if (style) {
    dynamicProps.style = style;
  }

  if (title) {
    dynamicProps.title = title;
  }

  if (badgeText) {
    dynamicProps['data-badge'] = badgeText;
  }

  return (
    <span
      role="presentation"
      {...dynamicProps}
      className={c}
      onClick={handleClick}
    />
  );
}
