import type { OptionType } from '../../types';

export function createOption<T extends Pick<string, 'toString'>>(
  value: T,
  label?: string,
): OptionType<T> {
  return {
    value,
    label: label || value.toString(),
  };
}
