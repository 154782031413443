import { injectable } from 'inversify';

import type {
  ILocalStorageManager,
  ILocalStorageManagerFactory,
  LocalStorageDataValidator,
} from './interfaces';
import { LocalStorageManager } from './LocalStorageManager';

@injectable()
export class LocalStorageManagerFactory implements ILocalStorageManagerFactory {
  // eslint-disable-next-line class-methods-use-this
  public create<T>(key: string, validator?: LocalStorageDataValidator<T>): ILocalStorageManager<T> {
    return new LocalStorageManager<T>(key, validator);
  }
}
