import React from 'react';
import classNames from 'classnames';
import './divider.styl';

interface IProps {
  title?: string;
  wideInPanel?: boolean;
  noLabelBorder?: boolean;
  smallText?: boolean;
}

export function Divider(props: IProps): JSX.Element {
  const {
    noLabelBorder,
    smallText,
    wideInPanel,
    title,
  } = props;
  const classnames = classNames('divider', {
    'no-label-border': noLabelBorder,
    'small-text': smallText,
    'wide-in-panel': wideInPanel,
  });
  return (
    <div className={classnames}>
      {title && <header>{title}</header>}
    </div>
  );
}
