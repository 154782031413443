import type { ReactNode } from 'react';
import React from 'react';

import { DeactivatedCookiesPageView } from '@esurance/ui-components';
import { globalLoader } from '@esurance/shared';

/* globals navigator, window */

export class AppWithRequiredCookies extends React.Component<React.PropsWithChildren> {
  public render(): JSX.Element | ReactNode {
    const { children } = this.props;

    if (navigator.cookieEnabled || this.isEmbeddedSuggestion()) {
      return children;
    }

    globalLoader.stop();
    return <DeactivatedCookiesPageView />;
  }

  private isEmbeddedSuggestion(): boolean {
    return window.location.href.indexOf('/embedded-suggestion') !== -1;
  }
}
