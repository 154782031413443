import type { Container } from 'inversify';

import type { TagParser } from '../components/TranslationWithTags';

import { DependencyType } from './DependencyType';

export function configureContainer(container: Container): void {
  if (container.isBound(DependencyType.TranslationTagParsers)) return;
  container
    .bind<TagParser[]>(DependencyType.TranslationTagParsers)
    .toConstantValue([]);
}
