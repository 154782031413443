import type { KeyboardEvent, MouseEvent } from 'react';
import React from 'react';
import classesNames from 'classnames';
import { resolve } from 'inversify-react';

import { EventName, IEventManager, DependencyType } from '@esurance/services';

import { _t } from '../../../service';

interface IProps {
  disabled?: boolean;
  isVisible?: boolean;
  itemClass?: string;
  buttonLabel?: string;
  onSubmit: () => void;
}

export class NextQuestionButton extends React.PureComponent<IProps> {
  public static defaultProps = {
    disabled: false,
    isVisible: true,
  };

  @resolve(DependencyType.EventManager)
  private eventManager: IEventManager;

  private element: HTMLDivElement | null = null;

  public componentDidMount(): void {
    this.eventManager.on(
      EventName.KeyboardSubmit,
      this.handleKeyboardSubmit,
    );
  }

  public componentWillUnmount(): void {
    this.eventManager.removeListener(
      EventName.KeyboardSubmit,
      this.handleKeyboardSubmit,
    );
  }

  public render(): JSX.Element {
    const { disabled, isVisible, buttonLabel } = this.props;
    const classes = classesNames('next-question', {
      hidden: !isVisible,
    });

    return (
      <div className={classes} ref={this.handleRef}>
        <button
          tabIndex={-1}
          className="btn primary"
          onClick={this.handleSubmit}
          disabled={disabled}
          type="button"
        >
          {buttonLabel || _t('survey-next_question')}
        </button>

        {_t('survey-next_question-enter')}
      </div>
    );
  }

  private handleRef = (element: HTMLDivElement): void => {
    this.element = element;
  };

  private handleKeyboardSubmit = (evt: KeyboardEvent<Window> | any): void => {
    const { disabled, itemClass = '.question' } = this.props;
    if (!disabled && this.element) {
      const parent = this.element.closest(itemClass);
      const submitButton = this.element.querySelector('button');

      evt.stopPropagation();
      evt.preventDefault();

      if (submitButton && parent && parent.classList.contains('active_question')) {
        submitButton.click();
      }
    }
  };

  private handleSubmit = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const { onSubmit } = this.props;

    onSubmit();
  };
}
