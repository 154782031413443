import { EventEmitter } from 'events';

import { inject, injectable } from 'inversify';
import type { KeycloakInstance, KeycloakLoginOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';

import { ConstantDependency } from '@esurance/constants';

import type {
  IKeycloakManager,
  IKeycloakLoginActionParams,
  KeycloakManagerEventHandlerMap,
} from './interfaces';

@injectable()
export class KeycloakManager implements IKeycloakManager {
  // https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter

  private readonly keycloak: KeycloakInstance;

  private eventEmitter: EventEmitter = new EventEmitter();

  public constructor(
  @inject(ConstantDependency.KeycloakUrl)
    keycloakUrl: string,
    @inject(ConstantDependency.KeycloakRealm)
    keycloakRealm: string,
    @inject(ConstantDependency.KeycloakClientId)
    keycloakClientId: string,
  ) {
    this.keycloak = Keycloak({
      url: keycloakUrl,
      realm: keycloakRealm,
      clientId: keycloakClientId,
    });

    this.keycloak.onReady = (authenticated?: boolean) => {
      this.emit('ready', authenticated);
    };

    // @ts-ignore
    window.keycloak = this.keycloak; // for debugging
  }

  public async init(): Promise<void> {
    this.keycloak.init({
      onLoad: 'check-sso',
      enableLogging: true,
      checkLoginIframe: false,
      pkceMethod: 'S256',
    });

    return new Promise(((resolve) => {
      this.on('ready', () => resolve(undefined));
    }));
  }

  public async login(redirectUri?: string, locale?: string): Promise<void> {
    const loginUrl = this.keycloak.createLoginUrl({ redirectUri, locale });
    window.location.assign(loginUrl);
  }

  public async register(redirectUri?: string): Promise<void> {
    await this.keycloak.register({ redirectUri });
  }

  public async logout(): Promise<void> {
    if (this.isAuthenticated) {
      await this.keycloak.logout();
    }
  }

  public get token(): string | undefined {
    return this.keycloak.token;
  }

  public get isAuthenticated(): boolean {
    return this.keycloak.authenticated || false;
  }

  public async updateToken(minValiditySeconds = -1): Promise<void> {
    await this.keycloak.updateToken(minValiditySeconds);
  }

  public get instance(): unknown {
    return this.keycloak;
  }

  public loginAction(params: IKeycloakLoginActionParams): void {
    const loginOptions: KeycloakLoginOptions = {
      action: params.action,
      redirectUri: params.redirectUrl,
    };
    if (params.email) {
      loginOptions.loginHint = params.email;
    }
    const updatePasswordUrl = this.keycloak.createLoginUrl(loginOptions);
    window.location.href = updatePasswordUrl;
  }

  // KeycloakEventManager

  private emit<K extends keyof KeycloakManagerEventHandlerMap>(
    event: K,
    ...args: Parameters<KeycloakManagerEventHandlerMap[K]>
  ): boolean {
    return this.eventEmitter.emit(event, args);
  }

  public on<K extends keyof KeycloakManagerEventHandlerMap>(
    event: K,
    handler: KeycloakManagerEventHandlerMap[K],
  ): void {
    this.eventEmitter.on(event, handler);
  }

  public removeListener<K extends keyof KeycloakManagerEventHandlerMap>(
    event: K,
    handler: KeycloakManagerEventHandlerMap[K],
  ): void {
    this.eventEmitter.removeListener(event, handler);
  }
}
