import _ from 'lodash';
import React from 'react';

import type { ITranslatable } from '@esurance/entities';
import { LANGUAGES } from '@esurance/legacy-constants';

import { getTranslatable } from '../../service';
import { Switch } from '../element/switch';

import type { IInputProps } from './input';
import { Input } from './input';

interface IProps extends IInputProps {
  // this is a hack to override values in the next interface
  value?: any;
  defaultValue?: any;
  onChange?: any;
}

export interface IInputI18NProps extends IProps {
  value?: ITranslatable;
  defaultValue?: ITranslatable;
  onChange?: (v: ITranslatable) => void;
}

interface IState {
  currentLanguage: string;
  value?: ITranslatable;
}

const langs = _.map(LANGUAGES, (value) => ({ title: value, value }));

export class InputI18N extends React.Component<IInputI18NProps, IState> {
  public state: IState = {
    currentLanguage: LANGUAGES[0],
    value: undefined,
  };

  public componentDidMount(): void {
    const { defaultValue, value: currentValue } = this.props;

    const value = defaultValue || currentValue;
    if (value) {
      this.setState({ value });
    } else {
      this.setState({
        value: {
          de: '',
          en: '',
          fr: '',
          it: '',
        },
      });
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IInputI18NProps): void {
    const { onChange } = this.props;
    if (onChange) {
      this.setState({ value: nextProps.value });
    }
  }

  public render(): JSX.Element | null {
    const { value, currentLanguage } = this.state;
    if (!value) {
      return null;
    }
    const props: IInputProps = {
      ...this.props,
      defaultValue: undefined,
      extraLabel: this.renderTranslations(),
      onChange: this.handleChange,
      value: (value)[currentLanguage],
    };
    return (
      <>
        {_.map(value, (translation, key) => (
          <input
            type="hidden"
            name={`${props.name}_${key}`}
            key={key}
            value={translation}
          />
        ))}
        <Input {...props} />
      </>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    const { onChange } = this.props;
    const { value: currentValue, currentLanguage } = this.state;

    const value: ITranslatable = currentValue ? { ...currentValue } : getTranslatable('');
    value[currentLanguage] = event.target.value;
    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
  };

  private renderTranslations(): JSX.Element {
    const { currentLanguage } = this.state;
    return (
      <Switch
        items={langs}
        onChange={(item: string) => this.handleSwitchChange(item)}
        selectedValue={currentLanguage}
      />
    );
  }

  private handleSwitchChange = (lang: string): void => {
    this.setState({ currentLanguage: lang });
  };
}
