import './gdpr-bar.styl';

import React from 'react';
import { Trans } from 'react-i18next';
import Cookie from 'js-cookie';

import { _t } from '../../service';

export interface IGDPRCookie {
  accepted: boolean;
  date: Date;
}

interface IState {
  accepted: boolean;
}

interface IProps {
  name?: string;
}

export class GDPR extends React.Component<IProps, IState> {
  public static defaultProps = {
    name: 'GDPR',
  };

  public state: IState = {
    accepted: false,
  };

  public componentDidMount(): void {
    const { name } = this.props;
    const savedData: IGDPRCookie = name && JSON.parse(localStorage.getItem(name) as string);
    const globalCookiePolicy = Cookie.get('viewed_cookie_policy');

    if (globalCookiePolicy) {
      savedData.accepted = globalCookiePolicy === 'yes';
    }

    this.setState(savedData);
  }

  public render(): JSX.Element | null {
    const { accepted } = this.state;

    if (accepted) {
      return null;
    }

    return (
      <div className="gdpr-bar" data-gdpr="gdpr-bar">
        <div className="content">
          <Trans i18nKey="gdpr-content">
            text-before
            <a href={_t('data_protection_info_link')} target="_blank" rel="noopener noreferrer">
              text-link
            </a>
            text-after
          </Trans>
        </div>
        <button
          type="button"
          className="btn primary mini no-responsive"
          onClick={this.handleClick}
        >
          {_t('gdpr-button')}
        </button>
      </div>
    );
  }

  private handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const { name } = this.props;

    const newState = {
      accepted: true,
      date: new Date(),
    };

    this.setState(newState);

    if (name) {
      localStorage.setItem(name, JSON.stringify(newState));
    }
  };
}
