import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import {
  createMaterialAlertTheme,
  createMaterialButtonTheme,
  createMaterialCheckboxTheme,
  createMaterialFormHelperTextTheme,
  createMaterialTooltipTheme,
  createMuiAlertStyles,
  createMuiAutocompleteStyles,
  createMuiButtonStyles,
  createMuiFormControlLabelStyles,
  createMuiFormControlStyles,
  createMuiFormHelperTextStyles,
  createMuiFormLabelStyles,
  createMuiInputBaseStyles,
  createMuiInputLabelStyles,
  createMuiMenuItemStyles,
  createMuiSwitchStyles,
  createMuiTextFieldStyles,
} from './helpers';
import { breakpointsValues } from './constants/materialThemeBreakbointsValues';
import FuturaStdWoff from './fonts/Futura/FuturaStd-Book.woff';
import FuturaHeavyWoff from './fonts/Futura/FuturaHeavy.woff';
import FuturaStdBoldWoff from './fonts/Futura/FuturaStd-Bold.woff';
import { baseThemeOptions } from './baseTheme';

const mainTheme = createTheme(deepmerge(
  baseThemeOptions,
  {
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          colorPrimary: {
            color: '#179f96',
          },
          colorSecondary: {
            color: '#ccc',
          },
          colorAction: {
            color: '#333',
          },
          colorDisabled: {
            color: '#999',
          },
          colorError: {
            color: '#e26578',
          },
          fontSizeSmall: {
            fontSize: '20px',
          },
          fontSizeInherit: {
            fontSize: '35px',
          },
          fontSizeLarge: {
            fontSize: '45px',
          },
          root: {
            fontSize: '25px',

            '& path': {
              color: 'inherit',
              fontSize: 'inherit',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: createMuiAutocompleteStyles(),
      },
      MuiFormControl: {
        styleOverrides: createMuiFormControlStyles(),
      },
      MuiFormControlLabel: {
        styleOverrides: createMuiFormControlLabelStyles(),
      },
      MuiFormLabel: {
        styleOverrides: createMuiFormLabelStyles(),
      },
      MuiInputBase: {
        styleOverrides: createMuiInputBaseStyles(),
      },
      MuiInputLabel: {
        styleOverrides: createMuiInputLabelStyles(),
      },
      MuiMenuItem: {
        styleOverrides: createMuiMenuItemStyles(),
      },
      MuiTextField: {
        styleOverrides: createMuiTextFieldStyles(),
      },
      MuiTooltip: {
        defaultProps: createMaterialTooltipTheme(),
      },
      MuiCheckbox: {
        defaultProps: createMaterialCheckboxTheme(),
      },
      MuiFormHelperText: {
        styleOverrides: createMuiFormHelperTextStyles(),
        defaultProps: createMaterialFormHelperTextTheme(),
      },
      MuiCssBaseline: {
        styleOverrides: `
        ${baseThemeOptions.components?.MuiCssBaseline?.styleOverrides || ''}
        @font-face {
            font-family: 'Futura Std Book';
            font-weight: normal;
            font-style: normal;
            src: url(${FuturaStdWoff}) format('woff');
        }
        @font-face {
            font-family: 'Futura Std Bold';
            font-weight: bold;
            font-style: normal;
            src: url(${FuturaStdBoldWoff}) format('woff');
        }
        @font-face {
            font-family: 'Futura Heavy';
            font-weight: normal;
            font-style :normal;
            src: url(${FuturaHeavyWoff}) format('woff');
        }
      `,
      },
    },
    shape: {
      borderRadius: 0,
    },
    palette: {
      primary: {
        main: '#179f96',
        dark: '#007068',
        light: '#c5e7e5',
        contrastText: '#fff',
      },
      secondary: {
        main: '#1dccc0',
        dark: '#009a90',
        light: '#69fff3',
        contrastText: 'rgba(0,0,0,0.87)',
      },
      error: {
        main: '#e26578',
        dark: '#ac344c',
        light: '#ff96a7',
        contrastText: '#fff',
      },
      warning: {
        main: '#efb041',
        dark: '#b88104',
        light: '#ffe272',
        contrastText: 'rgba(0,0,0,0.87)',
      },
      info: {
        main: '#448ef7',
        dark: '#0061c3',
        light: '#82beff',
        contrastText: '#fff',
      },
      success: {
        main: '#00a58d',
        dark: '#007560',
        light: '#55d7bd',
        contrastText: '#fff',
      },
      text: {
        primary: '#354052',
        secondary: '#979797',
        tertiary: '#878D99',
        disabled: '#999',
        hint: '#e83c68',
      },
      background: {
        paper: '#fff',
        default: '#fff',
        alertInfo: '#F6F6F6',
      },
      border: {
        regular: 'rgb(193,193,193)',
      },
      alert: {
        standardInfo: {
          background: '#F6F6F6',
          text: '#818181',
        },
        standardSuccess: {
          background: '#DAF8F2',
          text: '#179F96',
        },
      },
      divider: '#d0d8da',
    },
    typography: {
      fontFamily: [
        'Futura Std Book',
        'Source Sans Pro',
        'SourceSansPro',
        'sans-serif',
      ].join(','),

      h1: {
        fontSize: '44px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      h2: {
        fontSize: '38px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      h3: {
        fontSize: '32px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      h4: {
        fontSize: '22px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      h5: {
        fontSize: '18px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      h6: {
        fontSize: '18px',
        fontFamily: [
          'Futura Std Bold',
          'Source Sans Pro',
        ].join(','),
      },
      body1: {
        fontSize: '18px',
        fontFamily: [
          'Futura Std Book',
          'Source Sans Pro',
        ].join(','),
      },
      body2: {
        fontSize: '16px',
        fontFamily: [
          'Futura Std Book',
          'Source Sans Pro',
        ].join(','),
      },
      caption: {
        fontSize: '14px',
        fontFamily: [
          'Futura Std Book',
          'Source Sans Pro',
        ].join(','),
      },
      overline: {
        fontSize: '12px',
        fontFamily: [
          'Futura Std Book',
          'Source Sans Pro',
        ].join(','),
      },
      button: {
        fontSize: '18px',
        fontFamily: [
          'Futura Std Book',
          'Source Sans Pro',
        ].join(','),
      },
    },
    breakpoints: {
      values: {
        ...breakpointsValues,
      },
    },
  },
));

export const inaTheme = createTheme(mainTheme, {
  components: {
    MuiButton: {
      styleOverrides: createMuiButtonStyles(mainTheme),
      defaultProps: createMaterialButtonTheme(),
    },
    MuiSwitch: {
      styleOverrides: createMuiSwitchStyles(mainTheme.palette),
    },
    MuiAlert: {
      defaultProps: createMaterialAlertTheme(),
      styleOverrides: createMuiAlertStyles(mainTheme),
    },
  },
});
