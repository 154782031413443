import type { AxiosResponse } from 'axios';
import { saveAs } from 'browser-filesaver/FileSaver';

// eslint-disable-next-line consistent-return
export function generateAndDownloadFile(response: AxiosResponse): void {
  const disposition = response.headers && response.headers['content-disposition'];
  const matches = /filename="([^"]*)"/.exec(disposition);
  const filename = (matches != null && matches[1] ? matches[1] : 'noname.pdf');

  const blob = new Blob([response.data]);
  if (
    navigator.userAgent.indexOf('MSIE') !== -1
    || navigator.appVersion.indexOf('Trident/') > -1
  ) {
    return saveAs(blob, filename);
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  });
}
