import React from 'react';
import { resolve } from 'inversify-react';

import { DependencyType } from '../ioc/DependencyType';
import { IAuthManager } from '../managers/interfaces';

export class LoginPageView extends React.Component {
  @resolve(DependencyType.AuthManager)
  private authManager: IAuthManager;

  public async componentDidMount(): Promise<void> {
    if (!this.authManager.isAuthenticated) {
      await this.authManager.login();
    }
  }

  public render(): JSX.Element | null {
    return null;
  }
}

export default LoginPageView;
