import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type FormHelperTextStyles = ComponentsOverrides['MuiFormHelperText'];

export function createMuiFormHelperTextStyles(
  overrides?: Partial<FormHelperTextStyles>,
): Partial<FormHelperTextStyles> {
  return {
    root: {
      margin: 0,
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
