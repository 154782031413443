import type { TagParser } from '../types';

import {
  ulTagParser,
  olTagParser,
  liTagParser,
  bTagParser,
  iTagParser,
  strongTagParser,
  emTagParser,
  pTagParser,
  hrTagParser,
  brTagParser,
  tableTagParser,
  tbodyTagParser,
  trTagParser,
  thTagParser,
  tdTagParser,
  spanTagParser,
  aTagParser,
  imageTagParser,
  uTagParser,
  h1TagParser,
  h2TagParser,
  h3TagParser,
  h4TagParser,
  h5TagParser,
} from './html';
import { logoTagParser } from './LogoTag';

export function getAllParsers(tagParsers?: TagParser[]): TagParser[] {
  return [
    ...(tagParsers || []),
    ulTagParser,
    olTagParser,
    liTagParser,
    bTagParser,
    iTagParser,
    strongTagParser,
    emTagParser,
    pTagParser,
    hrTagParser,
    brTagParser,
    tableTagParser,
    tbodyTagParser,
    trTagParser,
    thTagParser,
    tdTagParser,
    spanTagParser,
    aTagParser,
    imageTagParser,
    uTagParser,
    logoTagParser,
    h1TagParser,
    h2TagParser,
    h3TagParser,
    h4TagParser,
    h5TagParser,
  ];
}
