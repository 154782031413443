import React from 'react';
import { Field } from 'react-final-form';

import { Input, FormItem } from '@esurance/ui-components';
import { emailPattern } from '@esurance/legacy-constants';

interface IProps {
  name: string;
  validate?: (value: string | number | string[]) => string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export function EmailField(props: IProps): JSX.Element {
  const { name, validate } = props;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <FormItem
          htmlFor={input.name}
          required={props.required}
          label={props.label}
        >
          <Input
            {...input}
            pattern={emailPattern}
            placeholder={props.placeholder}
            type="email"
          />
          {meta.touched && meta.error && (
            <div className="v-error">{meta.error}</div>
          )}
        </FormItem>
      )}
    </Field>
  );
}
