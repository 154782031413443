import React from 'react';
import type { SvgIconProps } from '@mui/material';
import BoyIcon from '@mui/icons-material/Boy';
import CompanyIcon from '@mui/icons-material/Business';
import TeamIcon from '@mui/icons-material/GroupsRounded';
import GirlIcon from '@mui/icons-material/GirlRounded';

import type { Salutation } from '@esurance/legacy-constants';

interface IProps extends SvgIconProps {
  type?: Salutation,
}

export const OwnerIcon = (props: IProps) => {
  const { type, fontSize, color } = props;

  if (type === 'mrs') {
    return <GirlIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'sir') {
    return <BoyIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'company') {
    return <CompanyIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'team') {
    return <TeamIcon fontSize={fontSize} color={color} />;
  }

  return <TeamIcon fontSize={fontSize} color={color} />;
};
