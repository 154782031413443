import React from 'react';
import _ from 'lodash';

import { parserForTag } from './helpers';

export const spanTagParser = parserForTag('span', (element, children) => {
  const classes = element.getAttribute('className');
  if (classes) {
    return (
      <span className={classes}>{children}</span>
    );
  }
  return <span>{children}</span>;
});

export const ulTagParser = parserForTag('ul', (element, children) => (
  <ul>{children}</ul>
));

export const olTagParser = parserForTag('ol', (element, children) => {
  const start = element.getAttribute('start');
  if (start) {
    return <ol start={_.toNumber(start)}>{children}</ol>;
  }
  return <ol>{children}</ol>;
});

export const liTagParser = parserForTag('li', (element, children) => (
  <li>{children}</li>
));

export const bTagParser = parserForTag('b', (element, children) => (
  <b>{children}</b>
));

export const iTagParser = parserForTag('i', (element, children) => (
  <i>{children}</i>
));

export const strongTagParser = parserForTag('strong', (element, children) => (
  <strong>{children}</strong>
));

export const emTagParser = parserForTag('em', (element, children) => (
  <em>{children}</em>
));

export const pTagParser = parserForTag('p', (element, children) => (
  <p>{children}</p>
));

export const tableTagParser = parserForTag('table', (element, children) => (
  <table>{children}</table>
));

export const tbodyTagParser = parserForTag('tbody', (element, children) => (
  <tbody>{children}</tbody>
));

export const trTagParser = parserForTag('tr', (element, children) => (
  <tr>{children}</tr>
));

export const thTagParser = parserForTag('th', (element, children) => (
  <th>{children}</th>
));

export const tdTagParser = parserForTag('td', (element, children) => (
  <td>{children}</td>
));

export const uTagParser = parserForTag('u', (element, children) => (
  <u>{children}</u>
));

export const h1TagParser = parserForTag('h1', (element, children) => (
  <h1>{children}</h1>
));

export const h2TagParser = parserForTag('h2', (element, children) => (
  <h2>{children}</h2>
));

export const h3TagParser = parserForTag('h3', (element, children) => (
  <h3>{children}</h3>
));

export const h4TagParser = parserForTag('h4', (element, children) => (
  <h4>{children}</h4>
));

export const h5TagParser = parserForTag('h5', (element, children) => (
  <h5>{children}</h5>
));

export const hrTagParser = parserForTag('hr', () => <hr />);

export const brTagParser = parserForTag('br', () => <br />);

export const aTagParser = parserForTag('a', (element, children) => {
  const href = element.getAttribute('href');
  const target = element.getAttribute('target') || '_self';
  if (href) return <a href={href} target={target}>{children}</a>;
  return <span>{children}</span>;
});

export const imageTagParser = parserForTag('pict', (element) => {
  const src = element.getAttribute('src');
  const height = element.getAttribute('height');
  const width = element.getAttribute('width');
  const altText = element.getAttribute('alt');

  return (
    <img
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      src={unescape(src!)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      height={height!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      width={width!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt={altText!}
    />
  );
});
