import type { PaletteOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import {
  createMaterialAppBarTheme,
  createMaterialIconButtonTheme,
  createMaterialCardTheme,
  createMaterialCardHeaderTheme,
  createMaterialCardContentTheme,
  createMaterialCheckboxTheme,
  createMaterialFormHelperTextTheme,
  createMaterialIconTheme,
  createMaterialSvgIconTheme,
  createMaterialSwitchTheme,
  createMaterialTabsTheme,
  createMaterialTabTheme,
  createMaterialTooltipTheme,
  createMaterialDialogActionsStyles,
  createMuiAutocompleteStyles,
  createMuiFormControlStyles,
  createMuiFormHelperTextStyles,
  createMuiFormLabelStyles,
  createMuiInputBaseStyles,
  createMuiInputLabelStyles,
  createMuiMenuItemStyles,
  createMuiTextFieldStyles,
  createMuiButtonStyles,
  createMuiFormControlLabelStyles,
  createMaterialButtonTheme,
  createMaterialAlertTheme,
  createMuiAlertStyles,
} from './helpers';
import { breakpointsValues } from './constants/materialThemeBreakbointsValues';
import { baseTheme } from './baseTheme';
import { createMuiIconButtonStyles } from './helpers/stylesOverrides/createMuiIconButtonStyles';

// TODO: AD: make backoffice to inherit base theme
const palette: PaletteOptions = {
  background: {
    default: '#f4f4f4',
  },
  info: {
    main: '#F2F9FF',
  },
  border: {
    regular: '#acb1b9',
    regularHover: '#354052',
  },
  divider: '#ebedf8',
  alert: {
    standardInfo: {
      background: '#F1F4F8',
      text: '#4E5D76',
    },
    standardSuccess: {
      background: '#DAF8F2',
      text: '#00A58D',
    },
  },
};

const mainTheme = createTheme(deepmerge(baseTheme, {
  components: {
    MuiAutocomplete: {
      styleOverrides: createMuiAutocompleteStyles(),
    },
    MuiFormControl: {
      styleOverrides: createMuiFormControlStyles(),
    },
    MuiFormControlLabel: {
      styleOverrides: createMuiFormControlLabelStyles(),
    },
    MuiFormLabel: {
      styleOverrides: createMuiFormLabelStyles(),
    },
    MuiInputLabel: {
      styleOverrides: createMuiInputLabelStyles(),
    },
    MuiMenuItem: {
      styleOverrides: createMuiMenuItemStyles(),
    },
    MuiTablePagination: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCheckbox: {
      defaultProps: createMaterialCheckboxTheme(),
    },
    MuiTooltip: {
      defaultProps: createMaterialTooltipTheme(),
    },
    MuiFormHelperText: {
      styleOverrides: createMuiFormHelperTextStyles(),
      defaultProps: createMaterialFormHelperTextTheme(),
    },
    MuiSwitch: {
      defaultProps: createMaterialSwitchTheme(),
    },
    MuiIcon: {
      defaultProps: createMaterialIconTheme(),
    },
    MuiSvgIcon: {
      defaultProps: createMaterialSvgIconTheme({
        color: 'primary',
      }),
      styleOverrides: {
        colorPrimary: {
          color: '#17a0cf',
        },
        colorSecondary: {
          color: '#ccc',
        },
        colorAction: {
          color: '#333',
        },
        colorDisabled: {
          color: '#999',
        },
        colorError: {
          color: '#e26578',
        },
        fontSizeSmall: {
          fontSize: '20px',
        },
        fontSizeInherit: {
          fontSize: '35px',
        },
        fontSizeLarge: {
          fontSize: '45px',
        },
        root: {
          fontSize: '25px',

          '& path': {
            color: 'inherit',
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: createMaterialAppBarTheme(),
    },
    MuiIconButton: {
      defaultProps: createMaterialIconButtonTheme(),
      styleOverrides: createMuiIconButtonStyles({
        root: {
          cursor: 'pointer',
        },
      }),
    },
    MuiCard: {
      defaultProps: createMaterialCardTheme(),
    },
    MuiInputBase: {
      styleOverrides: createMuiInputBaseStyles({
        root: {
          height: '44px',
        },
      }),
    },
    MuiTextField: {
      styleOverrides: createMuiTextFieldStyles({
        root: {
          height: '44px',
        },
      }),
    },
    MuiCardHeader: {
      defaultProps: createMaterialCardHeaderTheme({
        style: {
          borderBottom: `1px solid ${palette.divider}`,
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      }),
    },
    MuiCardContent: {
      defaultProps: createMaterialCardContentTheme({
        style: {
          padding: '16px 24px',
          position: 'relative',
          minHeight: '300px',
        },
      }),
    },
    MuiTabs: {
      defaultProps: createMaterialTabsTheme(),
    },
    MuiTab: {
      defaultProps: createMaterialTabTheme({
        style: {
          minWidth: 'auto',
          textTransform: 'capitalize',
          fontSize: '15px',
        },
      }),
    },
    MuiDialogActions: {
      defaultProps: createMaterialDialogActionsStyles(),
    },
  },
  palette,
  breakpoints: {
    values: {
      ...breakpointsValues,
    },
  },
}));

export const backofficeTheme = createTheme(mainTheme, {
  components: {
    MuiButton: {
      styleOverrides: createMuiButtonStyles(mainTheme),
      defaultProps: createMaterialButtonTheme(),
    },
    MuiAlert: {
      defaultProps: createMaterialAlertTheme(),
      styleOverrides: createMuiAlertStyles(mainTheme),
    },
  },
});
