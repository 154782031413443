import './Input.styl';

import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * @deprecated
 */
// eslint-disable-next-line
export interface IInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  prefix?: React.ReactNode;
  error?: string | null;
}

/**
 * @deprecated
 */
export const Input = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const {
    className: containerClassName,
    prefix = null,
    onFocus,
    onBlur,
    error,
    ...rest
  } = props;
  const [isFocused, setFocused] = useState(false);
  const [translate] = useTranslation();

  const inputContainerClassName = classNames('es-input-wrapper', containerClassName, {
    focused: isFocused,
  });

  const prefixElement = prefix && (
    <div className="es-input-prefix">
      {prefix}
    </div>
  );

  return (
    <div className="es-input-container">
      <div className={inputContainerClassName}>
        {prefixElement}
        <input
          ref={ref}
          className="es-input"

          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            setFocused(true);
            if (onFocus) onFocus(event);
          }}

          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            setFocused(false);
            if (onBlur) onBlur(event);
          }}

          {...rest}
        />
      </div>

      {error && (
        <div className="error">
          {translate(error)}
        </div>
      )}
    </div>
  );
});
