import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';
import type { IQuestionnaireContent } from '@esurance/entities';

import { axios } from '../managers/axios-wrapper';

import type { ISurveyRepository } from './interfaces/ISurveyRepository';

/* eslint-disable class-methods-use-this */
@injectable()
export class SurveyRepository implements ISurveyRepository {
  public constructor(
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) {
    //
  }

  public getQuestionnaire(
    orgId: number,
  ): Promise<IQuestionnaireContent> {
    return axios
      .get<IQuestionnaireContent>(
        `${this.backofficeApiRoot}/organization/${orgId}/questionnaire`,
      )
      .then((response) => response.data);
  }

  public postQuestionnaire(
    orgId: number,
    data: IQuestionnaireContent,
  ): Promise<boolean> {
    return axios
      .post<boolean>(
        `${this.backofficeApiRoot}/organization/${orgId}/questionnaire-reply`,
        {
          data,
        },
      )
      .then((response) => response.data);
  }
}
