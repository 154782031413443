import type { AppBarProps } from '@mui/material';

export function createMaterialAppBarTheme(
  overrides?: Partial<AppBarProps>,
): Partial<AppBarProps> {
  return {
    style: {
      boxShadow: 'none',
    },
    ...overrides,
  };
}
