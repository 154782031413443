export function redirectToAppSelector() {
  let newUrl = window.location.origin.replace(
    /\/\/.+?\./, '//app.',
  );

  if (newUrl.endsWith('//app.ch')) {
    newUrl = newUrl.replace('//app.ch', '//app.esurance.ch');
  }
  window.location.replace(newUrl);
}
