import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dropdown,
  DropdownArrowType,
  DropdownArrowIcon,
} from '@esurance/ui-components';
import type { IHistoryManager } from '@esurance/services';
import {
  DependencyType as ServicesDependencyType,
} from '@esurance/services';
import type { IAuthManager } from '@esurance/auth';
import {
  isNewCustomerFlowData,
  isUpsellingFlowData,
  DependencyType as AuthDependencyType,
} from '@esurance/auth';
import { useInjection } from '@esurance/ioc-base';

const enum ActionValue {
  Logout = 'logout',
  Operator = 'consultant',
}

function onSelect(historyManager: IHistoryManager, actionValue: string): void {
  if (actionValue === ActionValue.Logout) {
    historyManager.navigateTo('/logout');
  }
}

export function OperatorSelect(): JSX.Element | null {
  const [translate] = useTranslation();
  const authManager = useInjection<IAuthManager>(AuthDependencyType.AuthManager);
  const historyManager = useInjection<IHistoryManager>(ServicesDependencyType.HistoryManager);

  const { flow, identity } = authManager;
  if (!identity) {
    return null;
  }

  let operatorOptionTitle = identity.name || '';
  if (isUpsellingFlowData(flow)) {
    operatorOptionTitle += ` (${flow.organization.name})`;
  }
  if (isNewCustomerFlowData(flow)) {
    operatorOptionTitle += ` (${translate('header-section_new-customer-flow')})`;
  }

  const options = [
    {
      value: ActionValue.Operator,
      title: operatorOptionTitle,
    },
    {
      value: ActionValue.Logout,
      title: translate('header-section_log-out'),
    },
  ];

  return (
    <Dropdown
      className="operator-select"
      arrowType={DropdownArrowType.Chevron}
      list={options}
      selected={options[0].value}
      triggerIcon={<DropdownArrowIcon />}
      onClick={(val) => onSelect(historyManager, val)}
    />
  );
}
