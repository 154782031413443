import _ from 'lodash';

import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type {
  AnyPriceParameterElement,
  BookConsultantionDto,
  IConsultantData,
  IGastroConsultantResponseDto,
  IPriceRequest,
  IPriceResponse,
  IProductCoverage,
  IProductEntityDetails,
  IRequestSurveyCreate,
  IZipData,
} from '@esurance/entities';

import { getLangHeader } from '../service/helper';
import { generateAndDownloadFile } from '../service/generateAndDownloadFile';

function mapCoveragePriceParameter(
  coverageId: number,
  data: AnyPriceParameterElement,
): AnyPriceParameterElement {
  return Object.assign(data, {
    coverage_id: coverageId,
  });
}

// TODO: Move to Gastro repository
class GastroClass {
  public getProductDetails(productId: number | string): Promise<IProductEntityDetails> {
    return axios
      .get<IProductEntityDetails>(
        `${diContainer.backofficeApiRoot}/product/${productId}`,
      )
      .then((response) => response.data);
  }

  public getProductCoverages(productId: number | string): Promise<IProductCoverage[]> {
    return axios
      .get<IProductCoverage[]>(
        `${diContainer.backofficeApiRoot}/product/${productId}/coverages`,
      )
      .then((response) => response.data);
  }

  public getCoveragePriceParameters(coverageId: number): Promise<AnyPriceParameterElement[]> {
    return axios
      .get<AnyPriceParameterElement[]>(
        `${diContainer.backofficeApiRoot}/tariff_management/coverage/${coverageId}/price_parameters`,
        {
          headers: getLangHeader(),
        },
      )
      .then((response) => response.data.map((parameter) => (
        mapCoveragePriceParameter(coverageId, parameter)
      )));
  }

  public calculatePriceFromCoverageParams(
    data: IPriceRequest,
  ): Promise<IPriceResponse> {
    return axios
      .post(
        `${diContainer.backofficeApiRoot}/tariff_management/coverage/calculate_price`,
        data,
      )
      .then((response) => {
        const { price } = response.data;
        if (
          Object.prototype.hasOwnProperty.call(price, 'price_breakdown')
          && !_.isEmpty(price.price_breakdown)
        ) {
          const {
            employee = null,
            employer = null,
            majority_owner = null,
            family_member = null,
          } = price.price_breakdown;

          return {
            price: {
              priceBreakdown: {
                employee,
                employer,
                majorityOwner: majority_owner,
                familyMember: family_member,
              },
              total: response.data.total,
              ...response.data.price,
            },
          };
        }

        return response.data;
      });
  }

  public generatePDFOffer(data: IPriceRequest[]): Promise<void> {
    return axios
      .post<any>(
        `${diContainer.affinityV3ApiRoot}/coverage/generate-offer`,
        {
          products: data,
        },
        {
          headers: getLangHeader(),
          responseType: 'arraybuffer',
        },
      )
      .then(generateAndDownloadFile);
  }

  public createDataBasedOnSurveyReplies(
    data: IRequestSurveyCreate,
  ): Promise<boolean> {
    return axios
      .post<boolean>(`${diContainer.affinityApiRoot}/reply/cart/product`, data, {
        headers: getLangHeader(),
      })
      .then((response) => response.data);
  }

  public getRegionByZip(zip: string | number): Promise<IZipData> {
    return axios
      .get<IZipData>(
        `${diContainer.backofficeApiRoot}/tariff_management/region/${zip}`,
        {
          headers: getLangHeader(),
        },
      )
      .then((response) => response.data);
  }

  public getGastroConsultantByZip(
    zip: string,
  ): Promise<IConsultantData> {
    return axios
      .get<IGastroConsultantResponseDto>(
        `${diContainer.affinityApiRoot}/business-stream/gastrosuisse/zip/${zip}/consultant`,
      )
      .then((response) => ({
        avatar: response.data.avatar,
        contacts: response.data.contacts,
        id: response.data.id,
        name: `${response.data.firstName} ${response.data.lastName}`,
        title: response.data.title,
      }));
  }

  public bookConsultantion(
    id: number,
    data: BookConsultantionDto,
  ): Promise<boolean> {
    return axios
      .post(
        `${diContainer.affinityApiRoot}/business-stream/gastrosuisse/consultant/${id}/email/send`,
        data,
        {
          headers: getLangHeader(),
        },
      )
      .then((response) => response.data);
  }
}

export const gastro = new GastroClass();
