import _ from 'lodash';
import type { KeyboardEvent, SyntheticEvent } from 'react';
import React from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';

import './search-input.styl';

interface IProps {
  defaultValue?: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  validationMessage?: string;
}

export class SearchInput extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    placeholder: '',
    validationMessage: '',
  };

  private input: HTMLInputElement | null = null;

  private debounceSubmit = _.debounce((value: string) => {
    const { onSubmit } = this.props;
    if (value.length > 2) {
      if (onSubmit) {
        onSubmit(value);
      }
    }
  }, 1000);

  public render(): JSX.Element {
    const { defaultValue, name, placeholder } = this.props;

    return (
      <fieldset className="search-field">
        <div className="input-control search-control">
          <input
            ref={(input) => {
              this.input = input;
              return input;
            }}
            placeholder={placeholder}
            name={name}
            id={name}
            onChange={this.handleChange}
            autoComplete="off"
            type="text"
            onKeyDown={this.handleKeyboardSubmit}
            defaultValue={defaultValue}
          />

          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className="submit" onClick={this.handleSubmit}>
            <SearchIcon />
          </div>
        </div>
      </fieldset>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }

    const { value } = event.target;
    if (_.isEmpty(value)) {
      this.handleSubmit(event);
    } else {
      this.debounceSubmit(value);
    }
  };

  private handleKeyboardSubmit = (event: KeyboardEvent<HTMLElement>): void => {
    if (event.keyCode === 13) {
      this.handleSubmit(event);
    }
  };

  private handleSubmit = (event: SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();
    const { onSubmit } = this.props;

    if (onSubmit && this.input) {
      onSubmit(this.input.value);
    }
  };
}

export default SearchInput;
