import { Container } from 'inversify';
import getDecorators from 'inversify-inject-decorators';

import { configureContainer } from './configureContainer';

const inversifyContainer = new Container({ defaultScope: 'Singleton' });
configureContainer(inversifyContainer);

const { lazyInject } = getDecorators(inversifyContainer);

export { lazyInject };
export { inversifyContainer };
