import React from 'react';
import { useTranslation } from 'react-i18next';
import type { InputProps } from '@mui/material';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { Tooltip } from '../Tooltip/Tooltip';

export interface ITextFieldProps extends InputProps {
  label?: string;
  extraLabel?: string;
  tip?: string;
  valid?: boolean;
  validationMessage?: string;
  touched?: boolean;
}

const StyledTooltip = styled(Tooltip)({
  position: 'absolute',
  right: '20px',
  top: '-7px',
  padding: '0 5px',
  background: 'white',
  zIndex: 1,
});

export function TextField(props: ITextFieldProps): JSX.Element {
  const { t: translate } = useTranslation();
  const {
    valid,
    validationMessage,
    label,
    extraLabel,
    tip,
    touched,
    ...rest
  } = props;
  const id = `${props.name || ''}${props.type}`;

  const showError = touched && !valid;
  const errorMessage = _.isEmpty(validationMessage)
    ? translate('input_default-invalid-message')
    : validationMessage;

  const controlled = 'value' in props && 'onChange' in props;

  if (controlled) {
    delete rest.defaultValue;
  } else {
    delete rest.value;
  }

  return (
    <FormControl error={showError}>
      <InputLabel
        variant="outlined"
        htmlFor={id}
        id={label}
      >
        {label}
        {extraLabel}
      </InputLabel>

      {tip && (
        <StyledTooltip title={tip}>
          <InfoIcon fontSize="small" />
        </StyledTooltip>
      )}

      <OutlinedInput
        {...rest}
        label={label}
      />

      {showError && (
        <FormHelperText data-test={`error_${id}`}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}
