import * as Centrifuge from 'centrifuge';
import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';
import { AuthEvent, DependencyType as AuthDependencyType, IAuthManager } from '@esurance/auth';

import { DependencyType } from '../DependencyType';

import { SocketListener } from './SocketListener';
import type { ISocketManager } from './interfaces';
import { IEventManager } from './interfaces';
import { axios } from './axios-wrapper';

@injectable()
export class SocketManager implements ISocketManager {
  private token: string;

  private timestamp: string;

  private centrifuge: any;

  public constructor(
    @inject(DependencyType.EventManager)
    private eventManager: IEventManager,
    @inject(AuthDependencyType.AuthManager)
    private authManager: IAuthManager,
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) {}

  public async init(): Promise<void> {
    this.authManager.on(AuthEvent.SessionDataChanged, () => {
      this.break();
      this.connect();
    });

    this.authManager.on(AuthEvent.Logout, () => {
      this.break();
    });

    if (this.authManager.isAuthenticated) {
      await this.connect();
    }
  }

  public break(): void {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
    this.centrifuge = undefined;
  }

  private getToken(): Promise<void> {
    return axios
      .get<{ token: string; timestamp: number }>(
        `${this.backofficeApiRoot}/websocket/token`,
      )
      .then((response) => {
        this.token = response.data.token;
        this.timestamp = response.data.timestamp.toString();
      });
  }

  private async connect(): Promise<void> {
    try {
      if (this.centrifuge || !this.authManager.contact) {
        return;
      }

      await this.getToken();

      if (this.token) {
        return;
      }

      const { userId } = this.authManager;
      const settings = {
        timestamp: this.timestamp.toString(),
        token: this.token,
        url: `${window.location.origin}/websocket`,
        user: `${userId}`,
      };

      this.centrifuge = new Centrifuge(settings);
      const callbacks = new SocketListener(this.eventManager);

      this.centrifuge.subscribe(`events#${userId}`, callbacks);
      this.centrifuge.connect();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('SocketManager: Failed to set connection: ', err);
    }
  }
}
