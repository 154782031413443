import type { SwitchProps } from '@mui/material';

export function createMaterialSwitchTheme(
  overrides?: Partial<SwitchProps>,
): Partial<SwitchProps> {
  return {
    color: 'primary',
    ...overrides,
  };
}
