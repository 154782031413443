import type { ChangeEvent } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Box, TextField, Typography, useTheme,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';

import type { IFormField } from '../../models/forms/interfaces';

export interface IFormSelectOption<T> {
  value: T | string;
  title: string;
}

interface IFormSelectFieldProps {
  loading?: boolean;
  model: IFormField<string>;
  label?: string;
  id?: string;
}

export const FormSelect = observer((props: IFormSelectFieldProps): JSX.Element | null => {
  const theme = useTheme();
  const [translate] = useTranslation();
  const { loading, model, id } = props;

  const handleChange = (event: ChangeEvent<{}>, newValue: IFormSelectOption<string> | null) => {
    if (newValue === null) {
      model.change('');
      return;
    }
    model.change(newValue.value);
  };

  const label = props.label || model.label && translate(model.label);
  if (!model.options) return null;
  const translatedOptions = model.options.map((o) => ({
    title: translate(o.title),
    value: o.value,
  }));
  const selectedOption = model.options.find((o) => o.value === model.value);
  const translatedSelectedOption = selectedOption && {
    value: selectedOption.value,
    title: translate(selectedOption.title),
  };

  const dataTest = id || label;

  return (
    <Box data-test={dataTest ? `${dataTest}-container` : undefined} width="100%" mb="16px">
      <Typography variant="caption">
        {label}
        {model.isRequired
          && (
            <Typography
              sx={{
                paddingLeft: theme.spacing(0.5),
                color: theme.palette.text.hint,
              }}
              display="inline"
            >
              *
            </Typography>
          )}
      </Typography>
      <Autocomplete
        id={id || label}
        loading={loading}
        options={translatedOptions}
        value={translatedSelectedOption}
        onInputChange={model.touch}
        onBlur={model.touch}
        onChange={handleChange}
        getOptionLabel={(option: IFormSelectOption<string>) => option.title}
        size="small"
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder={translate('dropdown-select-value-placeholder')}
            error={model.isTouched && model.isInvalid}
            helperText={model.showErrorMessage && translate(model.errorMessage as string)}
          />
        )}
        sx={model.showErrorMessage
          ? {
            marginBottom: theme.spacing(2),
          }
          : {
            marginBottom: 0,
            '& .MuiFormHelperText-root': {
              marginTop: 0,
            },
          }}
      />
    </Box>
  );
});
