import React from 'react';
import { styled } from '@mui/material/styles';
import { resolve } from 'inversify-react';
import { observer } from 'mobx-react';
import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import AccountBoxOutlined from '@mui/icons-material/AccountBoxOutlined';
import type { WithTranslation } from 'react-i18next';
import { useTranslation, withTranslation } from 'react-i18next';

import { Loader } from '@esurance/ui-components';

import { DependencyType } from '../../bootstrap/ioc/DependencyType';
import type {
  IAvailableApplicationData,
  IAvailableTenantData,
} from '../../models/interfaces';
import {
  AppType,
  IAppSelector,
} from '../../models/interfaces';

const ListLoader = styled(Loader)({
  zIndex: 1101,
});

const RootPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  flex: 1,
});

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
}));

const StyledMenuList = styled(MenuList)({
  width: '374px',
  fontWeight: 'bold' as const,
  padding: 0,
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 'bold' as const,
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: '51px',
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '42px',
});

@observer
class AppSelector extends React.Component<WithTranslation> {
  @resolve(DependencyType.AppSelectorModel)
  private model: IAppSelector;

  public async componentDidMount(): Promise<void> {
    await this.model.load();
  }

  public render(): JSX.Element | null {
    const { t: translate } = this.props;
    const { isLoading, userPermissions } = this.model;

    if (isLoading || !userPermissions) {
      return <ListLoader loading />;
    }

    return (
      <RootPaper elevation={0}>
        <Title variant="h1">
          {translate('select-application-title')}
        </Title>
        <Subtitle variant="body1">
          {translate('select-application-subtitle')}
        </Subtitle>
        {
          userPermissions.tenants.map((tenant) => (
            <TenantInfo
              tenant={tenant}
              model={this.model}
              key={tenant.name}
            />
          ))
        }
      </RootPaper>
    );
  }
}

export const AppSelectorView = (withTranslation()(AppSelector));

interface ITenantInfoProps {
  model: IAppSelector;
  tenant: IAvailableTenantData;
}

export function TenantInfo({ tenant, model }: ITenantInfoProps): JSX.Element {
  const { t: translate, i18n } = useTranslation();

  function getTenantBoundAppTitle(app: IAvailableApplicationData): string {
    let { title } = app;
    const tenantBoundTitle = `${tenant.name}.${title}`;
    if (i18n.exists(tenantBoundTitle)) {
      title = tenantBoundTitle;
    }
    return title;
  }

  return (
    <div>
      <StyledMenuList>
        {tenant.availableApplications.map((app) => (
          <StyledMenuItem
            key={app.title}
            onClick={() => model.handleRedirect(app)}
          >
            <StyledListItemIcon>
              <AppIcon type={app.type} />
            </StyledListItemIcon>
            <Typography variant="inherit">{translate(getTenantBoundAppTitle(app))}</Typography>
          </StyledMenuItem>
        ))}
      </StyledMenuList>
    </div>
  );
}

interface IAppIconProps {
  type: AppType;
}

function AppIcon({ type }: IAppIconProps): JSX.Element {
  // TODO: Keycloak: add different icons for different applications
  if (type === AppType.Cockpit) {
    return <AccountBoxOutlined />;
  }
  return <AccountBoxOutlined />;
}
