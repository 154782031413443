import classNames from 'classnames';
import i18next from 'i18next';
import _ from 'lodash';
import type { FocusEvent } from 'react';
import React from 'react';
// @ts-ignore
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {
  formatDate,
  parseDate,
  // @ts-ignore
} from 'react-day-picker/moment';
import type { Modifier } from 'react-day-picker/types/common';
import InfoIcon from '@mui/icons-material/InfoRounded';
import CalendarIcon from '@mui/icons-material/DateRangeRounded';

import { DateFormat, datePattern } from '@esurance/legacy-constants';

import { _t } from '../../service';
import { Tip } from '../element/tip';

// Make sure moment.js has the required locale data
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import './datepicker.styl';

interface IProps {
  label?: string;
  tip?: {
    content: string;
    width: number;
  };
  placeholder?: string;
  name?: string;
  validationMessage?: string;
  value?: Date;
  onChange?: (value: Date) => void;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  disabledDays?: Modifier | Modifier[];
  month?: Date;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
}

export class Datepicker extends React.Component<IProps> {
  public static defaultProps = {
    placeholder: DateFormat.FRONTEND_DATE,
    readOnly: false,
    validationMessage: '',
  };

  private input?: HTMLInputElement | HTMLTextAreaElement;

  public componentWillUnmount(): void {
    this.input = undefined;
  }

  public render(): JSX.Element {
    const {
      label,
      className,
      validationMessage,
      name: lower,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      ...rest
    } = this.props;

    const { readOnly, required } = this.props;

    const id = `${lower}datepicker`;

    const propsToPass = {
      ...rest,
      className: '',
      id,
      name: lower,
      onBlur: this.handleBlur,
    };

    const fieldsetClassName = classNames(
      'datepicker',
      {
        readonly: readOnly,
        required: required && !readOnly,
      },
      className,
    );

    return (
      <fieldset className={fieldsetClassName}>
        {label && this.renderLabel(id, label)}
        {this.renderField(propsToPass)}

        <div className="error">
          {_.isEmpty(validationMessage)
            ? _t('input_default-invalid-message')
            : validationMessage}
        </div>
      </fieldset>
    );
  }

  private renderLabel(id: string, label: string): JSX.Element {
    const { tip } = this.props;
    return (
      <label htmlFor={id}>
        {label}
        {tip && (
          <Tip {...tip}>
            <InfoIcon fontSize="small" />
          </Tip>
        )}
      </label>
    );
  }

  private renderField(propsToPass: any): JSX.Element {
    const {
      readOnly,
      placeholder,
      disabledDays,
      month,
    } = this.props;
    if (readOnly) {
      return (
        <div className="date-input-wrapper">
          <CalendarIcon className="calendar" color="secondary" />
          <input
            type="text"
            placeholder={placeholder}
            value={
              propsToPass.value
                ? formatDate(
                  propsToPass.value,
                  DateFormat.FRONTEND_DATE,
                )
                : undefined
            }
            id={propsToPass.id}
            name={propsToPass.name}
            readOnly
            ref={this.setRef}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
          />
        </div>
      );
    }

    return (
      <div className="date-input-wrapper">
        <div className="arrow-down" />
        <CalendarIcon className="calendar" color="secondary" />
        <DayPickerInput
          dayPickerProps={{
            disabledDays,
            firstDayOfWeek: 1,
            locale: i18next.language,
            localeUtils: MomentLocaleUtils,
            month: !_.isNil(month) && month,
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          format={DateFormat.FRONTEND_DATE}
          placeholder={placeholder}
          value={propsToPass.value || ''}
          inputProps={{
            autoComplete: 'off',
            id: propsToPass.id,
            name: propsToPass.name,
            pattern: datePattern,
            ref: this.setRef,
            required: propsToPass.required,
            title: `${DateFormat.FRONTEND_DATE}`,
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
          }}
          onDayChange={this.handleDayChange}
        />
      </div>
    );
  }

  private handleDayChange = (day: Date, modifiers: any): void => {
    const { onChange } = this.props;

    if (day && modifiers.disabled) {
      this.input?.setCustomValidity('Data is invalid');
      return;
    }
    this.input?.setCustomValidity('');

    if (day && onChange) {
      onChange(day);
    }
  };

  private setRef = (input: any): void => {
    this.input = input;
  };

  private handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const { onBlur } = this.props;
    if (this.input) {
      this.input.classList.add('touched');

      if (onBlur) {
        onBlur(event);
      }
    }
  };

  private handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus(event);
    }
  };
}
