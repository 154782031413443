import React from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const Krankentaggeld = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd">
      <path fillRule="nonzero" d="M21.65 6.5a.35.35 0 0 1 .7 0v12A2.355 2.355 0 0 1 20 20.85h-7a2.356 2.356 0 0 1-2.35-2.35v-12a.35.35 0 0 1 .7 0v12c0 .906.744 1.65 1.65 1.65h7c.906 0 1.65-.744 1.65-1.65v-12z" />
      <path fillRule="nonzero" d="M22.65 5a1.15 1.15 0 0 0-1.15-1.15h-10A1.15 1.15 0 0 0 10.35 5v1.15h12.3V5zM23 6.85H10a.35.35 0 0 1-.35-.35V5a1.85 1.85 0 0 1 1.85-1.85h10A1.85 1.85 0 0 1 23.35 5v1.5a.35.35 0 0 1-.35.35zm-8 10a.35.35 0 0 1-.35-.35v-7a.35.35 0 0 1 .35-.35h7a.35.35 0 0 1 .35.35v7a.35.35 0 0 1-.35.35h-7zm6.65-.7v-6.3h-6.3v6.3h6.3zM8.65 19a1.15 1.15 0 0 0-1.15-1.15h-5a1.151 1.151 0 0 0 0 2.3h5A1.15 1.15 0 0 0 8.65 19zm.7 0a1.85 1.85 0 0 1-1.85 1.85h-5a1.851 1.851 0 0 1 0-3.7h5A1.85 1.85 0 0 1 9.35 19z" />
      <path fillRule="nonzero" d="M4.65 17.5a.35.35 0 0 1 .7 0v3a.35.35 0 0 1-.7 0v-3zm4.698-6.813a1.149 1.149 0 0 0-1.626 0l-3.535 3.535a1.15 1.15 0 0 0 1.626 1.627l3.535-3.536a1.149 1.149 0 0 0 0-1.626zm.495 2.121-3.535 3.536a1.85 1.85 0 0 1-2.616-2.617l3.535-3.535a1.849 1.849 0 0 1 2.616 0 1.849 1.849 0 0 1 0 2.616z" />
      <path fillRule="nonzero" d="M5.46 12.454a.35.35 0 1 1 .495-.495l2.121 2.121a.35.35 0 1 1-.495.495L5.46 12.454z" />
    </g>
  </SvgIcon>
);
