import React from 'react';
import { useTheme } from '@mui/material/styles';

interface IProps {
  checked?: boolean;
  indeterminate?: boolean;
}

export function CheckboxIcon({ checked = false, indeterminate = false }: IProps): JSX.Element {
  const theme = useTheme();

  const borderColor = theme.components?.MuiCheckbox?.defaultProps?.color === 'default'
    ? '#ccc'
    : theme.palette.primary.main;

  const markerColor = theme.components?.MuiCheckbox?.defaultProps?.color === 'default'
    ? '#000'
    : theme.palette.primary.main;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
      <defs>
        <rect id="b" width="19" height="19" rx="2" />
        <filter id="a" width="178.9%" height="178.9%" x="-39.5%" y="-39.5%" filterUnits="objectBoundingBox">
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feMorphology in="SourceAlpha" radius="1" result="shadowInner" />
          <feOffset in="shadowInner" result="shadowInner" />
          <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.0901960784 0 0 0 0 0.62745098 0 0 0 0 0.811764706 0 0 0 0.0730298913 0" />
        </filter>
      </defs>
      <g transform="translate(.5 .5)" fill="none" fillRule="evenodd">
        <use fill={borderColor} filter="url(#a)" xlinkHref="#b" />
        <rect width="18" height="18" x="0" y="0" stroke={borderColor} strokeOpacity=".818" rx="2" />
        {checked && (
          <path transform="translate(-5.5 -5.5)" fill={markerColor} d="M18.942 11.189a.585.585 0 0 0-.439-.189.585.585 0 0 0-.438.189l-4.233 4.424-1.897-1.987a.585.585 0 0 0-.438-.188.585.585 0 0 0-.439.188l-.877.916A.638.638 0 0 0 10 15c0 .18.06.332.18.458l2.336 2.438.878.915c.12.126.266.189.438.189a.585.585 0 0 0 .439-.189l.877-.915 4.671-4.876a.638.638 0 0 0 .181-.458c0-.18-.06-.332-.18-.458l-.878-.915z" />
        )}

        {indeterminate && (
          <path transform="translate(-0.5 -1)" fill={markerColor} d="M14.25 8C14.67 8 15 8.33 15 8.75C15 9.2 15 9.8 15 10.25C15 10.66 14.67 11 14.25 11C12.2 11 6.8 11 4.75 11C4.34 11 4 10.66 4 10.25C4 9.8 4 9.2 4 8.75C4 8.33 4.34 8 4.75 8C6.8 8 12.2 8 14.25 8Z" />
        )}
      </g>
    </svg>
  );
}
