import type { ICompanySuggestionData } from '../interfaces/ICompanySuggestionData';
import type { ICompanySuggestionResponseDto } from '../interfaces';

export function parseCompanySuggestionDto(
  dto: ICompanySuggestionResponseDto,
): ICompanySuggestionData {
  const { street, number } = parseStreetWithNumber(dto.street_name_with_number);
  return {
    name: dto.name,
    uid: dto.uid,
    street,
    number,
    postal: dto.postal_code,
    town: dto.town,
    phone: dto.phone_number,
  };
}

function parseStreetWithNumber(streetWithNumber: string): { street: string, number: string } {
  const index = streetWithNumber.lastIndexOf(' ');
  const street = streetWithNumber.substr(0, index);
  let number = '';
  if (index !== -1) {
    number = streetWithNumber.substr(index + 1);
  }
  return { street, number };
}
