import type { FocusEvent } from 'react';
import React from 'react';
import _ from 'lodash';

import type { ISurveyQuestionPeopleName } from '@esurance/entities';

import { SurveyTabIndex } from '../tab-index';
import { Input } from '../../form/input';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionPeopleName;
  repeaterAnswer: number;
}

export default class QuestionText extends React.Component<IProps> {
  private names: {
    [key: number]: string;
  } = {};

  public render(): JSX.Element {
    const { item, onChange, repeaterAnswer } = this.props;

    const handleBlur = (event: FocusEvent<HTMLInputElement>, i: number): void => {
      if (event.currentTarget.value) {
        this.names[i] = event.currentTarget.value;

        onChange(item, {
          names: _.values(this.names),
        });
      }
    };

    return (
      <BaseQuestion title={item.title}>
        {_.times(repeaterAnswer, (i) => {
          SurveyTabIndex.value += 1;
          return (
            <Input
              key={i}
              type="text"
              label=""
              onBlur={(event) => handleBlur(event, i)}
              tabIndex={SurveyTabIndex.value}
            />
          );
        })}
      </BaseQuestion>
    );
  }
}
