import type { Theme } from '@mui/material';
import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type MuiAlertStyles = ComponentsOverrides['MuiAlert'];

export function createMuiAlertStyles(
  theme: Theme,
  overrides?: Partial<MuiAlertStyles>,
): Partial<MuiAlertStyles> {
  return {
    standardInfo: {
      color: theme.palette.alert.standardInfo.text,
      background: theme.palette.alert.standardInfo.background,
    },

    standardSuccess: {
      color: theme.palette.alert.standardSuccess.text,
      background: theme.palette.alert.standardSuccess.background,
    },

    ...overrides,
  };
}
