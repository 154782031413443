import type { Container } from 'inversify';

import { initConstants } from '@esurance/constants';
import { ConstantDependency } from '@esurance/affinity-constants';

import { environment } from '../../../environments/environment';
import { AppSelectorRepository } from '../../repositories/AppSelectorRepository';
import type { IAppSelector, IAppSelectorRepository } from '../../models/interfaces';
import { AppSelector } from '../../models/AppSelector';

import { DependencyType } from './DependencyType';

export async function configureContainer(container: Container): Promise<void> {
  await initConstants(container, environment);

  container
    .bind(ConstantDependency.ApplicationName)
    .toConstantValue('app-selector');

  container
    .bind<IAppSelector>(DependencyType.AppSelectorModel)
    .to(AppSelector);

  container
    .bind<IAppSelectorRepository>(DependencyType.AppSelectorRepository)
    .to(AppSelectorRepository);
}
