import type { DialogActionsProps } from '@mui/material';

export function createMaterialDialogActionsStyles(
  overrides?: Partial<DialogActionsProps>,
): Partial<DialogActionsProps> {
  return {
    sx: {
      padding: '8px 24px',
    },
    ...overrides,
  };
}
