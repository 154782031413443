import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { DependencyType } from '../bootstrap/ioc/DependencyType';

import type { IAppSelector, IAvailableApplicationData, IUserPermissionsData } from './interfaces';
import { IAppSelectorRepository } from './interfaces';

@injectable()
export class AppSelector implements IAppSelector {
  public userPermissions: IUserPermissionsData;

  public isLoading = true;

  constructor(
    @inject(DependencyType.AppSelectorRepository)
    private appSelectorRepository: IAppSelectorRepository,
  ) {
    makeAutoObservable(this);
  }

  public load = async (): Promise<void> => {
    this.userPermissions = await this.appSelectorRepository.get();
    this.tryDirectRedirect();
    this.isLoading = false;
  };

  private tryDirectRedirect(): void {
    const availableApps = this.userPermissions.tenants
      .reduce<IAvailableApplicationData[]>((apps, tnt) => (
        [...apps, ...tnt.availableApplications]
      ), []);
    if (availableApps.length === 1) {
      this.handleRedirect(availableApps[0]);
    }
  }

  public handleRedirect = (app: IAvailableApplicationData): void => {
    window.location.href = app.url;
  };
}
