export interface IRule<T> {
  message: string;
  name?: string;
  validationRule: (value: T, originValue?: T) => boolean;
  validWhen: boolean;
}

export interface IValidationResult {
  name?: string;
  message: string;
  isInvalid: boolean;
}

export interface IValidation {
  isValid: boolean;
  result: IValidationResult[];
}

export class Validator<T> {
  private validations: IRule<T>[];

  public constructor(validations: IRule<T>[]) {
    this.validations = validations;
  }

  public validate(data: T, originData?: T): IValidation {
    const validation = this.valid();

    this.validations.forEach((rule) => {
      const validationMethod = rule.validationRule;

      if (validationMethod(data, originData) !== rule.validWhen) {
        validation.result.push({
          isInvalid: true,
          message: rule.message,
          ...(Object.prototype.hasOwnProperty.call(rule, 'name') ? { name: rule.name } : {}),
        });

        validation.isValid = false;
      }
    });

    return validation;
  }

  private valid(): IValidation {
    return {
      isValid: true,
      result: [],
    };
  }
}
