import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';
import './TranslatedFieldKeyLabel.styl';

interface IProps {
  className?: string;
}

export function TranslatedFieldKeyLabel(
  props: React.PropsWithChildren<IProps>,
): JSX.Element {
  const [translate] = useTranslation();
  const { children, className } = props;
  const rootClasses = cn('translated-field-key-label', className);
  return (
    <div className={rootClasses}>
      <CopyToClipboard
        text={children as string}
      >
        <Icon
          contain
          linkGrey
          type="content-copy"
          size="xs"
          iconSize="xs"
          title={translate('icon-tooltip-copy-to-clipboard')}
          onClick={handleCopyLabel}
        />
      </CopyToClipboard>
      <span>{children}</span>
    </div>
  );
}

function handleCopyLabel(): boolean {
  return false;
}
