import { expandMobXArrayIfRequired } from './expandMobXArrayIfRequired';

export function fillMobXArray<T>(
  array: T[],
  value: T,
  maxLength: number,
): void {
  expandMobXArrayIfRequired(array, maxLength - 1);
  array.fill(value);
}
