import Raven from 'raven-js';
import { inject, injectable } from 'inversify';

import { DependencyType, IAuthManager } from '@esurance/auth';
import { ConstantDependency } from '@esurance/constants';

import type { ISentryManager } from './interfaces';

@injectable()
export class SentryManager implements ISentryManager {
  public constructor(
    @inject(DependencyType.AuthManager)
    private authManager: IAuthManager,

    @inject(ConstantDependency.SentryPublicDsn)
    private publicDsn: string,

  ) {}

  public init(): void {
    const { publicDsn } = this;
    const enable = !!publicDsn;

    if (enable) {
      Raven.config(publicDsn, {
        sampleRate: 1,
      }).install();
    }
  }
}
