import _, { debounce } from 'lodash';
import i18next from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { format } from 'date-fns';

import type { ICancellablePromise, ITranslatable } from '@esurance/entities';
import { diContainer } from '@esurance/ioc-base';
import type { IconType } from '@esurance/legacy-constants';
import { LANGUAGES, ProductTypes, Salutation } from '@esurance/legacy-constants';

import {
  DATE_FORMAT,
  DATE_FORMAT_BACKEND,
  DATE_TIME_FORMAT_BACKEND,
  parseDate as parseDateNew,
} from '../utils/dates';

function printString(elem: string): boolean {
  const newWindow = window.open(
    '',
    'PRINT',
    'scrollbars=1,resizable=1,height=400,width=600,left=0,top=0',
  );

  if (!newWindow) {
    return false;
  }

  newWindow.document.write(
    `<h1>${document.title}</h1>${elem}`,
  );

  newWindow.document.close(); // neexport cessary for IE >= 10
  newWindow.blur();
  window.focus();

  newWindow.print();
  newWindow.close();

  return true;
}

function bool(value: boolean): string {
  return value ? 'Yes' : 'No';
}

function isValidMobilePhoneNumber(phoneNumber: string): boolean {
  const numberParsingResult = parsePhoneNumberFromString(phoneNumber, 'CH');
  const phoneNumberInternational = numberParsingResult
    ? _.toString(numberParsingResult.number)
    : '';

  if (phoneNumberInternational && /\+4171/.test(phoneNumberInternational)) {
    return false;
  }

  return Boolean(
    numberParsingResult
    && numberParsingResult.isValid()
    && /417|380/.test(phoneNumberInternational),
  );
}

function buildDate(v: string): Date {
  // iPhone specific issue
  const date = v.split(/[^0-9]/).map((datePart) => parseInt(datePart, 10));

  // In JS Month starts count from 0;
  date[1] -= 1;
  // @ts-ignore
  // result.setUTCMonth(result.getUTCMonth() - 1); // hotfix due to date format
  return new Date(...date);
}

function makeCancelable<T>(promise: Promise<T>): ICancellablePromise<T> {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (...val: any) => (
        // eslint-disable-next-line prefer-promise-reject-errors,prefer-spread
        hasCanceled ? reject({ isCanceled: true }) : resolve.apply(null, val)
      ),
      (...error: any) => (
        // eslint-disable-next-line prefer-promise-reject-errors,prefer-spread
        hasCanceled ? reject({ isCanceled: true }) : reject.apply(null, error)
      ),
    );
  }) as ICancellablePromise<T>;

  wrappedPromise.cancel = () => {
    hasCanceled = true;
  };

  return wrappedPromise;
}

function firstLetterUpperCase(text: string): string {
  return `${text[0].toUpperCase()}${text.slice(1)}`;
}

const getSalutationIcon = (value: Salutation): IconType => {
  const salutationIcons: { [key: string]: IconType } = {
    [Salutation.Male]: 'male',
    [Salutation.Female]: 'female',
    [Salutation.Company]: 'company',
    [Salutation.Team]: 'team',
  };

  return salutationIcons[value];
};

// TODO: Not DRY. The same implementation is in ui-components package
function isHealthProduct(typeName: ProductTypes): boolean {
  const healthTypes = [
    ProductTypes.Krankentaggeld,
    ProductTypes.Unfall,
    ProductTypes.Krankenkasse,
  ];
  return healthTypes.includes(typeName);
}

function isNotMembership(typeName: ProductTypes): boolean {
  return typeName !== ProductTypes.Haushalt;
}

function mockTr<T>(s: T): ITranslatable<T> {
  return _.toPlainObject(LANGUAGES.map((v) => [v, s]));
}

export const helper = {
  bool,
  buildDate,
  getElementAbsoluteOffset,
  firstLetterUpperCase,
  getSalutationIcon,
  isHealthProduct,
  isNotMembership,
  isValidMobilePhoneNumber,
  makeCancelable,
  mockTr,
  printString,
};

export class FormDataParser {
  constructor(private data: FormData) {
  }

  public extractStr(key: string, defaultValue: string | null = null): string | null {
    const value = this.data.get(key);
    if (_.isNull(value)) {
      return defaultValue;
    }
    return value.toString();
  }

  public extractStrArray(key: string, defaultValue: string[] = []): string[] {
    const value = this.data.getAll(key);

    if (_.isNull(value)) {
      return defaultValue;
    }
    return value.map((elem) => elem.toString());
  }

  public extractNumber(key: string, defaultValue: number | null = null): number | null {
    const value = this.extractStr(key, defaultValue?.toString());
    if (_.isNil(value) || _.trim(value) === '') {
      return defaultValue;
    }
    return _.toNumber(value);
  }

  public extractEnumValue<T extends Pick<string, 'toString'>>(
    key: string,
    defaultValue: T | null = null,
  ): T | string | null {
    const value = this.extractStr(key, defaultValue?.toString());
    if (_.isNil(value) || _.trim(value) === '') {
      return defaultValue;
    }
    return value;
  }

  public extractDate(
    key: string,
    defaultValue = '',
    withoutTime?: boolean,
  ): string {
    const date = this.extractStr(key);
    if (date) {
      return format(
        parseDateNew(date),
        withoutTime ? DATE_FORMAT_BACKEND : DATE_TIME_FORMAT_BACKEND,
      );
    }
    return defaultValue;
  }

  public extractBool(key: string): boolean {
    return !!this.data.get(key);
  }
}

export function move<T>(fromIndex: number, toIndex: number, list: T[]): T[] {
  // eslint-disable-next-line no-param-reassign
  [list[fromIndex], list[toIndex]] = [list[toIndex], list[fromIndex]];

  return list;
}

export function sortableMove<T extends { order: number }>(
  from: T,
  to: T,
  list: T[],
): T[] {
  const fromIndex = _.findIndex(list, (item) => item.order === from.order);
  const toIndex = _.findIndex(list, (item) => item.order === to.order);

  return move(fromIndex, toIndex, list);
}

export function getElementAbsoluteOffset<T extends HTMLElement>(
  input: T,
): { left: number; top: number } {
  let top = 0;
  let left = 0;
  let element = input;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent as T;
  } while (element);

  return {
    left,
    top,
  };
}

// TODO: ESD-7096 : This is temporary solution to unblock the release.
let activatingItem: HTMLElement | null = null;

function scrollIntoViewCenterLegacyImpl(element: HTMLElement): void {
  const ACTIVATING_DELAY = 1000;
  if (activatingItem === element) {
    return;
  }
  activatingItem = element;
  const absoluteElementOffset = getElementAbsoluteOffset(element);
  // To get center of element, need subtract element height from window height and divide by 2
  const centerOfElement = (window.innerHeight - element.clientHeight) / 2;

  window.scroll({
    behavior: 'smooth',
    top: absoluteElementOffset.top - centerOfElement,
  });

  setTimeout(() => {
    if (activatingItem === element) {
      activatingItem = null;
    }
  }, ACTIVATING_DELAY);
}

function scrollIntoViewCenterModernImpl(element: HTMLElement): void {
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const scrollIntoViewCenterImpl = (element: HTMLElement) => {
  const useLegacyScrollIntoCenterView = (
    diContainer.userAgentManager.isInternetExplorer
    || diContainer.userAgentManager.isEdge
    || diContainer.userAgentManager.isSafari
  );

  return useLegacyScrollIntoCenterView
    ? scrollIntoViewCenterLegacyImpl(element)
    : scrollIntoViewCenterModernImpl(element);
};

// TODO: ESD-7096 : This is temporary solution to unblock the release.
const ACTIVATING_DEBOUNCE_TIME = 100;
export const scrollIntoViewCenter = debounce(scrollIntoViewCenterImpl, ACTIVATING_DEBOUNCE_TIME);

export function reorderItem<T>(
  coverages: T[],
  fromIndex: number,
  toIndex: number,
): T[] {
  if (
    fromIndex < 0
    || toIndex < 0
    || fromIndex >= coverages.length
    || toIndex >= coverages.length
  ) {
    return coverages;
  }
  const updatedCoverages = [...coverages];
  const [coverage] = updatedCoverages.splice(fromIndex, 1);
  updatedCoverages.splice(toIndex, 0, coverage);
  return updatedCoverages;
}

/**
 * Returns true if the @index is out of range of the @list
 * @param list
 * @param index
 */
export function isOutOfRange<T>(list: T[], index: number): boolean {
  return index < 0 || index >= list.length;
}

/**
 * Parses string into Date object
 * @param date - string to parse
 * @deprecated import parseDate from shared/utils/dates
 */
export function parseDate(date: string | Date): Date | null {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    if (date.indexOf('.') !== -1) {
      return parseDateNew(date);
    }
    return new Date(date);
  }
  return date;
}

/**
 * Converts Date into printable string
 * @param date - string to parse
 */
export function toDateString(date: string | Date): string {
  const parsedDate = parseDate(date);
  return parsedDate ? format(parsedDate, DATE_FORMAT) : '';
}

export function delay(ms: number): Promise<void> {
  return new Promise(((resolve) => setTimeout(resolve, ms)));
}

export function getLangHeader(): { [key: string]: string } {
  return {
    'Accept-Language': i18next.language.toLowerCase(),
  };
}
