import { _t } from '../service/i18n';

import type { IValidationResult } from './IValidationResult';

export function dateRequiredRule(value: string): IValidationResult {
  return {
    message: _t('survey-non-empty-date-message'),
    passes: !!(value && value.trim().length),
  };
}
