import { action } from './action';
import { activity } from './activity';
import { insurance } from './insurance';
import { organization } from './organization';
import { policy } from './policy';
import { gastro } from './gastro';
import { questionnaire } from './questionnaire';
import { fingerprint } from './fingerprint';

/**
 * @deprecated
 */
export const repository = {
  action,
  activity,
  fingerprint,
  gastro,
  insurance,
  organization,
  policy,
  questionnaire,
};
