import { injectable } from 'inversify';

import { axios } from '@esurance/services';

import type { IAppSelectorRepository, IUserPermissionsData } from '../models/interfaces';

import { parseUserPermissionsDto } from './parsers/parseUserPermissionsDto';

@injectable()
export class AppSelectorRepository implements IAppSelectorRepository {
  public async get(): Promise<IUserPermissionsData> {
    const response = await axios
      .get<IUserPermissionsData>('/api/v2/permissions/available-applications');
    return parseUserPermissionsDto(response.data);
  }
}
