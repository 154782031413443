import { useEffect, useState } from 'react';

export function useScrolledTo(minScroll: number): boolean {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handler() {
      const scrollTop: number = document.scrollingElement?.scrollTop || 0;

      if (scrollTop >= minScroll && !scrolled) {
        setScrolled(true);
      } else if (scrollTop < minScroll && scrolled) {
        setScrolled(false);
      }
    }

    document.addEventListener('scroll', handler);
    return () => document.removeEventListener('scroll', handler);
  }, [scrolled]);

  return scrolled;
}
