import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';

import { useInjection } from '@esurance/ioc-base';
import {
  LanguageSelect,
  DropdownArrowIcon,
} from '@esurance/ui-components';
import { ConstantDependency } from '@esurance/constants';
import { OperatorSelect } from '@esurance/domain-components';

import { HeaderLogo } from './HeaderLogo';

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  logo: `${PREFIX}-logo`,
  actions: `${PREFIX}-actions`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'white',
    '& .list, & label': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body1.fontSize,
    },
    height: 58,
    boxShadow: '2px 6px 10px 0 rgb(204 213 221 / 30%)!important',
    padding: '0 30px',
    '@media (max-width:1200px)': {
      padding: '0 70px',
    },
    '@media (max-width:800px)': {
      padding: '0 15px',
      height: 46,
    },
  },

  [`& .${classes.toolbar}`]: {
    justifyContent: 'space-between',
    minHeight: '100%',
    padding: 0,
    maxWidth: 1280,
    width: '100%',
    margin: 'auto',
  },

  [`& .${classes.logo}`]: {
    height: '22px',
    width: 'auto',
    display: 'inline-flex',
    marginTop: '-5px',
    '@media (max-width:800px)': {
      marginTop: '-3px',
    },
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: 30,
    },
    '& .dropdown-trigger': {
      display: 'flex',
      fontWeight: 'bold',
      fontFamily: "'Source Sans Pro', 'SourceSansPro', sans-serif",
      alignItems: 'center',
      '& .dropdown-arrow-icon': {
        marginLeft: 6,
      },
    },
  },
}));

export function Header(): JSX.Element {
  const logoLink = useInjection<string>(ConstantDependency.EsuranceUrl);

  return (
    <StyledAppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <HeaderLogo
          className={classes.logo}
          link={logoLink}
          isAbsoluteLinkUrl
        />
        <div className={classes.actions}>
          <LanguageSelect icon={<DropdownArrowIcon />} />
          <OperatorSelect />
        </div>
      </Toolbar>
    </StyledAppBar>
  );
}
