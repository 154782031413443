import React from 'react';
import { styled } from '@mui/material/styles';
import type { ButtonProps } from '@mui/material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PREFIX = 'SplitButton';

const classes = {
  popper: `${PREFIX}-popper`,
};

const Root = styled('div')({
  [`& .${classes.popper}`]: {
    zIndex: 31,
  },
});

interface ISplitButtonProps {
  options: IOption[];
  onClick: (value: string) => void;
  selected?: number;
  disabled?: number;
  className?: string;
  variant?: ButtonProps['variant'];
  dataTestPrefix?: string;
}

export interface IOption {
  value: string;
  title: string;
}

export function SplitButton(props: ISplitButtonProps): JSX.Element {
  const {
    options,
    selected,
    disabled,
    className,
    variant,
    dataTestPrefix = 'split-button',
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(selected || 0);

  const handleClick = (index: number): void => {
    const { onClick } = props;
    onClick(options[index].value);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ): void => {
    setSelectedIndex(index);
    setOpen(false);
    handleClick(index);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Root className={className}>
      <ButtonGroup variant={variant || 'outlined'} color="primary" ref={anchorRef} aria-label="split button">
        <Button
          onClick={() => handleClick(selectedIndex)}
          data-test={`${dataTestPrefix}.main-button`}
        >
          {options[selectedIndex]?.title}
        </Button>

        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          data-test={`${dataTestPrefix}.drowdown-button`}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role="listbox"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === selectedIndex}
                      disabled={index === disabled}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  );
}
