import type { ISessionTracker } from './interfaces';

export class SessionTracker implements ISessionTracker {
  private currentSessionId = 0;

  public createNewSession(): number {
    this.currentSessionId += 1;
    return this.currentSessionId;
  }

  public isCurrentSession(sessionId: number): boolean {
    return this.currentSessionId === sessionId;
  }
}
