import type { Container } from 'inversify';

export interface IDependency<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
}

function unbind(inversifyContainer: Container, identifier: symbol): void {
  if (inversifyContainer.isBound(identifier)) {
    inversifyContainer.unbind(identifier);
  }
}

export function replaceDependencyWithType<T>(
  inversifyContainer: Container,
  identifier: symbol,
  NewService: IDependency<T>,
): void {
  unbind(inversifyContainer, identifier);
  inversifyContainer.bind(identifier).to(NewService);
}

export function replaceDependencyWithConst<T>(
  inversifyContainer: Container,
  identifier: symbol,
  value: T,
): void {
  unbind(inversifyContainer, identifier);
  inversifyContainer.bind(identifier).toConstantValue(value);
}
