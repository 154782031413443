import { action, makeObservable, observable } from 'mobx';
import _ from 'lodash';

import type { OptionType } from '@esurance/ui-components';
import { configStore } from '@esurance/shared/application/configStore';

import type { IAutocompleteModel } from './interfaces';

export abstract class AbstractAutocomplete implements IAutocompleteModel {
  public constructor(useDebounce: boolean) {
    if (useDebounce) {
      this.scheduleUpdateOptionsList = _.debounce(
        this.scheduleUpdateOptionsList,
        configStore.debounce.inputChange,
      );
    }

    makeObservable<AbstractAutocomplete, 'updateOptionsList' | 'scheduleUpdateOptionsList'>(
      this,
      {
        isLoading: observable,
        options: observable,
        value: observable,

        onChange: action,
        onInputChange: action,
        updateOptionsList: action,
        scheduleUpdateOptionsList: action,
      },
    );
  }

  public isLoading = false;

  public options: OptionType[] = [];

  public value: OptionType = {
    value: '',
    label: '',
  };

  public onChange = (newValue: OptionType | null): void => {
    if (newValue) {
      this.value = newValue;
    } else {
      this.value = {
        value: '',
        label: '',
      };
      this.options = [];
    }
  };

  public onInputChange = (newValue: string): Promise<void> => {
    if (!newValue?.trim()) {
      this.onChange(null);
      return Promise.resolve();
    }
    this.onChange({
      label: newValue,
      value: newValue,
    });
    return this.updateOptionsList();
  };

  protected updateOptionsList = (): Promise<void> => {
    this.isLoading = true;
    return this.scheduleUpdateOptionsList();
  };

  protected scheduleUpdateOptionsList = async (): Promise<void> => {
    this.isLoading = true;
    const currentValue = this.value.value;
    try {
      const newOptions = await this.fetchNewOptionsList();
      if (currentValue === this.value.value) {
        this.options = newOptions;
      }
    } catch (err) {
      throw err;
    } finally {
      if (currentValue === this.value.value) {
        this.isLoading = false;
      }
    }
  };

  protected abstract fetchNewOptionsList(): Promise<OptionType[]>;
}
