import type { IFormField } from './IFormField';

export const FORM_MARKER = Symbol('FORM_MARKER');

export interface IForm {
  id?: IFormField<string> | IFormField<number>;
  isInvalid: boolean;
  isTouched: boolean;
  isChanged: boolean;
  reset: () => void;
  save: (onSuccess?: () => void, onError?: () => void) => Promise<void>;
  getFieldByName(name: string): IFormField<string> | null;
}
