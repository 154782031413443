import React from 'react';

import { ScrollToTop } from './ScrollToTop';

export function withScrollToTop<T>(
  WrappedComponent: React.ComponentType<T>,
): React.FunctionComponent<T> {
  return function scrollToTopWrapper(props: T): JSX.Element {
    return (
      <ScrollToTop>
        <WrappedComponent {...props} />
      </ScrollToTop>
    );
  };
}
