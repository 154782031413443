import './LanguageSelect.styl';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '@esurance/legacy-constants';

import { Dropdown, DropdownArrowType } from '../Dropdown/Dropdown';

import type { ILanguage } from './toLanguageOptions';
import { toLanguageOptions } from './toLanguageOptions';
import { InlineLayout } from './components/InlineLayout';

export interface ILanguageSelectProps {
  languages?: ILanguage[];
  onChange?: (language?: string) => void;
  inlineStyle?: boolean;
  icon?: JSX.Element;
}

const defaultLanguages = toLanguageOptions(LANGUAGES);

export function LanguageSelect(props: ILanguageSelectProps): JSX.Element {
  const {
    languages, onChange, inlineStyle, icon,
  } = props;
  const { i18n } = useTranslation();

  function changeLanguage(language: string): void {
    i18n.changeLanguage(language);
    if (onChange) {
      onChange(language);
    }
  }

  if (inlineStyle) {
    return (
      <InlineLayout
        // eslint-disable-next-line react/jsx-no-bind
        onLanguageSelect={changeLanguage}
        list={languages || defaultLanguages}
        selected={i18n.language}
      />
    );
  }

  return (
    <Dropdown
      // eslint-disable-next-line react/jsx-no-bind
      onClick={changeLanguage}
      list={languages || defaultLanguages}
      selected={i18n.language}
      className="language-select"
      arrowType={DropdownArrowType.Chevron}
      triggerIcon={icon}
    />
  );
}
