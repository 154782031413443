import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import type { IEventManager } from '@esurance/services';
import { DependencyType, EventName } from '@esurance/services';
import { useInjection } from '@esurance/ioc-base';

const PREFIX = 'ConfirmationDialogView';

const classes = {
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.button}`]: {
    padding: '3px 20px',
    minWidth: '90px',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

type maxWidthType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

interface IProps {
  title?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  footer?: ReactNode;
  maxWidth?: maxWidthType;
}

export function ConfirmationDialogView(props: IProps): JSX.Element | null {
  const eventManager = useInjection<IEventManager>(DependencyType.EventManager);
  const [translate] = useTranslation();

  const handleConfirm = () => {
    eventManager.emit(EventName.CloseModal);
    if (!props.onSubmit) return;
    props.onSubmit();
  };

  const handleClose = () => {
    eventManager.emit(EventName.CloseModal);
    if (!props.onCancel) return;
    props.onCancel();
  };

  const {
    children, title, maxWidth, footer,
  } = props;

  return (
    <StyledDialog
      open
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth={maxWidth || 'sm'}
    >
      <DialogTitle id="dialog-title">
        <Typography variant="h4">{title ? translate(title) : ''}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        {children }
      </DialogContent>
      <DialogActions>
        {footer || (
          <>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="outlined"
              size="medium"
            >
              {translate('cancel')}
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disableElevation
            >
              {translate('confirm')}
            </Button>
          </>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
