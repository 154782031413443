import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { moneyFormater } from '@esurance/shared/service';

const Wrapper = styled('div')(({ theme }) => ({
  '&': {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: theme.palette.text.tertiary,
  },
}));

interface IProps {
  price: number;
}

export function FutureTariffPrice({ price }: IProps) {
  const [translate] = useTranslation();
  const formattedPrice = moneyFormater(price);
  const futurePrice = `${translate('basket_future-tariff-price-title')}: ${formattedPrice}`;

  return (
    <Wrapper className="future-tariff">
      <InfoIcon fontSize="small" color="primary" />
      {futurePrice}
    </Wrapper>
  );
}
