import type { TOptions } from 'i18next';
import i18next from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import type { ITranslatable, ITranslatedKey } from '@esurance/entities';
import { LANGUAGES } from '@esurance/legacy-constants';

/** eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Just a shortcut to get translations
 * @param key
 * @param options
 * @private
 * @deprecated
 */
// eslint-disable-next-line
export const _t = (
  key: string,
  options?: TOptions,
): string => {
  if (window.location.search === '?lng=empty') {
    return '';
  }
  if (window.location.search === '?lng=key') {
    return key;
  }
  return i18next.t(key, options);
};

/**
 * Just a shortcut to get translations
 */
export function _tf(
  field: string,
  language?: string,
): string;
export function _tf(
  field: string | Partial<ITranslatable<string>>,
  language?: string,
): string;
export function _tf<T = string>(
  field: Partial<ITranslatable<T>>,
  language?: string,
): T | null;
// eslint-disable-next-line no-underscore-dangle
export function _tf<T = string>(
  field: Partial<ITranslatable<T>> | string,
  language?: string,
): T | string | null {
  if (_.isString(field)) {
    return _t(field);
  }
  if (field && typeof field === 'object') {
    return field[language || i18next.language] || null;
  }
  return JSON.stringify(field);
}

export function useTF<T = string>() {
  const { i18n: { language } } = useTranslation();

  return (field: Partial<ITranslatable<T>> | string) => {
    if (_.isString(field)) {
      return _tf(field, language);
    }
    if (field && typeof field === 'object') {
      return _tf(field, language);
    }
    return _tf(field, language);
  };
}

export function getTranslatable<T = string>(key: string): ITranslatable<T> {
  const translatable: any = {};
  for (const lng of LANGUAGES) {
    translatable[lng] = i18next.t(key, { lng });
  }
  return translatable;
}

export function getFakeTranslatable<T = string>(val: T): ITranslatable<T> {
  const translatable: any = {};
  for (const lng of LANGUAGES) {
    translatable[lng] = val;
  }
  return translatable;
}

export function createTranslatedKey(defaultData: Partial<ITranslatedKey> = {}): ITranslatedKey {
  return {
    translationKey: defaultData.translationKey || '',
    translation: defaultData.translation || getTranslatable(''),
  };
}

export function createNewTranslatedKeyMock(translation: string): ITranslatedKey {
  return createTranslatedKey({
    translation: getTranslatable(translation),
  });
}

export function toTranslatable(keyOrTranslatable: string | ITranslatable): ITranslatable {
  if (typeof keyOrTranslatable === 'string') {
    return getTranslatable(keyOrTranslatable);
  }
  return keyOrTranslatable;
}

const calculationT = tWithPrefix('calculation');
export function translate(value: string): string {
  return calculationT(value, {
    defaultValue: '',
  });
}

// eslint-disable-next-line
export function _te(value: string) {
  const tran = _t; // To prevent scan new values
  return tran(`error:${value}`);
}

const ALLOWED_TAGS = ['a'];

export function tWithPrefix(prefix: string) {
  return (key: string, options?: TOptions) => {
    if (i18next.exists(`${prefix}.${key}`)) {
      const translation = i18next.t(`${prefix}.${key}`, options);
      return sanitize(translation, { ALLOWED_TAGS });
    }
    return i18next.t(key, key, options);
  };
}

export async function translationsReload(allLanguages?: boolean): Promise<void> {
  await i18next.reloadResources(allLanguages ? [...LANGUAGES] : [i18next.language]);
}
