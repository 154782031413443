import { startOfToday, subDays } from 'date-fns';

import { parseDate } from '@esurance/shared/utils/dates';

import { _t } from '../service/i18n';

import type { IValidationResult } from './IValidationResult';

export function fourteenDaysAgoRule(value: string): IValidationResult {
  const date = parseDate(value);

  return {
    message: _t('survey-invalid-date-message'),
    passes: date >= subDays(startOfToday(), 14),
  };
}
