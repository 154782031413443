import type { Container } from 'inversify';

import type {
  IAccountRepository,
  IAxiosManager,
  IConfigRepository,
  IConstantRepository,
  IEventManager,
  IHistoryManager,
  ILocalStorageManagerFactory,
  INotificationManager,
  ISentryManager,
  ISocketManager,
  ISurveyRepository,
  IUserAgentManager,
  IRecaptchaManager,
  IGoogleTagManager,
} from '@esurance/services';
import {
  AccountRepository,
  AxiosManager,
  ConfigRepository,
  ConstantRepository,
  EventManager,
  HistoryManager,
  LocalStorageManagerFactory,
  NotificationManager,
  SentryManager,
  SocketManager,
  SurveyRepository,
  TranslationManager,
  UserAgentManager,
  RecaptchaManager,
  GoogleTagManager,
  URLTrackingParametersManager,
  FeatureManager,
} from '@esurance/services';
import { configureContainer as configureAuthContainer } from '@esurance/auth';

// eslint-disable-next-line no-restricted-imports
import {
  DependencyType,
} from '../../../services/src/lib/DependencyType';

import { createStorageMock } from './__mocks__/createStorageMock';

/* globals window, navigator */

export function configureContainer(container: Container): void {
  configureAuthContainer(container);

  container
    .bind<IEventManager>(DependencyType.EventManager)
    .to(EventManager);

  container
    .bind<INotificationManager>(DependencyType.NotificationManager)
    .to(NotificationManager);

  container
    .bind<IConstantRepository>(DependencyType.ConstantRepository)
    .to(ConstantRepository);

  container
    .bind<ISocketManager>(DependencyType.SocketManager)
    .to(SocketManager);

  container
    .bind<IConfigRepository>(DependencyType.ConfigRepository)
    .to(ConfigRepository);

  container
    .bind<IAccountRepository>(DependencyType.AccountRepository)
    .to(AccountRepository);

  container
    .bind<ISurveyRepository>(DependencyType.SurveyRepository)
    .to(SurveyRepository);

  container
    .bind<ISentryManager>(DependencyType.SentryManager)
    .to(SentryManager);

  container
    .bind<TranslationManager>(DependencyType.TranslationManager)
    .to(TranslationManager);

  container
    .bind<IAxiosManager>(DependencyType.AxiosManager)
    .to(AxiosManager);

  container
    .bind<ILocalStorageManagerFactory>(DependencyType.LocalStorageManagerFactory)
    .to(LocalStorageManagerFactory);

  container
    .bind<IUserAgentManager>(DependencyType.UserAgentManager)
    .to(UserAgentManager);

  container
    .bind<IHistoryManager>(DependencyType.HistoryManager)
    .to(HistoryManager);

  container
    .bind<IRecaptchaManager>(DependencyType.RecaptchaManager)
    .to(RecaptchaManager);

  container
    .bind<IGoogleTagManager>(DependencyType.GoogleTagManager)
    .to(GoogleTagManager);

  container
    .bind(DependencyType.URLTrackingParametersManager)
    .to(URLTrackingParametersManager);

  container
    .bind(DependencyType.FeatureManager)
    .to(FeatureManager);

  if (navigator.cookieEnabled) {
    container
      .bind<Storage>(DependencyType.LocalStorage)
      .toConstantValue(window.localStorage);
  } else {
    container
      .bind<Storage>(DependencyType.LocalStorage)
      .toConstantValue(createStorageMock());
  }
}
