import React from 'react';
import { observer } from 'mobx-react';
import {
  Autocomplete,
  Box,
  TextField,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import type { OptionType } from '@esurance/ui-components';
import type { ISizeInput } from '@esurance/domain-components';

const filter = createFilterOptions<IAutocompleteOption>();

const StyledBox = styled(Box)(() => ({
  '& .MuiOutlinedInput-root': {
    height: 'max-content',
  },
}));

interface IAutocompleteOption extends OptionType {
  createNew?: boolean;
}

interface IAutocompleteProps {
  size?: ISizeInput;
  placeholder?: string;
  label?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  showError?: boolean;
  errorMessage?: string;
  value?: OptionType;
  name?: string;
  options: OptionType[];

  onChange(value: OptionType | null): void;

  onInputChange(newValue: string): void;

  onTouch?: () => void;
  width?: string;
}

// TODO: Use this autocomplete view during migration to material
// TODO: Cover this view with tests
export const AutocompleteView = observer((props: IAutocompleteProps): JSX.Element => {
  const {
    placeholder,
    label,
    isLoading,
    options,
    value,
    onChange,
    onInputChange,
    onTouch,
    showError,
    errorMessage,
    width,
    size,
    name,
  } = props;

  const [translate] = useTranslation();

  return (
    <StyledBox
      width={width || '100%'}
      className="form-autocomplete"
      data-test={`${name || label}-container`}
    >
      <Autocomplete
        size={size}
        value={value}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'object') {
            return option.label;
          }

          return option || '';
        }}
        isOptionEqualToValue={(option, currentValue) => option.value === currentValue.value}
        loading={isLoading}
        loadingText={translate('search_prompt_text')}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            label={label}
            error={showError}
            placeholder={placeholder}
            helperText={showError && translate(errorMessage || '')}
          />
        )}
        onInputChange={(e, newValue) => {
          if (e === null) return;
          onInputChange(newValue);
        }}
        onChange={(e, newValue) => {
          const autocompleteOption = newValue as IAutocompleteOption;
          if (autocompleteOption?.createNew) {
            onChange({
              label: autocompleteOption.value,
              value: autocompleteOption.value,
            });
          } else {
            onChange(newValue as OptionType | null);
          }
        }}
        onMouseUp={(e) => e.stopPropagation()}
        filterOptions={(optionsToFilter: OptionType[], params) => {
          const filtered = filter(optionsToFilter, params);
          const inputValue = value?.label;

          const hasExecMatch = filtered.some((o) => o.label === inputValue);

          // Suggest the creation of a new value
          if (!isLoading && inputValue && inputValue !== '' && !hasExecMatch) {
            filtered.unshift({
              label: `${translate('add-value-prompt')} "${inputValue}"`,
              value: inputValue,
              createNew: true,
            });
          }

          return filtered;
        }}
        onBlur={onTouch}
        sx={
          showError
            ? {
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                minHeight: '100%',
                bottom: 'calc( -1 * 100%)',
              },
            }
            : {
              marginBottom: 0,
              '& .MuiFormHelperText-root': {
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
              },
            }
        }
        freeSolo
      />
    </StyledBox>
  );
});
