import { inject, injectable } from 'inversify';
import _ from 'lodash';
import type { AxiosError } from 'axios';
import type { TOptions } from 'i18next';
import i18next from 'i18next';

import { AlertTypes } from '@esurance/legacy-constants';

import { DependencyType } from '../DependencyType';
import { IConfigRepository } from '../repositories/interfaces';

import { axios as axiosBase, setErrorHandler } from './axios-wrapper';
import { getHeaders } from './helpers';
import type {
  AxiosConfigureCallback,
  IAxiosManager,
} from './interfaces';
import {
  EventName,
  IEventManager,
  INotificationManager,
  ITranslationManager,
  IHistoryManager,
} from './interfaces';

@injectable()
export class AxiosManager implements IAxiosManager {
  private axios = axiosBase;

  public constructor(
    @inject(DependencyType.TranslationManager)
    private readonly translationManager: ITranslationManager,
    @inject(DependencyType.ConfigRepository)
    private readonly configRepository: IConfigRepository,
    // @inject(DependencyType.AuthManager)
    // private readonly authManager: IAuthManager,
    @inject(DependencyType.NotificationManager)
    private readonly notificationManager: INotificationManager,
    @inject(DependencyType.EventManager)
    private readonly eventManager: IEventManager,
    @inject(DependencyType.HistoryManager)
    private readonly historyManager: IHistoryManager,
    // @inject(DependencyType.KeycloakManager)
    // private readonly keycloakManager: IKeycloakManager,
  ) {
    setErrorHandler(this.onRequestRejected);
    this.translationManager.onLanguageChanged(() => {
      this.updateHeaders();
    });

    // axiosBase.interceptors.request.use((config: AxiosRequestConfig) => ({
    //   ...config,
    //   headers: Object.assign(config.headers, {
    //     Authorization: `Bearer ${this.keycloakManager.token}`,
    //   }),
    // }));
  }

  public init(): void {
    this.updateHeaders();
  }

  public handleError(error: AxiosError): void {
    if (error?.response?.status === 404) {
      this.historyManager.navigateTo('/not-found', { replace: true });
    } else {
      this.raiseErrorNotification(error);
    }
  }

  public raiseErrorNotification(error: AxiosError<any>): void {
    const { response } = error;
    if (!response) {
      return;
    }
    const { data } = response;
    const messages = Object.values<string>(data.messages ? data.messages : data.errors);
    messages.forEach((message: string) => {
      this.notificationManager.raise({
        closeable: true,
        content: message,
        onTop: true,
        type: AlertTypes.Error,
      });
    });
  }

  private updateHeaders(): void {
    this.axios.defaults.headers.common = getHeaders();
  }

  public configure(axiosCallback: AxiosConfigureCallback): void {
    axiosCallback(this.axios);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onRequestRejected = (reject: any): void => {
    const { status, config, statusText } = reject.response;
    if (status === 401) {
      try {
        // this.authManager.logout();
        this.eventManager.emit(EventName.UnauthorizedError);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      this.historyManager.navigateTo(
        '/logout',
        {
          replace: true,
          state: { message: translate('logout_notification_expired-token') },
        },
      );

      throw reject;
    }
    const errorsNotToHandle = [422, 413, 403];
    if (errorsNotToHandle.includes(status)) {
      return;
    }
    let errorMessage = reject.message || statusText;

    if (
      !_.isUndefined(config.statusMessage)
        && !_.isUndefined(config.statusMessage[status])
    ) {
      errorMessage = config.statusMessage[status];
    }

    this.notificationManager.raise({
      closeable: true,
      content: errorMessage,
      type: AlertTypes.Error,
    });
  };
}

// TODO: this function was copied from the shared module to break circle dependency
//  original name: _t
export function translate(key: string, options?: TOptions): string {
  if (window.location.search === '?lng=empty') {
    return '';
  }
  if (window.location.search === '?lng=key') {
    return key;
  }
  return i18next.t(key, options);
}
