import type { ComponentsOverrides } from '@mui/material/styles/overrides';
import type { Palette } from '@mui/material/styles';

type SwitchStyles = ComponentsOverrides['MuiSwitch'];

export function createMuiSwitchStyles(
  palette: Palette,
  overrides?: Partial<SwitchStyles>,
): Partial<SwitchStyles> {
  return {
    root: {
      width: '70px',
      height: 'auto',
      '.Mui-checked+.MuiSwitch-track': {
        opacity: '1!important',
      },
      paddingTop: '7px',
      marginTop: '-7px',
      paddingBottom: '7px',
      marginBottom: '-7px',
    },
    switchBase: {
      top: '1px',
      left: '6px',
    },
    thumb: {
      background: '#FFF',
    },
    track: {
      height: '26px',
      borderRadius: '26px',
      background: palette.border.regular,
      border: `1px solid ${palette.divider}`,
    },
    ...overrides,
  };
}
