import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type { AnyQuestionnaireQuestion, IQuestionnaire, IQuestionnaireReply } from '@esurance/entities';

import { AbstractCRUD } from './abstract-crud';

class Questionnaire extends AbstractCRUD<IQuestionnaire> {
  protected root = 'questionnaire';

  public getList(
    limit?: number,
    offset?: number,
  ): Promise<IQuestionnaire[]> {
    return axios
      .get<IQuestionnaire[]>(
        `${diContainer.backofficeApiRoot}/${this.root}`,
        {
          params: {
            limit,
            offset,
          },
        },
      )
      .then((response) => response.data);
  }
}

class Question extends AbstractCRUD<AnyQuestionnaireQuestion> {
  protected root = 'question';

  public getList(
    limit?: number,
    offset?: number,
  ): Promise<AnyQuestionnaireQuestion[]> {
    return axios
      .get<AnyQuestionnaireQuestion[]>(
        `${diContainer.backofficeApiRoot}/${this.root}`,
        {
          params: {
            limit,
            offset,
          },
        },
      )
      .then((response) => response.data);
  }
}

class Reply extends AbstractCRUD<IQuestionnaireReply> {
  protected root = 'reply';

  public getList(
    limit?: number,
    offset?: number,
  ): Promise<IQuestionnaireReply[]> {
    return axios
      .get<IQuestionnaireReply[]>(
        `${diContainer.backofficeApiRoot}/${this.root}`,
        {
          params: {
            limit,
            offset,
          },
        },
      )
      .then((response) => response.data);
  }
}

export const questionnaire = {
  question: new Question(),
  questionnaire: new Questionnaire(),
  reply: new Reply(),
};
