import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  styled,
  Typography,
  Alert,
} from '@mui/material';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { TermsDescription } from './TermsDescription';
import { TermsTitle } from './TermsTitle';

interface IProps extends WithTranslation {
  onCheckboxChange: (flag: boolean) => void;
  agreeTerms: boolean;
  title: string;
  description?: string;
  error?: string;
}

const TermsAndConditionsWrapper = styled('div')((props) => ({
  '& a': {
    textDecoration: 'underline',
  },
  '& .terms-description': {
    marginLeft: '25px',
    marginBottom: props.theme.spacing(1),
  },
}));

class TermsAndConditionsImpl extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      agreeTerms,
      title,
      description,
      t: translate,
      error,
    } = this.props;

    const hasDescription = Boolean(description && translate(description, ''));
    const hasError = Boolean(error && translate(error, ''));

    return (
      <TermsAndConditionsWrapper className="terms">
        <FormControl component="fieldset" error={hasError}>
          <FormControlLabel
            control={(
              <Checkbox
                name="terms-checkbox"
                value=""
                onChange={this.handleToggleCheckbox}
                checked={agreeTerms}
                required
              />
            )}
            label={(<TermsTitle title={title} />)}
          />
          {description && hasDescription && (
            <TermsDescription description={description} className="terms-description" />)}
          {error && hasError && (
            <Alert severity="error">
              <Typography>{translate(error)}</Typography>
            </Alert>
          )}
        </FormControl>
      </TermsAndConditionsWrapper>
    );
  }

  private handleToggleCheckbox = (): void => {
    const { agreeTerms, onCheckboxChange } = this.props;

    onCheckboxChange(!agreeTerms);
  };
}

export const TermsAndConditions = withTranslation()(TermsAndConditionsImpl);
