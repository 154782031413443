import type {
  ISessionContactData,
  ISessionIdentityData,
  SessionFlowData,
} from '../../repositories/interfaces';

export enum AuthEvent {
  Login = 'Login',
  SessionDataChanged = 'SessionDataChanged',
  Logout = 'Logout',
  Error = 'Error',
}

export interface IAuthEventHandlerMap {
  SessionDataChanged: () => void;
  Login: () => void;
  Logout: () => void;
  Error: (error: Error) => void;
}

export interface IAuthManager {
  isAuthenticated: boolean;
  isRegistrationCompleted: boolean;

  init(): Promise<void> | void;
  readonly isInitialized: boolean;

  login(redirectUri?: string): Promise<void>;
  logout(): Promise<void>;
  refreshSessionData(): Promise<void>;
  impersonate(userIdentityId: string): Promise<void>;
  redirectToChangePasswordPage(): void;

  userId: number | never;

  // 1 usage in backoffice
  isPartnerContact(): boolean;
  // 1 usage in survey ctx <- ConsultantsStepIsVisibleCondition
  isUpsellingSession(): boolean;
  // 1 usage in survey ctx <- SignupStepIsVisibleCondition
  readonly isNewCustomerUpsellingSession: boolean;

  on<T extends keyof IAuthEventHandlerMap>(event: T, handler: IAuthEventHandlerMap[T]): void;
  off<T extends keyof IAuthEventHandlerMap>(event: T, handler: IAuthEventHandlerMap[T]): void;

  token?: string;
  identity?: ISessionIdentityData;
  contact?: ISessionContactData;
  flow?: SessionFlowData;
}
