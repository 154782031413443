import React from 'react';
import _ from 'lodash';

import type { ISurveyQuestionPeopleOption } from '@esurance/entities';

import { RadioList } from '../component/radio-list';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionPeopleOption;
  names: string[];
}

interface IState {
  selected?: string;
}

class List extends React.Component<IProps> {
  public state: IState = {
    selected: undefined,
  };

  public render(): JSX.Element | null {
    const { item, names: peopleNames } = this.props;
    const { selected } = this.state;
    const names = peopleNames.filter((name) => !_.isEmpty(name));

    if (!names.length) {
      return null;
    }

    const options = names.map((name) => ({
      title: name,
      value: name,
    }));

    return (
      <BaseQuestion title={item.title}>
        <RadioList
          items={options}
          selected={selected}
          onChange={this.handleSelect}
        />
      </BaseQuestion>
    );
  }

  private handleSelect = (value: string): void => {
    const { onChange, item } = this.props;
    this.setState({
      selected: value,
    });

    onChange(item, {
      answer: value,
    });
  };
}

export default List;
