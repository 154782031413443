import _ from 'lodash';

import { _t } from '../service/i18n';

import type { IValidationResult } from './IValidationResult';

export function requiredRule(value: string): IValidationResult {
  return {
    message: _t('survey-invalid-date-message'),
    passes: !_.isEmpty(value),
  };
}
