import './list.styl';

import _ from 'lodash';
import React from 'react';
import ChecklistIcon from '@mui/icons-material/FactCheckOutlined';
import { Typography } from '@mui/material';

import type { IAbstractActivity } from '@esurance/entities';

import { _t } from '../../../service';
import { Divider } from '../../element/divider';

interface IProps {
  list: IAbstractActivity[];
  groupName?: string;
  renderChild: (
    item: IAbstractActivity,
    selected: boolean,
    onSelect: () => void,
  ) => JSX.Element;
}

interface IState {
  selectedId: number;
}

export class ActivityList extends React.Component<IProps, IState> {
  public state: IState = {
    selectedId: -1,
  };

  public render(): JSX.Element {
    const { list, renderChild, groupName } = this.props;
    const { selectedId } = this.state;
    const empty = _.isEmpty(list);
    const renderedList = list.map((item) => {
      const selected = item.id === selectedId;
      return renderChild(item, selected, () => this.handleActivitySelect(item));
    });

    return (
      <>
        {groupName ? (
          <Divider title={groupName} wideInPanel />
        ) : null}

        <div className="activity-list">
          {empty ? this.renderEmptyMessage() : renderedList}
        </div>
      </>
    );
  }

  private renderEmptyMessage(): JSX.Element {
    return (
      <div className="message-no-activity">
        <Typography variant="h1">
          <ChecklistIcon fontSize="inherit" />
        </Typography>
        <p>{_t('activity_no-more-tasks')}</p>
      </div>
    );
  }

  private handleActivitySelect = (item: IAbstractActivity): void => {
    const { selectedId } = this.state;
    this.setState({
      selectedId: item.id === selectedId ? -1 : item.id,
    });
  };
}
