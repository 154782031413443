import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type FormControlStyles = ComponentsOverrides['MuiFormControl'];

export function createMuiFormControlStyles(
  overrides?: FormControlStyles,
): FormControlStyles {
  return {
    root: {
      width: '100%',
      fontSize: '16px',
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
