import type { SvgIconProps } from '@mui/material';

export function createMaterialSvgIconTheme(
  overrides?: Partial<SvgIconProps>,
): Partial<SvgIconProps> {
  return {
    color: 'secondary',
    ...overrides,
  };
}
