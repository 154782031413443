import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type InputLabelStyles = ComponentsOverrides['MuiInputLabel'];

export function createMuiInputLabelStyles(
  overrides: InputLabelStyles = {},
): InputLabelStyles {
  return {
    root: {
      color: '#979797',
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
