class TabIndex {
  // private hidden = 0;
  private hidden = -1;

  set value(value) {
    // console.debug(value);
    this.hidden = value;
  }

  get value(): number {
    return this.hidden;
  }
}

export const SurveyTabIndex = new TabIndex();
