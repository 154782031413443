import type { IForm, IValidator } from '../interfaces';

export class RequiredIfAnotherIsEmpty<T = string> implements IValidator<T> {
  constructor(
    private anotherFieldName: string,
    private context: IForm,
    public errorMessage: string = 'required-invalid-message',
  ) {
    if (!anotherFieldName) {
      throw new Error('Field is required as argument of the constructor');
    }
  }

  public validate(value: T): boolean {
    const hasValue = !!value;

    const anotherField = this.context.getFieldByName(this.anotherFieldName);
    if (anotherField === null) {
      throw new Error('Another Field is not found in the form');
    }
    const hasAnotherFieldValue = !!anotherField.value && !!anotherField.value.trim();
    return hasAnotherFieldValue ? true : hasValue;
  }
}
