import type { FocusEvent } from 'react';
import React from 'react';
import _ from 'lodash';

import type { ISurveyQuestionPeopleText } from '@esurance/entities';

import { SurveyTabIndex } from '../tab-index';
import { Input } from '../../form/input';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionPeopleText;
  names: string[];
}

export default class QuestionText extends React.Component<IProps> {
  private values: any = {};

  public render(): JSX.Element | null {
    const { item, names, onChange } = this.props;

    const filteredNames = names.filter((name) => !_.isEmpty(name));
    const handleBlur = (event: FocusEvent<HTMLInputElement>, i: number): void => {
      this.values[i] = event.currentTarget.value;

      onChange(item, {
        values: _.values(this.values),
      });
    };

    if (!filteredNames.length) {
      return null;
    }

    return (
      <BaseQuestion title={item.title}>
        {_.times(filteredNames.length, (i) => {
          SurveyTabIndex.value += 1;
          return (
            <Input
              key={i}
              type="text"
              label={filteredNames[i]}
              onBlur={(event) => handleBlur(event, i)}
              tabIndex={SurveyTabIndex.value}
            />
          );
        })}
      </BaseQuestion>
    );
  }
}
