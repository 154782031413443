import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  TextField,
} from '@mui/material';
import cn from 'classnames';

import type { IFormField } from '@esurance/domain-components';

const PREFIX = 'FormTextField';

const classes = {
  valid: `${PREFIX}-textFieldValid`,
  invalid: `${PREFIX}-textFieldInvalid`,
  required: `${PREFIX}-required`,
  disabled: `${PREFIX}-disabled`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.valid}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.invalid}`]: {
    height: 'max-content',
    marginBottom: 0,
    '& .MuiFormHelperText-root': {
      paddingBottom: 0,
      marginTop: 0,
    },
  },

  [`& .${classes.required}`]: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.hint,
  },
  [`& .${classes.disabled}`]: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  '& .MuiOutlinedInput-root': {
    height: 'max-content',
  },
}));

const Field = styled(TextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});

export enum ISizeInput {
  Small = 'small',
  Medium = 'medium',
}

interface IFormTextFieldProps {
  type?: 'string' | 'number'
  model: IFormField<string>
  width?: string;
  multiline?: boolean;
  placeholder?: string;
  id?: string;
  label?: string;
  size?: ISizeInput;
  skipRequired?: boolean;
}

export const FormTextField = observer((props: IFormTextFieldProps): JSX.Element | null => {
  const [translate] = useTranslation();
  const {
    valid,
    invalid,
    disabled,
  } = classes;

  const {
    type = 'string',
    model,
    width,
    multiline,
    placeholder,
    size,
    skipRequired = false,
  } = props;

  if (model.value) {
    model.touch(); // Force showing error message on prefilled fields
  }

  const label = props.label || model.label && translate(model.label);
  const dataTest = props.id || model.label || label;
  const textFieldClasses = cn(
    model.showErrorMessage ? invalid : valid,
    model.isDisabled && disabled,
  );

  return (
    <StyledBox
      width={width || '100%'}
      className="form-text-field"
      data-test={`${dataTest}-container`}
    >
      <Field
        required={!skipRequired && model.isRequired}
        size={size}
        label={label}
        type={type}
        id={model.label}
        value={model.value}
        error={model.isTouched && model.isInvalid}
        helperText={model.showErrorMessage
          && translate(model.errorMessage as string)}
        placeholder={placeholder}
        onInput={model.touch}
        onChange={(e) => model.change(e.target.value)}
        onBlur={model.touch}
        className={textFieldClasses}
        fullWidth
        InputProps={multiline
          ? {
            style: {
              minHeight: '88px',
              alignItems: 'start',
            },
          }
          : {
            style: {},
          }}
        multiline={multiline}
        disabled={model.isDisabled}
      />
    </StyledBox>
  );
});
