import { injectable } from 'inversify';

import type {
  ITagManagerData,
  IGoogleTagManager,
} from './interfaces';

@injectable()
export class GoogleTagManager implements IGoogleTagManager {
  public push(data: ITagManagerData): void {
    window.dataLayer.push(data);
  }
}
