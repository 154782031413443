import React from 'react';
import { styled } from '@mui/material/styles';

import { phonePattern } from '@esurance/legacy-constants';

import { _t } from '../../service';

import type { IInputProps } from './input';
import { Input } from './input';

// eslint-disable-next-line
export interface IPhoneProps extends IInputProps {}

const InputContainer = styled('div')({
  '& .MuiOutlinedInput-input': {
    height: '44px',
    boxSizing: 'border-box',
  },
});

export class PhoneInput extends React.PureComponent<IPhoneProps> {
  public render(): JSX.Element {
    const { label, ...restProps } = this.props;

    return (
      <InputContainer>
        <Input
          pattern={phonePattern}
          validationMessage={_t(
            'signup_organisation-info_street-phone-invalid-input',
          )}
          placeholder="+417"
          defaultValue="+417"
          onMouseDown={this.handlePhoneMouseDown}
          type="tel"
          label={(
            label || label === ''
              ? label
              : _t('signup_organisation-info_street-phone-input')
          )}
          name="phone_number"
          {...restProps}
        />
      </InputContainer>
    );
  }

  private handlePhoneMouseDown = (event: React.MouseEvent<HTMLInputElement>): void => {
    const input = event.currentTarget;

    setTimeout(() => {
      if (input.value === '') {
        input.value = '+417';
      }
    });
  };
}

export default PhoneInput;
