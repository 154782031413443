import ReactGA from 'react-ga';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';

import { useInjection } from '@esurance/ioc-base';
import type { IHistoryManager } from '@esurance/services';
import { DependencyType } from '@esurance/services';

export const RouterListener: React.FC = () => {
  const historyManager = useInjection<IHistoryManager>(DependencyType.HistoryManager);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    historyManager.setNavigateFunction(navigate);
  }, [navigate]);

  useEffect(() => {
    if (window.gaId) {
      ReactGA.initialize(window.gaId, { debug: false });
      ReactGA.pageview(window.location.pathname);
    } else {
      // TODO: use logger, sentry for example. Task: ESD-9838
      // eslint-disable-next-line no-console
      console.warn('Google Analytics Tracking Id is not configured.');
    }
  }, []);

  useEffect(() => {
    historyManager.setLocation(location);
    if (window.gaId) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    } else {
      // TODO: use logger, sentry for example. Task: ESD-9838
      // eslint-disable-next-line no-console
      console.warn('Google Analytics Tracking Id is not configured.');
    }
  }, [location]);

  return null;
};
