export { inversifyContainer, lazyInject } from './lib/inversifyContainer';
export { diContainer, DiContainer } from './lib/DiContainer';
export type { IServicesConfig } from './lib/initServices';
export { initServices } from './lib/initServices';

export type { IDependency } from './lib/replaceDependency';
export { replaceDependencyWithConst, replaceDependencyWithType } from './lib/replaceDependency';

export { configureContainer } from './lib/configureContainer';

export { Provider, InversifyContext } from './lib/InversifyContext';
export { useInjection } from './lib/useInjection';
