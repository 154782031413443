import './drag-n-drop.styl';

import type { ReactNode } from 'react';
import React from 'react';
import type { Accept, DropEvent, FileRejection } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUploadRounded';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SIZE_15_MB } from '@esurance/legacy-constants';

interface IProps {
  description?: string | ReactNode;
  buttonLabel: string;
  multiple?: boolean;
  acceptedTypes?: Accept;
  onDrop: (
    accepted: File[],
    rejected?: FileRejection[],
    event?: DropEvent,
  ) => void;
  children?: ReactNode;
  maxSize?: number;
  disableMaxSize?: boolean
}
const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  textAlign: 'left',
}));

export class DragNDrop extends React.Component<IProps> {
  public static defaultProps = {
    acceptedTypes: {
      'image/jpeg': [],
      'image/png': [],
      'image/bmp': [],
      'image/tiff': [],
      'application/pdf': [],
    },
    multiple: false,
    maxSize: SIZE_15_MB,
    disableMaxSize: false,
  };

  public render(): JSX.Element {
    const {
      acceptedTypes,
      multiple,
      description,
      buttonLabel,
      children,
      maxSize,
      disableMaxSize,
    } = this.props;

    const dropzoneProps = new Map<keyof IProps, IProps[keyof IProps]>();

    if (!disableMaxSize) {
      dropzoneProps.set('maxSize', maxSize);
    }

    return (
      <div className="upload">
        <Dropzone
          accept={acceptedTypes}
          onDrop={this.handleDrop}
          multiple={multiple}
          {...Object.fromEntries(dropzoneProps)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="drag-n-drop" aria-disabled="false" {...getRootProps()}>
              <input className="input-zone" {...getInputProps()} />
              <TitleWrapper marginBottom={2}>
                <CloudUploadIcon fontSize="inherit" />
                {' '}
                {description}
              </TitleWrapper>
              <button type="button" className="btn with-border mini no-responsive">
                {buttonLabel}
              </button>
            </div>
          )}
        </Dropzone>
        {children}
      </div>
    );
  }

  private handleDrop = (
    accepted: File[],
    rejected?: FileRejection[],
    event?: DropEvent,
  ): void => {
    const { onDrop } = this.props;
    onDrop(accepted, rejected, event);
  };
}
