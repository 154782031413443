import { emailPattern } from '@esurance/legacy-constants';

import { _t } from '../service/i18n';

import type { IValidationResult } from './IValidationResult';

export function emailRule(value: string): IValidationResult {
  return {
    message: _t('survey-invalid-email-message'),
    passes: value.search(emailPattern) !== -1,
  };
}
