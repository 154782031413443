import React from 'react';
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import LinkIcon from '@mui/icons-material/Link';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import type { ExtendButtonBase, ButtonTypeMap } from '@mui/material';

const Chf = styled((props) => <Box {...props}>chf</Box>)(({ theme }) => ({
  textTransform: 'uppercase',
  marginRight: theme.spacing(0.5),
}));

const BasketSubmitButtonIcon = styled(ArrowForwardIosRoundedIcon)(() => ({
  height: '16px',
  color: 'white',
}));

const LinkButton = styled<ExtendButtonBase<ButtonTypeMap>>((props: any) => (
  <Button size="large" {...props} />
))(() => ({
  '&:hover': {
    background: 'transparent',
  },
}));

const DownloadOfferButtonIcon = styled(TextSnippetOutlinedIcon)(({ theme }) => ({
  height: '24px',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(0.5),
}));

const SaveSessionButtonIcon = styled(LinkIcon)(({ theme }) => ({
  height: '24px',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(0.5),
}));

export interface IBasketSubmitProps {
  total: string;
}

class BasketSubmitView extends React.Component<IBasketSubmitProps & WithTranslation> {
  public render() {
    const { total, t: translate } = this.props;

    return (
      <Stack className="BasketSubmit-root">
        <Stack direction="row" alignItems="baseline" mb={2.5} className="BasketSubmit-total">
          <Typography variant="h6">
            {translate('basket_submit.total')}
          </Typography>

          <Typography variant="h2" display="flex" alignItems="baseline" ml="auto">
            <Typography variant="h5">
              <Chf />
            </Typography>
            {total}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="baseline" mb={2.5} className="BasketSubmit-submit">
          <Button
            variant="contained"
            size="large"
            fullWidth
            disableElevation
          >
            {translate('basket_submit.submit_button')}
            <BasketSubmitButtonIcon />
          </Button>
        </Stack>

        <Stack direction="row" justifyContent="space-between" className="BasketSubmit-additional">
          <LinkButton sx={{ pl: 0 }}>
            <DownloadOfferButtonIcon />
            {translate('basket_submit.download_offer')}
          </LinkButton>
          <LinkButton sx={{ pr: 0 }}>
            <SaveSessionButtonIcon />
            {translate('basket_submit.save_session')}
          </LinkButton>
        </Stack>
      </Stack>
    );
  }
}

export const BasketSubmit = withTranslation()(BasketSubmitView);
