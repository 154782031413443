export const ConstantDependency = {
  AffinityApiRoot: Symbol.for('AffinityApiRoot'),

  AffinityV3ApiRoot: Symbol.for('AffinityV3ApiRoot'),

  BackofficeApiRoot: Symbol.for('BackofficeApiRoot'),

  BackofficeV3ApiRoot: Symbol.for('BackofficeV3ApiRoot'),

  ProductEngineApiRoot: Symbol.for('ProductEngineApiRoot'),

  LocalStoragePrefix: Symbol.for('LocalStoragePrefix'),

  RecaptchaSiteKey: Symbol.for('RecaptchaSiteKey'),

  SentryPublicDsn: Symbol.for('SentryPublicDsn'),

  EsuranceUrl: Symbol.for('EsuranceUrl'),

  ApplicationName: Symbol.for('ApplicationName'),

  SwicaUrl: Symbol.for('SwicaUrl'),

  GastroSocialUrl: Symbol.for('GastroSocialUrl'),

  GastronomieUrl: Symbol.for('GastronomieUrl'),

  CockpitUrl: Symbol.for('CockpitUrl'),

  KeycloakUrl: Symbol.for('KeycloakUrl'),

  KeycloakRealm: Symbol.for('KeycloakRealm'),

  KeycloakClientId: Symbol.for('KeycloakClientId'),

  GastroShopAbsoluteUrl: Symbol.for('GastroShopAbsoluteUrl'),
} as const;
