import React from 'react';
import { debounce, isNil } from 'lodash';
import classNames from 'classnames';
import type {
  DropdownIndicatorProps,
  InputActionMeta,
  Props as ReactSelectProps,
} from 'react-select';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import type { OptionType } from '../types';

import type { InputType } from './model/InputType';

import './Select.styl';

export interface ISelectProps<T> extends ReactSelectProps<T> {
  inputType?: InputType;
  inputChangeDebounce?: number;
  hideDropdownIndicator?: true;
  isCreatable?: boolean;
}

export interface ISelectState {
  debouncedOnInputChange: (val: string, meta: InputActionMeta) => void;
}

export class Select<LocalOptionType = OptionType>
  extends React.Component<ISelectProps<LocalOptionType>, ISelectState> {
  private mounted = true;

  // TODO: AD: NX: need to replace any
  private reactSelectRef: any | null = null;

  public state: ISelectState = {
    debouncedOnInputChange: () => undefined,
  };

  public componentWillUnmount(): void {
    this.mounted = false;
  }

  private updateDebounced(): void {
    const { inputChangeDebounce } = this.props;

    const debouncedOnInputChange = debounce(
      (val: string, actionMeta: InputActionMeta): void => {
        const { onInputChange } = this.props;
        if (this.mounted && !isNil(onInputChange)) {
          onInputChange(val, actionMeta);
        }
      },
      inputChangeDebounce || 0,
    );

    this.setState({
      debouncedOnInputChange,
    });
  }

  public componentDidMount(): void {
    this.mounted = true;
    this.updateDebounced();
  }

  public componentDidUpdate(
    prevProps: Readonly<ISelectProps<LocalOptionType>>,
  ): void {
    const { onInputChange } = this.props;

    if (prevProps.onInputChange !== onInputChange) {
      this.updateDebounced();
    }
  }

  public render(): JSX.Element {
    const {
      isCreatable,
      inputChangeDebounce,
      className,
      hideDropdownIndicator,
      ...selectProps
    } = this.props;
    const { debouncedOnInputChange } = this.state;

    selectProps.onInputChange = debouncedOnInputChange;
    const classes = classNames('es-select Select', className || '');

    if (this.isAutocomplete()) {
      selectProps.filterOption = () => true;
    }

    const DropdownIndicator = (
      props: DropdownIndicatorProps<LocalOptionType>,
    ): JSX.Element => {
      // eslint-disable-next-line
      const { children, ...indicatorProps } = props;

      if (this.isAutocomplete() || hideDropdownIndicator) {
        return <div />;
      }

      return (
        <components.DropdownIndicator {...indicatorProps}>
          <div className="arrow-down" />
        </components.DropdownIndicator>
      );
    };

    const defaultComponents = {
      DropdownIndicator,
    };

    if (isCreatable) {
      return (
        <CreatableSelect
          className={classes}
          classNamePrefix="es-select"
          components={defaultComponents}
          ref={(reactSelect) => {
            this.reactSelectRef = reactSelect;
          }}
          {...selectProps}
        />
      );
    }

    return (
      <ReactSelect
        className={classes}
        classNamePrefix="es-select"
        components={defaultComponents}
        ref={(reactSelect) => {
          this.reactSelectRef = reactSelect;
        }}
        styles={{
          input: () => ({
            width: '100%',
          }),
        }}
        {...selectProps}
      />
    );
  }

  public focus = (): void => {
    if (this.reactSelectRef) {
      this.reactSelectRef.focus();
    }
  };

  public blur = (): void => {
    if (this.reactSelectRef) {
      this.reactSelectRef.blur();
    }
  };

  private isAutocomplete = (): boolean => {
    const { inputType } = this.props;
    return inputType === 'autocomplete';
  };
}
