import React from 'react';
import classNames from 'classnames';

interface IProps
  extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
  > {
  dot: React.ReactNode;
  meta?: React.ReactNode;
}

export function TimelineItem({
  dot,
  meta,
  className,
  children,
  ...restProps
}: React.PropsWithChildren<IProps>): JSX.Element {
  return (
    <div className={classNames('timeline-item', className)} {...restProps}>
      {meta && <div className="timeline-item-meta">{meta}</div>}

      <div className="timeline-item-tail">
        {dot && <div className="timeline-item-dot">{dot}</div>}
      </div>

      <div className="timeline-item-content">{children}</div>
    </div>
  );
}
