import './alert.styl';

import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import { AlertTypes } from '@esurance/legacy-constants';

interface IProps {
  type?: AlertTypes;
  notification?: boolean;
  onTop?: boolean;
  className?: string;
  children?: ReactNode;
}

export class Alert extends React.Component<IProps> {
  public static defaultProps = {
    type: AlertTypes.Secondary,
  };

  public render(): JSX.Element {
    const {
      notification,
      onTop,
      className,
      type,
      children,
    } = this.props;

    const alertClasses = classNames('alert', type, className, {
      notification,
      ontop: onTop,
    });

    return (
      <div className={alertClasses}>
        <div className="alert-inner">{children}</div>
      </div>
    );
  }
}
