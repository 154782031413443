import './toggler.styl';

import React from 'react';

import type { ProductTypes } from '@esurance/legacy-constants';

import type { IProps } from './checkbox';
import { Checkbox } from './checkbox';

interface IToggler extends IProps {
  productType?: ProductTypes;
}

export function Toggler(props: IToggler): JSX.Element {
  const { classNames } = props;
  const componentClassNames = classNames ? [...classNames] : [];
  componentClassNames.push('checkbox-toggler-container');
  const nextProps = { ...props, classNames: componentClassNames };
  return <Checkbox {...nextProps} />;
}
