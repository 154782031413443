import React from 'react';
import { styled } from '@mui/material';

import { sanitizeTranslationTag } from '../../../helpers';

import { parserForTag } from './helpers';

interface IProps {
  logoSrc: string;
  logoHref?: string;
}

const Image = styled('img')({
  maxHeight: '31px',
  maxWidth: '131px',
  verticalAlign: 'middle',
});

export function LogoTag({ logoSrc, logoHref }: IProps) {
  const image = (<Image src={logoSrc} />);

  if (logoHref) {
    return (
      <a href={logoHref}>
        {image}
      </a>
    );
  }

  return image;
}

const tagName = 'logo';

export const logoTagParser = parserForTag(tagName, (element) => {
  const sanitizedElement = sanitizeTranslationTag(tagName, element);

  return (
    <LogoTag
      logoSrc={sanitizedElement.getAttribute('src') || 'unknown_src'}
      logoHref={sanitizedElement.getAttribute('href') || undefined}
    />
  );
});
