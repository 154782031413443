import React from 'react';
import { Typography } from '@mui/material';

import { TranslationWithTags } from '@esurance/ui-components';

interface IProps {
  title: string;
}

export class TermsTitle extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      title,
    } = this.props;
    return (
      <Typography>
        <TranslationWithTags translationKey={title} />
      </Typography>
    );
  }
}
