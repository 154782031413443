import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';

import { useInjection } from '@esurance/ioc-base';
import { ConstantDependency } from '@esurance/constants';

const PREFIX = 'Footer';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('footer')(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '64px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export function Footer() {
  const [translate] = useTranslation();
  const esuranceUrl = useInjection(ConstantDependency.EsuranceUrl);
  return (
    <Root className={classes.root}>
      <Link
        variant="body1"
        underline="none"
        color="textPrimary"
        href={`${esuranceUrl}/impressum`}
        target="_blank"
        rel="noreferrer"
      >
        {translate('legal-notice')}
      </Link>
    </Root>
  );
}
