import type { Options } from 'fingerprintjs2';
import fingerprintjs2 from 'fingerprintjs2';

import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type { HealthEventName } from '@esurance/legacy-constants';
import { AlertTypes } from '@esurance/legacy-constants';

import { _t } from '../service';

class Fingerprint {
  private defaultFingerprintOptions: Options = {
    ERROR: 'error',
    EXCLUDED: 'excluded',
    NOT_AVAILABLE: 'not available',
    audio: {
      // On iOS 11, audio context can only be used in response to user interaction.
      // We require users to explicitly enable audio fingerprinting on iOS 11.
      // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
      excludeIOS11: true,
      timeout: 1000,
    },
    excludes: {
      // DNT depends on incognito mode for some browsers (Chrome)
      // and it's impossible to detect incognito mode
      doNotTrack: true,
      // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
      enumerateDevices: true,
      // uses js fonts already
      fontsFlash: true,
      // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
      pixelRatio: true,
    },
    extraComponents: [],
    fonts: {
      extendedJsFonts: false,
      swfContainerId: 'fingerprintjs2',
      swfPath: 'flash/compiled/FontList.swf',
      userDefinedFonts: [],
    },
    plugins: {
      excludeIE: false,
      sortPluginsFor: [/palemoon/i],
    },
    preprocessor: undefined,
    screen: {
      // To ensure consistent fingerprints when users rotate their mobile devices
      detectScreenOrientation: true,
    },
  };

  public send(
    event: HealthEventName,
    userId?: number,
    orgId?: number,
    options: Options = this.defaultFingerprintOptions,
  ): Promise<boolean | string> {
    // Cause we need to send the same fingerprintOprions on backend, to know how it was made.
    return fingerprintjs2
      .getPromise(options)
      .then((components: any) => {
        const values = components.map((component: any) => component.value);
        const hash = fingerprintjs2.x64hash128(values.join(''), 31);

        // TODO: If new user goes to gastro, and try to send fingerprint, backend returns 401,
        // cause user doesn't autenticated.
        // Need to fix it on th Backend
        return axios
          .post(`${diContainer.backofficeApiRoot}/admin/front_end_event`, {
            data: options,
            event,
            fingerprint: hash,
            org_id: orgId,
            user_id: userId,
          })
          .then(() => true);
      })
      .catch((error) => {
        diContainer.notificationManager.raise({
          closeable: true,
          content: _t('fingerprint_error'),
          type: AlertTypes.Error,
        });
        return error.messange;
      });
  }
}

export const fingerprint = new Fingerprint();
