import type { TagParser, TagParserResult } from '../types';
import { getAllParsers } from '../tags';

import { parseNode } from './parseNode';

export function parseTranslation(text: string, tagParsers: TagParser[]): TagParserResult[] {
  const parser = document.createElement('div');
  parser.innerHTML = text;
  const allTagParsers = getAllParsers(tagParsers);

  try {
    const components: TagParserResult[] = [];
    for (const child of parser.childNodes) {
      const node = parseNode(child, allTagParsers);
      components.push(node);
    }
    return components;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return [text];
  }
}
