/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { TOptions } from 'i18next';
import i18next from 'i18next';

import type { ITranslatable, ITranslatedKey } from '@esurance/entities';

import { Input } from '../Input/Input';
import { TextArea } from '../TextArea/TextArea';
import { TranslatedFieldKeyLabel } from '../TranslationKeyLabel/TranslatedFieldKeyLabel';
import { FormItem } from '../FormItem/FormItem';

interface ITranslatedFieldProps {
  label: string;
  field: ITranslatedKey;
  setter: (value: string) => void;
  className?: string;
  isMultiline?: true;
  disabled?: boolean;
}

function Field({
  label,
  field,
  setter,
  className,
  isMultiline,
  disabled,
}: ITranslatedFieldProps): JSX.Element {
  const [t, i18n] = useTranslation();

  const translate = (f: ITranslatedKey): string => translateObject(f.translation, i18n.language);
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => (
    setter(e.target.value)
  );

  return (
    <FormItem
      label={t(label)}
      bottomLabel={<TranslatedFieldKeyLabel>{field.translationKey}</TranslatedFieldKeyLabel>}
      className={className}
    >
      {isMultiline === undefined && (
        <Input
          value={translate(field)}
          className="input"
          onChange={onChange}
          disabled={disabled}
        />
      )}
      {isMultiline === true && (
        <TextArea
          value={translate(field)}
          className="textarea"
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </FormItem>
  );
}

export const TranslatedField = observer(Field);

// TODO: copy of shared _t, _tf functions. We need to move them into separate module
export const translateStr = (
  key: string,
  options?: TOptions,
): string => {
  if (window.location.search === '?lng=empty') {
    return '';
  }
  if (window.location.search === '?lng=key') {
    return key;
  }
  return i18next.t(key, options);
};

export function translateObject<T = string>(
  field: Partial<ITranslatable<T>> | string,
  language?: string,
): T | string {
  if (typeof field === 'string') {
    return translateStr(field);
  }
  if (_.isObject(field) && language) {
    const translation = field[language || i18next.language];
    if (translation) {
      return translation;
    }
  }
  return JSON.stringify(field);
}
