import {
  format, isValid, parse, startOfToday,
} from 'date-fns';

export const DATE_FORMAT_REGEX = /\d{2}\.\d{2}\.\d{4}/;

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT_SHORT = 'dd.MM';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
// Backend dates
export const DATE_TIME_FORMAT_BACKEND = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT_BACKEND = 'yyyy-MM-dd';

export const parseDate = (
  date: string,
  formatting = DATE_FORMAT,
): Date => parse(date, formatting, startOfToday());

export const formatDate = (
  date: string,
): string => format(new Date(date), DATE_FORMAT);

export const formatDateShort = (
  date: string,
  formatting = DATE_FORMAT,
): string => format(parseDate(date, formatting), DATE_FORMAT_SHORT);

export const isValidDate = (
  date: string,
  formatting = DATE_FORMAT,
  regex = DATE_FORMAT_REGEX,
): boolean => regex.test(date) && isValid(parseDate(date, formatting));
