import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { StyledEngineProvider } from '@mui/material/styles';

import { AuthProvider } from '@esurance/auth';
import { Boundary } from '@esurance/shared/component';
import { diContainer, inversifyContainer, Provider } from '@esurance/ioc-base';
import { AppWithRequiredCookies, RouterListener } from '@esurance/domain-components';
import { EsuranceThemeProvider, ThemeName } from '@esurance/material-theme';

import { Layout } from './Layout';

export function App(): JSX.Element {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<></>}>
        <Provider container={inversifyContainer}>
          <Boundary>
            <I18nextProvider i18n={diContainer.translationManager.i18n}>
              <StyledEngineProvider injectFirst>
                <EsuranceThemeProvider theme={ThemeName.Backoffice} href={window.location.href}>
                  <AppWithRequiredCookies>
                    <AuthProvider>
                      <BrowserRouter
                        basename={diContainer.historyManager.basename}
                      >
                        <RouterListener />
                        <Layout />
                      </BrowserRouter>
                    </AuthProvider>
                  </AppWithRequiredCookies>
                </EsuranceThemeProvider>
              </StyledEngineProvider>
            </I18nextProvider>
          </Boundary>
        </Provider>
      </React.Suspense>
    </React.StrictMode>
  );
}
