import type { IValidator } from '../interfaces';

export class ValidateIfNotEmpty<T = string> implements IValidator<T> {
  public errorMessage: string;

  constructor(private validator: IValidator<T>) {
    if (!validator) { throw new Error('Validator are required as argument of the constructor'); }
    this.errorMessage = this.validator.errorMessage;
  }

  public validate(value: T): boolean { return value ? this.validator.validate(value) : true; }
}
