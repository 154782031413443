import { injectable } from 'inversify';

import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';

import type {
  ICompanySuggestionRepository,
  ICompanySuggestionResponseDto,
  ICompanySuggestionSearchResponseDto,
  ICompanySuggestionData,
} from './interfaces';
import { parseCompanySuggestionDto } from './parsers/parseCompanySuggestionDto';

export function getSuggestionsEndpointUrl(apiRoot: string, query: string): string {
  return `${apiRoot}/organisation/suggest/query/${query}`;
}

@injectable()
export class CompanySuggestionRepository implements ICompanySuggestionRepository {
  public getSuggestions(query: string): Promise<ICompanySuggestionSearchResponseDto[]> {
    return axios
      .get<ICompanySuggestionSearchResponseDto[]>(
        getSuggestionsEndpointUrl(diContainer.backofficeApiRoot, query),
      )
      .then((response) => response.data);
  }

  public getData(uri: string): Promise<ICompanySuggestionData> {
    return axios
      .get<ICompanySuggestionResponseDto>(
        `${diContainer.backofficeApiRoot}/organisation/suggest/get/${uri}`,
      )
      .then((response) => parseCompanySuggestionDto(response.data));
  }
}
