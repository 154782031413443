import React from 'react';
import type { SvgIconProps } from '@mui/material';

import {
  Pensionskasse,
  Haftpflicht,
  Betriebsausfall,
  Unfall,
  Krankentaggeld,
  Rechtsschutz,
  Inventar,
  Erwerbsunfähigkeit,
  RetirementAccount,
  Mietkaution,
  Haushalt,
  Gebäude,
  Krankenkasse,
} from '@esurance/shared';
import type { ProductTypes } from '@esurance/legacy-constants';

interface IProps extends SvgIconProps {
  type: ProductTypes,
}

export const ProductIcon = (props: IProps) => {
  const {
    type,
    fontSize,
    color,
    sx,
  } = props;

  if (type === 'Pensionskasse' || type === 'PensionFund') {
    return <Pensionskasse fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Haftpflicht') {
    return <Haftpflicht fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'BetriebsAusfall') {
    return <Betriebsausfall fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Unfall') {
    return <Unfall fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Krankentaggeld') {
    return <Krankentaggeld fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Rechtsschutz') {
    return <Rechtsschutz fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Inventar') {
    return <Inventar fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Erwerbsunfähigkeit' || type === 'Disability') {
    return <Erwerbsunfähigkeit fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'RetirementAccount' || type === 'Ausgleichskasse') {
    return <RetirementAccount fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Mietkaution') {
    return <Mietkaution fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Haushalt' || type === 'Noch kein Mitglied?') {
    return <Haushalt fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Gebäude') {
    return <Gebäude fontSize={fontSize} color={color} sx={sx} />;
  }

  if (type === 'Krankenkasse') {
    return <Krankenkasse fontSize={fontSize} color={color} sx={sx} />;
  }

  return <Haushalt fontSize={fontSize} color={color} sx={sx} />;
};
