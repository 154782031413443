import type { ISessionData, ISessionDto } from '../interfaces';

import { parseSessionContactData } from './parseSessionContactData';
import { parseSessionFlowData } from './parseSessionFlowData';

export function parseSessionData(
  dto: ISessionDto,
): ISessionData {
  return {
    identity: dto.identity,
    contact: dto.contact ? parseSessionContactData(dto.contact) : undefined,
    flow: dto.flow ? parseSessionFlowData(dto.flow) : undefined,
  };
}
