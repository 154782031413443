import React from 'react';
import type { TooltipProps, Theme } from '@mui/material';
import { Tooltip as MaterialTooltip, tooltipClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { sanitize } from 'dompurify';

import { useIsMobile } from '../../hooks';

export type TooltipPlace = TooltipProps['placement'];

interface IProps {
  title: string;
  place?: TooltipPlace;
  mobilePlace?: TooltipPlace;
  className?: string;
  sx?: SxProps<Theme>;
  'data-test'?: string
}

const EsuranceTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'inline-block',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 250,
    borderRadius: 0,
    padding: '11px 16px',
    lineHeight: '18px',
    boxShadow: '6px 9px 20px 0 rgba(204, 213, 221, 0.4)',
    border: '1px solid #d0d8da',
    margin: '2px !important',
  },
}));

const ContentWrapper = styled('div')({
  display: 'inline-block',
  lineHeight: 0,
  cursor: 'pointer',
});

const ALLOWED_TAGS = ['p', 'ul', 'li'];

export function Tooltip({
  title,
  place,
  mobilePlace,
  children,
  className,
  sx,
  'data-test': dataTest,
}: React.PropsWithChildren<IProps>) {
  const isMobile = useIsMobile();

  return (
    <EsuranceTooltip
      sx={sx}
      placement={isMobile ? mobilePlace : place}
      title={(
        <Typography
          component="div"
          variant="small"
          data-test={dataTest}
          dangerouslySetInnerHTML={{ __html: sanitize(title, { ALLOWED_TAGS }) }}
        />
      )}
    >
      <ContentWrapper className={className}>
        {children}
      </ContentWrapper>
    </EsuranceTooltip>
  );
}

Tooltip.defaultProps = {
  place: 'left',
  mobilePlace: 'bottom-end',
};
