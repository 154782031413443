/* eslint-disable
jsx-a11y/no-static-element-interactions,
jsx-a11y/no-noninteractive-element-interactions,
react/destructuring-assignment,
react/no-access-state-in-setstate */

import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded';

import { Loader } from '../Loader/Loader';

import './Dropdown.styl';

export const enum DropdownArrowType {
  Default,
  Chevron,
}

interface IOptions {
  value: string;
  title: string;
}

interface IProps {
  list: readonly IOptions[];
  loading?: boolean;
  onClick?: (value: string) => void;
  selected?: string;
  renderTrigger?: (
    selected: string,
    label: string,
    labelClass: string,
    arrow: JSX.Element,
    onToggle: (e: React.SyntheticEvent<HTMLElement>) => void,
    touched: boolean,
  ) => void;
  className?: string;
  arrowType?: DropdownArrowType;
  triggerIcon?: JSX.Element;
}

interface IState {
  open: boolean;
  selected: string;
  touched: boolean;
}

class DropdownView extends React.Component<IProps & WithTranslation, IState> {
  public static defaultProps: Partial<IProps> = {
    arrowType: DropdownArrowType.Default,
  };

  public state: IState = {
    open: false,
    selected: '',
    touched: false,
  };

  private dropdownElement: EventTarget & HTMLElement | null;

  public componentWillUnmount(): void {
    this.dropdownElement = null;
    this.toggleListener(false);
  }

  // TODO: ESD-8674: rewrite the logic using mobx model
  public componentDidUpdate(previousProps: Readonly<IProps>): void {
    const { selected: currentSelected } = this.props;
    if (!_.isEqual(currentSelected, previousProps.selected)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selected: currentSelected || '',
      });
    }
  }

  public render(): JSX.Element {
    const { loading = false, list, selected } = this.props;

    const items = list.map((item) => {
      const className = classNames({
        selected: item.value === selected,
      });

      return (
        <div
          className={className}
          key={item.value}
          onClick={(e) => {
            e.preventDefault();
            this.onSelect(item.value);
          }}
        >
          {item.title}
        </div>
      );
    });

    const listContent = loading === true ? <Loader loading /> : items;

    // eslint-disable-next-line react/destructuring-assignment
    const className = classNames('dropdown', this.props.className);
    return (
      <div className={className}>
        <>
          {this.renderTrigger()}
          {this.state.open && <div className="list">{listContent}</div>}
        </>
      </div>
    );
  }

  private getArrow(): JSX.Element {
    const { arrowType, triggerIcon } = this.props;

    if (triggerIcon) {
      return triggerIcon;
    }

    if (arrowType === DropdownArrowType.Chevron) {
      return (
        <ArrowDropDownIcon
          fontSize="small"
          color="secondary"
          onClick={this.onToggle}
        />
      );
    }
    return <div className="arrow-down" onClick={this.onToggle} />;
  }

  private renderTrigger(): JSX.Element | void {
    const { t: translate } = this.props;

    const selected = this.props.selected
      ? this.props.selected
      : this.state.selected;

    let label = _.get(
      _.find(this.props.list, ['value', selected]),
      'title',
      '',
    );

    const labelClass = classNames('dropdown-trigger', {
      open: this.state.open,
      selected: !_.isEmpty(selected),
    });

    label = label || translate('dropdown-select-value-placeholder');
    const arrow = this.getArrow();

    if (this.props.renderTrigger) {
      return this.props.renderTrigger(
        selected,
        label,
        labelClass,
        arrow,
        this.onToggle,
        this.state.touched,
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label className={labelClass} onClick={this.onToggle}>
        {label}
        {arrow}
      </label>
    );
  }

  private onToggle = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    this.dropdownElement = e.currentTarget;
    const open = !this.state.open;
    this.setState({ open, touched: !this.state.touched && this.state.open });
    this.toggleListener(open);
  };

  private closeListener = (windowElem: MouseEvent): void => {
    const { target } = windowElem;
    if (target !== this.dropdownElement) {
      this.setState({ open: false, touched: true });
      this.toggleListener(false);
    }
  };

  private toggleListener(add: boolean): void {
    if (add) {
      window.addEventListener('click', this.closeListener);
    } else {
      window.removeEventListener('click', this.closeListener);
    }
  }

  private onSelect = (value: string): void => {
    if (this.props.onClick) {
      this.props.onClick(value);
    }
    this.setState({
      open: false,
      selected: value,
      touched: true,
    });
  };
}

export const Dropdown = withTranslation()(DropdownView);
