export const isGastrosuisseAffinityUrl = (url: string) => url.includes('gastroversicherungen');

export function getGastrosuisseAffinityUrl(): string {
  if (isGastrosuisseAffinityUrl(window.location.origin)) {
    return window.location.origin;
  }

  // Add the affinity name into the URL, if missing.
  return window.location.origin.replace(
    /\/\/.+?\./, '//gastroversicherungen.',
  );
}
