import React from 'react';
import { Field } from 'react-final-form';

import { Input, FormItem } from '@esurance/ui-components';

type InputFieldType = 'text' | 'number';

interface IProps {
  name: string;
  validate?: (value: string | number | string[]) => string;
  label?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  type?: InputFieldType;
  className?: string;
}

export function InputField(props: IProps): JSX.Element {
  const { name, validate } = props;
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <FormItem
          htmlFor={input.name}
          required={props.required}
          label={props.label}
          className={props.className}
        >
          <Input
            {...input}
            pattern={props.pattern}
            placeholder={props.placeholder}
            type={props.type || 'text'}
          />
          {meta.touched && meta.error && (
            <div className="v-error">{meta.error}</div>
          )}
        </FormItem>
      )}
    </Field>
  );
}
