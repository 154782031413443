import './companies-autocomplete.styl';

import _ from 'lodash';
import React from 'react';
import { resolve } from 'inversify-react';
import type { AutocompleteInputChangeReason } from '@mui/material';
import { Autocomplete, styled, TextField } from '@mui/material';
import type {
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import type { OptionType } from '@esurance/ui-components';
import { createOption } from '@esurance/ui-components';
import type { ICompanySuggestionSearchResponseDto } from '@esurance/autocompletes';
import {
  DependencyType as AutocompletesDependencyType,
  ICompanySuggestionRepository,
} from '@esurance/autocompletes';

import { configStore } from '../../application/configStore';

export const IS_NEW_OPTION_FLAG = '__isNew__';

const debounceAnonymous = _.debounce((func) => func(), configStore.debounce.inputChange);

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.border.regular,
  position: 'absolute',
  right: '30px',
}));

interface IProps extends WithTranslation {
  value?: string | null;
  placeholder?: string;
  onChange: (value: OptionType) => void;
  onClear: () => void;
  onInputChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  innerRef?: React.Ref<HTMLElement>;
}

interface IState {
  inputValue: string;
  loading: boolean;
  options: OptionType[];
}

// TODO: AD: it requires more deep refactoring
class CompaniesAutocompleteImpl extends React.Component<IProps, IState> {
  @resolve(AutocompletesDependencyType.CompanySuggestionRepository)
  private companySuggestionRepository: ICompanySuggestionRepository;

  public static defaultProps: Partial<IProps> = {
    onFocus: () => undefined,
    onBlur: () => undefined,
  };

  public state: IState = {
    inputValue: '',
    loading: false,
    options: [],
  };

  private readonly CREATE_NEW_OPTION_VALUE: string = 'companies_create_new';

  public componentDidMount(): void {
    this.initializeDefaultValueAndOptions();
  }

  private initializeDefaultValueAndOptions = (): void => {
    const { value } = this.props;

    if (!value) {
      return;
    }

    this.setState({
      loading: true,
    });

    this.companySuggestionRepository.getData(_.kebabCase(value))
      .then(async (companyInformation) => {
        const options = companyInformation.name
          ? await this.fetchCompanySuggestionOptions(companyInformation.name)
          : [createOption(value)];

        this.setState({
          options,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  public render(): JSX.Element {
    const {
      value,
      onFocus,
      onBlur,
      innerRef,
      t: translate,
    } = this.props;
    const { loading, options, inputValue } = this.state;

    return (
      <Autocomplete<OptionType, false, false, boolean>
        freeSolo
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'object') {
            return option.label;
          }

          return option || '';
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
            data-test={`option_${option.value}`}
            data-test-label={option.label}
          >
            {option.label || ''}
          </li>
        )}
        isOptionEqualToValue={(option, currentValue) => option.value === currentValue.value}
        loading={loading}
        loadingText={translate('search_prompt_text')}
        value={value}
        onChange={this.handleChange}
        inputValue={inputValue}
        onInputChange={this.handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        noOptionsText={translate('search_prompt_text')}
        renderInput={(params) => (
          <TextField
            {...params}
            data-test="companies-autocomplete-input"
            inputRef={innerRef}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && (
                    <StyledCircularProgress
                      data-test="loading-input-indicator"
                      size={20}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={(options, { inputValue }) => {
          if (inputValue !== '') {
            return [
              {
                label: `${translate('prompt_text_creator')} "${inputValue}"`,
                value: inputValue,
                [IS_NEW_OPTION_FLAG]: true,
              },
              ...options,
            ];
          }
          return options;
        }}
      />
    );
  }

  private handleChange = (
    event: React.SyntheticEvent,
    nextSelected: AutocompleteValue<OptionType, false, false, true> | null,
    reason: AutocompleteChangeReason,
  ): void => {
    const { onChange, onClear } = this.props;

    if (reason === 'clear') {
      onClear();
    } else {
      const { inputValue } = this.state;

      if (typeof nextSelected === 'object') {
        if (nextSelected?.value !== inputValue) {
          this.setState({
            inputValue: nextSelected?.label || '',
          });
        }
      }
      onChange(nextSelected as OptionType);
    }
  };

  private fetchCompanySuggestionOptions(inputValue: string): Promise<OptionType[]> {
    return this.companySuggestionRepository.getSuggestions(inputValue)
      .then((companies: ICompanySuggestionSearchResponseDto[]) => {
        const options: OptionType[] = (companies || [])
          .map((company: ICompanySuggestionSearchResponseDto) => ({
            label: company.name,
            value: company.uri,
          }));

        return options;
      });
  }

  private handleInputChange = (
    event: React.SyntheticEvent,
    inputValue: string,
    reason: AutocompleteInputChangeReason,
  ): void => {
    const { onInputChange } = this.props;

    if (!event) {
      return;
    }

    if (!inputValue) {
      this.setState({
        inputValue,
        loading: false,
        options: [],
      });
      return;
    }

    if (reason !== 'clear' && reason !== 'reset') {
      this.setState({
        inputValue,
        loading: !!inputValue,
      });

      debounceAnonymous(() => {
        // Prevent unnecessary API calls after debounce
        if (inputValue !== this.state.inputValue) {
          return;
        }

        this.fetchCompanySuggestionOptions(inputValue)
          .then((companySuggestionOptions) => {
            // Prevent unnecessary state change after async operation
            if (inputValue !== this.state.inputValue) {
              return;
            }

            this.setState({
              options: [
                ...companySuggestionOptions,
              ],
              loading: false,
            });
          });
      });

      if (onInputChange) {
        onInputChange(inputValue);
      }
    }
  };
}

export const CompaniesAutocomplete = withTranslation()(CompaniesAutocompleteImpl);
