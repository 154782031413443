export interface IRecaptchaManager {
  init: () => void;
  execute: (action?: RecaptchaAction) => Promise<string | undefined>;
}

export enum RecaptchaAction {
  Test = 'Test',
  Checkout = 'Checkout',
  ContactRequestLead = 'ContactRequestLead',
  PhysicalOfferLead = 'PhysicalOfferLead',
}
