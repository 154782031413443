import type { IconProps } from '@mui/material';

export function createMaterialIconTheme(
  overrides?: Partial<IconProps>,
): Partial<IconProps> {
  return {
    color: 'primary',
    ...overrides,
  };
}
