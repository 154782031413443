class GlobalLoader {
  public start(): void {
    this.item?.add('visible');
  }

  public stop(): void {
    this.item?.remove('visible');
  }

  public toggle(): void {
    this.item?.toggle('visible');
  }

  public isVisible(): boolean {
    return !!this.item?.contains('visible');
  }

  private get item(): DOMTokenList | undefined {
    return document.getElementById('globalLoader')?.classList;
  }
}

export const globalLoader = new GlobalLoader();
