import { breakpointsValues } from '@esurance/material-theme';

import { useScreenWidth } from './useScreenSize';

export function useIsMobile(): boolean {
  const screenWidth = useScreenWidth();
  return screenWidth <= breakpointsValues.sm;
}

export function useIsTablet(): boolean {
  const screenWidth = useScreenWidth();
  return screenWidth > breakpointsValues.sm && screenWidth <= breakpointsValues.md;
}

export function useIsDesktop(): boolean {
  const screenWidth = useScreenWidth();
  return screenWidth > breakpointsValues.md;
}

export function useIsTabletOrLess(): boolean {
  const screenWidth = useScreenWidth();
  return screenWidth <= breakpointsValues.md;
}
