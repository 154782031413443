import type { CheckboxProps } from '@mui/material';
import React from 'react';

import { CheckboxIcon } from '../../components';

export function createMaterialCheckboxTheme(
  overrides?: Partial<CheckboxProps>,
): Partial<CheckboxProps> {
  return {
    color: 'primary',
    indeterminateIcon: (<CheckboxIcon indeterminate />),
    checkedIcon: (<CheckboxIcon checked />),
    icon: (<CheckboxIcon />),
    ...overrides,
  };
}
