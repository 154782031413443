import _ from 'lodash';
import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';
import type {
  IAffinityType, IDBConstant, IProductType, IProductTypeDto,
} from '@esurance/entities';

import { axios } from '../managers/axios-wrapper';

import type { IConstantRepository } from './interfaces';

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */
function mapProductTypes(response: IProductTypeDto[]): IProductType[] {
  return response
    .filter((item) => !_.isNull(item.type))
    .map((item) => {
      if (_.isNull(item.name)) {
        item.name = {
          de: '',
          en: '',
          fr: '',
          it: '',
        };
      }
      return {
        code: _.snakeCase(item.name.en.trim()),
        company: item.company,
        id: item.id,
        logo: item.logo_url,
        name: item.name,
        position: item.position,
        private: item.private,
        type: item.type,
      };
    });
}

function mapAffinityTypes(response: IAffinityType[]): IAffinityType[] {
  return response.map((item) => ({
    id: item.id,
    name: item.name,
  }));
}

@injectable()
export class ConstantRepository implements IConstantRepository {
  public data?: IDBConstant;

  public constructor(
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) { }

  public init(): Promise<void> {
    return this.load();
  }

  public getProductById(id: number): IProductType | undefined {
    if (this.data) {
      return _.find(this.data.productType, { id });
    }
    return undefined;
  }

  public getProductTypes(): IProductType[] | undefined {
    return this.data ? this.data.productType : undefined;
  }

  public getAffinityTypes(): IAffinityType[] | undefined {
    return this.data?.affinityType;
  }

  private load(): Promise<void> {
    return axios
      .all([
        axios.get(`${this.backofficeApiRoot}/product/types`),
        axios.get(`${this.backofficeApiRoot}/affinity_group/all`),
      ])
      .then(([productTypesResponse, affinityTypeResponse]: any) => {
        this.data = {
          productType: mapProductTypes(productTypesResponse.data || []),
          affinityType: mapAffinityTypes(affinityTypeResponse.data || []),
        };
      });
  }
}
