export { DependencyType } from './lib/ioc/DependencyType';
export { configureContainer } from './lib/ioc/configureContainer';
export {
  AutocompleteView,
} from './lib/components';
export {
  AbstractAutocomplete,
  CompanySuggestionAutocomplete,
} from './lib/models';
export type { IAutocompleteModel } from './lib/models/interfaces';
export type { ICompanySuggestionSearchResponseDto } from './lib/repositories';
export { CompanySuggestionRepository, getSuggestionsEndpointUrl } from './lib/repositories';
export type { ICompanySuggestionRepository } from './lib/repositories/interfaces';
export type { ICompanySuggestionData } from './lib/repositories/interfaces';
