import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { resolve } from 'inversify-react';
import DesktopDisabledIcon from '@mui/icons-material/DesktopAccessDisabledRounded';

import { ConstantDependency } from '@esurance/constants';
import { DependencyType, IHistoryManager } from '@esurance/services';

import './NotFoundPage.styl';

class NotFoundPage extends React.Component<WithTranslation> {
  @resolve(DependencyType.HistoryManager)
  private historyManager: IHistoryManager;

  @resolve(ConstantDependency.EsuranceUrl)

  private esuranceUrl: string;

  public handleClick = (): void => {
    this.historyManager.redirectTo(this.esuranceUrl);
  };

  public render(): JSX.Element {
    const { t: translate } = this.props;

    return (
      <>
        <div className="notfound-wrap">
          <p className="notfound-img-wrap">
            <DesktopDisabledIcon fontSize="large" color="primary" />
          </p>
          <p className="notfound-text">
            {translate('page-notfound-text') }
            <a href="http://esurance.ch/" className="notfound-link"> esurance.ch</a>
          </p>
          <p className="notfound-buttons-wrap">
            <button
              className="btn primary contact-us"
              type="button"
              onClick={this.handleClick}
            >
              {translate('contact-us')}
            </button>
          </p>
        </div>
      </>
    );
  }
}

export const NotFoundPageView = withTranslation()(NotFoundPage);
