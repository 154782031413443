import { isValidIBAN } from 'ibantools';

import type { IValidator } from '../interfaces';

export class Iban implements IValidator {
  constructor(public errorMessage: string = 'iban-invalid-message') {}

  public validate(value: string): boolean {
    return isValidIBAN(value.replace(/ /g, ''));
  }
}
