import _ from 'lodash';
import React from 'react';
import './money.styl';
/* eslint-disable no-dupe-class-members */
// TODO: refactor this "Formatter"
class Formater {
  public static myMethod(value: string | number): string;

  public static myMethod(value: string | number, html: boolean): JSX.Element;

  public static myMethod(
    value: string | number,
    html: boolean,
    prefix: string,
    precision?: number,
  ): string;

  public static myMethod(
    value: string | number,
    html?: boolean,
    prefix = 'CHF ',
    precision = 2,
  ): string | JSX.Element {
    const parts = (value || '0').toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    // precision
    if (parts.length === 1) {
      parts.push('');
    } else if (parts[1].length > precision) {
      const decimal = _.round(parseFloat(`0.${parts[1]}`), precision);
      // eslint-disable-next-line prefer-destructuring
      parts[1] = decimal.toString().split('.')[1];
    }
    parts[1] = _.padEnd(parts[1], precision, '0');
    const result = precision !== 0 ? parts.join('.') : parts[0];
    if (html) {
      return <span className="money-with-currency">{result}</span>;
    }
    return `${prefix}${result}`;
  }
}

export const moneyFormater = Formater.myMethod;
