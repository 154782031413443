import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { RadioGroup } from '@esurance/shared';
import type { IFormField } from '@esurance/domain-components';
import type { Salutation } from '@esurance/legacy-constants';
import { OwnerIcon } from '@esurance/domain-components';

export interface IRadioInputOption {
  value: string;
  title: string;
  icon?: Salutation;
}

interface IProps {
  label?: string;
  id: string;
  model: IFormField<string>;
  options: readonly IRadioInputOption[];
  className?: string;
}

@observer
export class RadioGroupInput extends React.Component<IProps> {
  private readonly model: IFormField<string>;

  constructor(props: IProps) {
    super(props);
    this.model = props.model;
  }

  public render(): JSX.Element {
    const {
      label,
      options,
      id,
      className,
    } = this.props;
    const { model } = this;

    const classes = classNames(className, {
      invalid: model.isInvalid,
      touched: model.isTouched,
    });

    const validationMessage: string | undefined = model.isTouched ? model.errorMessage : undefined;

    return (
      <RadioGroup
        id={id}
        className={classes}
        label={label}
        required
        inline
        onChange={(value) => model.change(value)}
        options={options}
        selected={model.value}
        rendererIcons={(value) => this.renderIcon(value)}
        validationMessage={validationMessage}
      />
    );
  }

  public renderIcon(value: string): JSX.Element | null {
    const { options } = this.props;

    const option = options.find((o) => o.value === value);
    if (!option || !option.icon) {
      return null;
    }
    return (
      <OwnerIcon
        type={option.icon}
        color="primary"
      />
    );
  }
}
