import { injectable } from 'inversify';

import type { FeatureConfig, IFeatureManager } from './interfaces';
import { FeatureName } from './interfaces';

const featurePaths = {
  [FeatureName.organizationManagement]: '/organization/',
  [FeatureName.contactManagement]: '/contact/',
  [FeatureName.insuranceCompanyManagement]: '/insurance/',
  [FeatureName.partnerManagement]: '/partner/',
  [FeatureName.policyManagement]: '/policy/',
  [FeatureName.activitiesManagement]: '/activity/',
  [FeatureName.settings]: '/settings/',
  [FeatureName.dashboardManagement]: '/dashboard/',
  [FeatureName.ordersManagement]: '/orders/',
};

@injectable()
export class FeatureManager implements IFeatureManager {
  private config: FeatureConfig = {};

  init(config: FeatureConfig): void {
    this.config = config;
  }

  public isEnabled(featureName: FeatureName): boolean {
    return !!this.config[featureName]?.enabled;
  }

  public getOptions<T extends object>(featureName: FeatureName): T {
    return (this.config[featureName]?.options || {}) as T;
  }

  private get features(): FeatureName[] {
    return Object.keys(this.config) as FeatureName[];
  }

  public get defaultPath(): string {
    const defaultFeatureName = this.features.find((feature) => (
      this.config[feature]
      && this.config[feature]?.defaultFeature
      && this.isEnabled(feature)
    ));

    if (defaultFeatureName) {
      return featurePaths[defaultFeatureName];
    }

    const availableFeatureName = this.features.find((feature) => this.isEnabled(feature));
    if (!availableFeatureName) {
      throw new Error('Can not define default path as no features are enabled');
    }
    return featurePaths[availableFeatureName];
  }
}
