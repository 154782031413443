import React from 'react';
import ReactCodeInput from 'react-code-input';
import _ from 'lodash';
import './code-input.styl';

const CODE_PATTERN = /^\d{6}$/;

interface IProps {
  type?: string;
  fields: number;
  value?: string;
  name?: string;
  className?: string;
  style?: object;
  inputStyle?: object;
  inputStyleInvalid?: object;
  isValid?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  touch?: (event: Event) => void;
  untouch?: (event: Event) => void;
}

export class CodeInput extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    inputStyle: {
      disable: '',
    },
    inputStyleInvalid: {
      disable: '',
    },
    type: 'number',
  };

  private textInput?: HTMLInputElement[];

  public componentWillUnmount(): void {
    this.removeCodeInputsListeners();
    this.textInput = undefined;
  }

  public UNSAFE_componentWillUpdate(nextProps: Readonly<IProps>): void {
    if (nextProps.value === '') {
      this.setCodeInputValue('');
    }
  }

  public registerCodeInputsListeners = (): void => {
    (this.textInput || []).forEach((inputElement: HTMLInputElement, index: number) => {
      if (inputElement) {
        inputElement.addEventListener('paste', this.handleCodePaste.bind(this));
        inputElement.addEventListener(
          'focus',
          this.handleFocusEvent.bind(this, index),
        );
        inputElement.addEventListener('keydown', this.handleKeyDown);
      }
    });
  };

  public removeCodeInputsListeners = (): void => {
    (this.textInput || []).forEach((inputElement: HTMLInputElement, index: number) => {
      if (inputElement) {
        inputElement.removeEventListener(
          'paste',
          this.handleCodePaste.bind(this),
        );
        inputElement.removeEventListener(
          'focus',
          this.handleFocusEvent.bind(this, index),
        );
        inputElement.removeEventListener('keydown', this.handleKeyDown);
      }
    });
  };

  public render(): JSX.Element {
    return <ReactCodeInput {...this.props} ref={this.handleCodeInputRef} />;
  }

  private setCodeInputValue = (value: string): void => {
    (this.textInput || []).forEach((input, index) => {
      // eslint-disable-next-line no-param-reassign
      input.value = value[index];

      if (value.length - 1 === index) {
        input.focus();
      }
    });
  };

  private handleCodeInputRef = (elem: ReactCodeInput): void => {
    if (this.textInput || _.isNull(elem)) {
      return;
    }

    this.textInput = elem.textInput;
    this.registerCodeInputsListeners();
  };

  private handleCodePaste = (e: ClipboardEvent): void => {
    e.preventDefault();
    const { onChange } = this.props;

    if (e.clipboardData) {
      const code = _.trim(e.clipboardData.getData('text/plain'));

      if (CODE_PATTERN.test(code) && this.textInput) {
        this.setCodeInputValue(code);
        if (onChange) {
          onChange(code);
        }
      }
    }
  };

  private handleFocusEvent = (index: number, e: any): void => {
    if (index > 0 && this.textInput) {
      const prevInput = this.textInput[index - 1];

      if (_.isEmpty(prevInput.value)) {
        prevInput.focus();
      }
    }

    return e;
  };

  private handleKeyDown = (e: any): void => {
    const keyCodes = [38, 40, 37, 39];

    if (keyCodes.includes(e.keyCode)) {
      e.preventDefault();
    }
  };
}
