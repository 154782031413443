import './TranslationWithTags.styl';

import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { resolve } from 'inversify-react';
import classNames from 'classnames';
import type { StringMap, TOptions } from 'i18next';

import { DependencyType } from '../../ioc/DependencyType';

import type { TagParser } from './types';
import { parseTranslation } from './parser';

interface IProps {
  translationKey: string;
  options?: string | TOptions<StringMap>;
  repetitionIndex?: number;
  className?: string;
  wrapLess?: true;
}

class TranslationWithTagsRaw extends React.Component<IProps & WithTranslation> {
  @resolve(DependencyType.TranslationTagParsers)
  private tagParsers: TagParser[];

  public render(): React.ReactNode {
    const {
      t,
      translationKey,
      repetitionIndex,
      className,
      options,
      wrapLess = false,
    } = this.props;
    const translation = (repetitionIndex != null)
      ? replaceRepetitionIndex(t(translationKey), repetitionIndex)
      : t(translationKey, options || translationKey);

    const children = React.Children.toArray(parseTranslation(translation, this.tagParsers));

    if (wrapLess) {
      return children;
    }

    const classes = classNames('translation-with-tags', className);

    return (
      <div className={classes}>
        {children}
      </div>
    );
  }
}

function replaceRepetitionIndex(text: string, index: number): string {
  const questionNumberPattern = /\${index}/g;
  return text.replace(questionNumberPattern, (index + 1).toString());
}

export const TranslationWithTags = withTranslation()(TranslationWithTagsRaw);
