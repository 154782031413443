import { EventEmitter } from 'events';

import { injectable } from 'inversify';

import type { EventName, IEventManager, IEventToHandlerMap } from './interfaces';

@injectable()
export class EventManager implements IEventManager {
  private static readonly MAX_EVENT_LISTENERS = 20;

  private eventEmitter: EventEmitter = new EventEmitter();

  public constructor() {
    this.eventEmitter.setMaxListeners(EventManager.MAX_EVENT_LISTENERS);
  }

  public emit(name: EventName, ...args: any[]): boolean {
    return this.eventEmitter.emit(name, ...args);
  }

  public on<T extends keyof IEventToHandlerMap>(
    event: T,
    handler: IEventToHandlerMap[T],
  ): void {
    this.eventEmitter.on(event, handler);
  }

  public removeListener<T extends keyof IEventToHandlerMap>(
    event: T,
    handler: IEventToHandlerMap[T],
  ): void {
    this.eventEmitter.removeListener(event, handler);
  }
}
