import React from 'react';

import './AlertDialog.styl';

import { Modal } from '../Modal/Modal';

interface IAlertDialogProps {
  loading?: boolean;
  setCustomCloseModal?: () => void
}

export function AlertDialog(props: React.PropsWithChildren<IAlertDialogProps>): JSX.Element {
  const {
    loading,
    children,
    setCustomCloseModal,
  } = props;

  return (
    <Modal
      isClosable={false}
      loading={loading}
      setCustomCloseModal={setCustomCloseModal}
    >
      {children}
    </Modal>
  );
}
