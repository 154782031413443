import type { ReactNode } from 'react';
import React from 'react';

import type { SurveyQuestionType } from '@esurance/entities';

interface IProps {
  title: string;
  children: ReactNode;
}

export interface IBaseProps {
  item: SurveyQuestionType;
  onChange: (item: SurveyQuestionType, changes: any) => void;
  repeaterAnswer?: string | number;
}

class BaseQuestion extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { title, children } = this.props;
    return (
      <div className="question-inner">
        <h3>{title}</h3>

        {children}
      </div>
    );
  }
}

export default BaseQuestion;
