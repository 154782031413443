import { action, computed, makeObservable } from 'mobx';

import type { IForm, IFormField } from './interfaces';
import { FORM_FIELD_MARKER, FORM_MARKER } from './interfaces';

export abstract class Form<DTO> implements IForm {
  protected constructor() {
    makeObservable(
      this,
      {
        save: action,
        reset: action,
        getFormData: action,
        getFieldByName: action,

        isInvalid: computed,
        isTouched: computed,
        isChanged: computed,
      },
    );
  }

  public get isInvalid(): boolean {
    for (const [, f] of this.getFields()) {
      if (f.isInvalid) {
        return true;
      }
    }
    return false;
  }

  public get isTouched(): boolean {
    for (const [, f] of this.getFields()) {
      if (f.isTouched) {
        return true;
      }
    }
    return false;
  }

  public get isChanged(): boolean {
    for (const [, f] of this.getFields()) {
      if (f.isChanged) {
        return true;
      }
    }
    return false;
  }

  public async save(onSuccess?: () => void, onError?: () => void): Promise<void> {
    for (const [, f] of this.getFields()) {
      f.touch();
    }
    if (this.isInvalid) {
      return;
    }

    await this.doSave(this.getFormData())
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        if (onError) onError();
      });
  }

  public reset = (): void => {
    for (const [, field] of this.getFields()) {
      field.reset();
    }
  };

  protected abstract doSave(
    data: DTO,
    callback?: () => void,
  ): Promise<void>;

  public getFormData(): DTO {
    const result: { [key: string]: string } = {};
    for (const [dataKey, field] of this.getFields()) {
      result[dataKey] = field.value;
    }
    // TODO: need to review how we can get rid of this cast
    return result as any;
  }

  public* getFields(): IterableIterator<[string, IFormField<string>]> {
    for (const [key, field] of Object.entries(this)) {
      if (field != null && field[FORM_MARKER] === true) {
        yield* field.getFields();
      }
      if (field != null && field[FORM_FIELD_MARKER] === true) {
        yield [key, field as IFormField<string>];
      }
    }
  }

  public getFieldByName = (name: string): IFormField<string> | null => {
    for (const [dataKey, field] of this.getFields()) {
      if (name === dataKey) return field;
    }
    return null;
  };
}
