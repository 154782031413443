import type { MouseEvent } from 'react';
import React from 'react';
import DownloadIcon from '@mui/icons-material/DownloadRounded';

import { _t } from '../../../service';

interface IProps {
  files: any[];
}

export function Attachements({ files }: IProps): JSX.Element | null {
  if (files.length) {
    return (
      <div className="attachments">
        <DownloadIcon color="primary" />
        <div className="caption">
          {_t('attached-files-caption')}
          :
        </div>

        <div className="files">
          {files.map((file) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={file.id}
              className="attachment"
              onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
            >
              <a className="title" href={file.url} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
