import DOMPurify from 'dompurify';

export function sanitizeTranslationTag(tagName: string, element: Element): Element {
  const options: DOMPurify.Config = {
    ADD_TAGS: [tagName],
    RETURN_DOM: true, // returns HTMLElement type
  };
  const sanitizedElement = DOMPurify.sanitize(element, options) as HTMLElement;

  return sanitizedElement.getElementsByTagName(tagName)[0];
}
