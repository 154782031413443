/* eslint-disable */
/// <reference path="../../../../@types/app.d.ts"/>

export const AFFINITY_LANGUAGES = ["de", "fr", "it"];
export const CLEAR_CART_COOKIE_NAME = "CLEAR_CART";
// Temporarily! Until translations in other languages are added
// export const INA_LANGUAGES = ["de", "fr", "it", "en"];
export const CLEAR_CART_COOKIE_VALUE = "true";

// TODO: AD: VALIDATOR
// if (location.search.includes("lng=en")) {
//   AFFINITY_LANGUAGES.push("en");
// }

export const LANGUAGES: Readonly<string[]> = ["de", "en", "fr", "it"] as const;

export const SIZE_15_MB = 15728640;

export enum Order {
  ASC = "asc",
  DESC = "desc",
}

export enum DateFormat {
  FRONTEND_DATE = "DD.MM.YYYY",
}

export enum Colors {
  Green = "#00a58d",
  Blue = "#154576",
  LightBlue = "#33bbea",
  YellowGreen = "#bdd84d",
  Orange = "#f68e51",
  Yellow = "#fee05e",
  Purple = "#b995c5",
  Pink = "#e83c68",
  LightBlack = "#333333",
}

export enum Gender {
  Male = "male",
  Female = "female",
}

export enum Salutation {
  Male = "sir",
  Female = "mrs",
  Company = "company",
  Team = "team",
}

// Separate because of EventName values are numbers and here needed a string
export enum HealthEventName {
  UserRegistered = "user_registered",
  SurveyPassed = "survey_passed",
  HealthQuestionActivityRead = "health_question_activity_read",
  HealthQuestionDocumentDownloaded = "health_question_document_downloaded",
}

export enum ClaimType {
  Normal = 0,
  Emergency = 1,
}

export enum DocumentType {
  Original = "policy",
  Other = "policy_other",
}

export enum AlertTypes {
  Success = "success",
  Error = "error",
  Info = "info",
  Secondary = "secondary",
  Transparent = "transparent",
}

export enum BadgeType {
  Default = "default",
  Green = "green",
  Purple = "purple",
}

export enum ActivityTypes {
  FollowUp = "follow-up",
  Meeting = "meeting",
  Email = "email",
  Task = "task",
  Mail = "mail",
  Call = "call",
  Notification = "notification",
  Request = "request",
}

export enum ActivityWidgetType {
  Insurance = "insurance",
  Contact = "contact",
  Organization = "organization",
}

export type SomeAdminActivityType =
  | ActivityTypes.Task
  | ActivityTypes.Email
  | ActivityTypes.Mail
  | ActivityTypes.Call
  | ActivityTypes.Meeting
  | ActivityTypes.Notification
  | ActivityTypes.Request;

export interface ISelectOptions {
  value: string;
  title: string;
}

export enum ActivitySubTypes {
  PolicyInactivation = "policy_inactivation",
  AcceptedTerms = "accepted_terms",
  Optimization = "optimization_request",
  Register = "register",
  Claim = "claim",
  AddPolicy = "add_policy",
  NewInsuranceRequest = "new_insurance_request",
  SignedMandate = "mandate_signed",
  SignMandate = "sign_mandate",
  ProcessPolicies = "process_policy",
  SendMandateByPost = "send_mandate_by_mail",
  SurveyPassed = "survey_passed",
  SurveyNotPassed = "survey_not_passed",
  ChangePassword = "change_password",
  PolicyStatusChanged = "policy_status_changed",
  ProductBought = "product_bought",
  ProductDeclined = "product_declined",
  AddressChanged = "address_changed",
  ProductMembership = "product_membership",
  HealthAnswers = "health_answers",
  StandaloneSurveyCreated = "standalone_survey_created",
  StandaloneSurveyContactFinished = "standalone_survey_contact_finished",
  StandaloneSurveyFullyFinished = "standalone_survey_fully_finished",
  StandaloneSurveyExpired = "standalone_survey_expired",
  StandaloneSurveyContactReminder = "standalone_survey_contact_reminder",
  GastroSocialDone = "gastro_social_done",
}

export enum FormInputType {
  Checkbox = "checkbox",
  Int = "int",
  Money = "money",
  MultiSelect = "multi_select",
  Select = "select",
  Switch = "switch",
  Zip = "zip",
}

export enum ActivityStatusClass {
  NotDone = 0,
  Done = 1,
  InProgress = 2,
}

export enum ActionStatus {
  Free = "free",
  Pay = "pay",
  NotAvailable = "not-available",
}

export enum ICON_TYPE_KEYS {
  "activities",
  "add-btn",
  "add-claim",
  "airplane",
  "attention",
  "bell-filled",
  "bell",
  "blocks",
  "calendar",
  "cancel-signboard-filled",
  "cancel-signboard",
  "cancel",
  "car",
  "cart",
  "chat",
  "check-square",
  "check",
  "check-mark",
  "checkbox-checked",
  "checkbox-not-checked",
  "checklist",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "clip",
  "close",
  "company",
  "compare",
  "content-copy",
  "crutch",
  "cube",
  "cyber-security",
  "delete",
  "doc",
  "download",
  "dropdown",
  "dropdown-arrow",
  "edit",
  "email",
  "eye-hide",
  "eye-show",
  "eye",
  "female",
  "file-icon",
  "file",
  "furniture-filled",
  "furniture",
  "gear",
  "home-filled",
  "health-filled",
  "health-round",
  "health",
  "hold-phone",
  "home",
  "household",
  "increase-deadline",
  "insurance-company",
  "partner-company",
  "legal-hummer",
  "legal",
  "liability",
  "locker",
  "login-as-user",
  "mail",
  "male",
  "manager",
  "menu",
  "mietkaution-filled",
  "mietkaution",
  "mobile-phone",
  "mobile-sign",
  "mouse",
  "new-insurance",
  "paper-plane",
  "pdf",
  "pen",
  "people-o",
  "people",
  "permission",
  "person",
  "person-o",
  "phone-o",
  "phone",
  "picture",
  "pills-o",
  "pills",
  "post",
  "print",
  "question",
  "radiogroup",
  "rent",
  "request-policy",
  "reset",
  "retirement-filled",
  "retirement",
  "sandtime",
  "search",
  "selected-round",
  "star-o",
  "star",
  "system",
  "team",
  "text",
  "thief",
  "time",
  "tip",
  "transparent", // no background image
  "umbrella",
  "upload",
  "warning",
  "briefcase",
  "cart-empty",
  "download-filled",
  "invalid-answer-checkbox",
  "valid-answer-checkbox",
  "leg-bandage-o",
  "leg-bandage",
  "eye-slash",
  "cross-mark",
  "arrow-down",
  "add-comment",
  "partners",
  "communication",
  "distribution",
  "hard-delete",
  "video-call",
  "dashboard",
  "bookmark",
  "screen",
  "envelope",
  "phone-one",
  "phone-two",
  "check-thin",
  "time-o",
  "chat-affinity",
  "support"
}

export type MuiIconColor = 'primary' | 'secondary' | 'default' | IconColor;

/**
 * @deprecated
 */
export type IconColor =
  | "primary"
  | "secondary"
  | "blue"
  | "red"
  | "strong-red"
  | "warm-grey"
  | "lime-green"
  | "green"
  | "white"
  | "dark-grey-blue"
  | "faded-orange"
  | "light-black"
  | "black";

export type MuiIconSize = 'small' | 'medium';

export type IconSize = "xxs" | "xs" | "ss" | "s" | "m" | "l" | "xl" | "xxl";

/**
 * @deprecated
 */
export type IconType = keyof typeof ICON_TYPE_KEYS;

export enum ActionName {
  Meeting = "meeting",
  NewInsurance = "new_insurance",
  Claim = "claim",
  Survey = "survey",
}

export enum QuestionTypes {
  Options = "options",
  Input = "input",
  OptionsInput = "options-input",
  PeopleNameQuestion = "people-name-question",
  PeopleQuestion = "people-question",
  PeopleOption = "people-option",
}

export enum FlowTypes {
  Question = "question",
  QuestionGroup = "question-group",
}

export enum ProductTypes {
  Haushalt = "Haushalt",
  Pensionskasse = "Pensionskasse",
  PensionFund = "PensionFund",
  Haftpflicht = "Haftpflicht",
  Inventar = "Inventar",
  Betriebsausfall = "BetriebsAusfall",
  Gebäude = "Gebäude",
  Unfall = "Unfall",
  UnfallZ = "UVG-Z",
  Rechtsschutz = "Rechtsschutz",
  Mietkaution = "Mietkaution",
  Member = "Noch kein Mitglied?",
  Krankentaggeld = "Krankentaggeld",
  Erwerbsunfähigkeit = "Erwerbsunfähigkeit",
  Disability = "Disability",
  Krankenkasse = "Krankenkasse",
  RetirementAccount = "RetirementAccount",
  PensionKasse = "PensionKasse",
  AusgleichsKasse = "Ausgleichskasse",
  AusgleichsKasseSelfEmployed = "AusgleichsKasseSelfEmployed",
}

export enum AdditionalSurveySectionTypes {
  HealthIssuesForMajorityOwner = "HealthIssuesForMajorityOwner",
  AusgleichsKasseSelfEmployd = "AusgleichsKasseSelfEmployd",
}

export type ProductSurveySectionTypes = ProductTypes | AdditionalSurveySectionTypes;

export type ActionIconMapType = { [k in ActionName]: IconType };

export const ActionIconMap = {
  [ActionName.Meeting]: "people",
  [ActionName.Claim]: "add-claim",
  [ActionName.Survey]: "checklist",
  [ActionName.NewInsurance]: "new-insurance",
} as ActionIconMapType;

export type ProductTypeIconMapType = { [k in ProductTypes]: IconType };

export const ProductTypeIconMap: { [key: string]: IconType } = {
  [ProductTypes.Haushalt]: "people-o",
  [ProductTypes.Pensionskasse]: "retirement",
  [ProductTypes.PensionFund]: "retirement",
  [ProductTypes.Haftpflicht]: "liability",
  [ProductTypes.Betriebsausfall]: "cancel-signboard",
  [ProductTypes.Gebäude]: "home",
  [ProductTypes.Unfall]: "leg-bandage-o",
  [ProductTypes.UnfallZ]: "leg-bandage-o",
  [ProductTypes.Krankentaggeld]: "pills-o",
  [ProductTypes.Rechtsschutz]: "legal",
  [ProductTypes.Mietkaution]: "rent",
  [ProductTypes.Member]: "people-o",
  [ProductTypes.Inventar]: "furniture",
  [ProductTypes.Erwerbsunfähigkeit]: "crutch",
  [ProductTypes.Disability]: "crutch",
  [ProductTypes.Krankenkasse]: "health",
  [ProductTypes.RetirementAccount]: "insurance-company",
} as ProductTypeIconMapType;

export enum QuestionnaireQuestionType {
  Hidden = "hidden",
  Text = "text",
  Number = "number",
  Option = "option",
  Select = "select",
  Email = "email",
  Phone = "phone",
  Group = "group",
  Date = "date",
  Password = "password",
  Company = "company",
}

export enum ConditionType {
  Contains = "contains",
  Equals = "equals",
  In = "in",
  Any = "any",
  BMI = "bmi",
}

export enum ComparisonType {
  Or = "or",
  And = "and",
}

export enum ValidationType {
  MinMax = "min_max",
}

export enum FUNNEL_IDS {
  loginSignUpLink = "js-funnel--login_sign-up-link",
  signupTermsSubmit = "js-funnel--signup_submit",
  termsAgreeLink = "js-funnel--login_terms_agree-link",
  taskSubtypeAddPolicy = "js-funnel--activity_task_add-continue",
  singMandateThroughMouse = "js-funnel--sing-mandate_through-mouse_apply-button",
  singMandateOnMobileReload = "js-funnel--sing-mandate_sign-on-mobile_reload-button",
  singMandateThroughPost = "js-funnel--sing-mandate_through-post_button",
  singMandateThroughPrint = "js-funnel--sing-mandate_through-print_third-step_submit-button",
  signMandateButton = "js-funnel--sign-mandate-button",
  policyListPanelAddButton = "js-funnel--policy-list_panel-add-button",
  policyAddSubmitButton = "js-funnel--policy-add_submit-button",
  smsNext = "js-funnel--sms_next-button",
}

export enum GASTRO_FUNNEL_IDS {
  CalculationWidgetCheckoutButton = "js-funnel--calculation-widget_checkout-button",
  BasketSubmitButton = "js-funnel--basket_submit-btn",
  CartModalSubmitButton = "js-funnel--cart-modal_submit-button",
}

export enum PolicyAnalyzeStatus {
  Active = "active",
  Draft = "draft",
}

export enum TypeOfGastroConsultantContact {
  email = "email",
  mobile = "mobile_number",
  phone = "phone_number",
}


export enum LoaderPosition {
  Fixed = "fixed",
  Absolute = "absolute",
}

export enum AffinityGroup {
  IT = "It",
  Gastosuisse = "Gastro",
  Ina = "Ina",
  Integra = "Integra",
}

export enum AffinityGroupUrl {
  IT = "it",
  Gastosuisse = "gastrosuisse",
}

export enum AffinityLabelId {
  Gastosuisse = 2,
  IT = 3,
  Integra = 6
}

export const SWISS_COUNTRY_CODE = "CH";
