import React from 'react';

interface IProps {
  title: React.ReactNode;
}

export function TimelineGroup({
  title,
  children,
}: React.PropsWithChildren<IProps>): JSX.Element {
  return (
    <div className="timeline-group">
      <div className="timeline-group-divider">
        <div className="timeline-group-title">{title}</div>
      </div>

      <div className="timeline-group-list">{children}</div>
    </div>
  );
}
