import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type FormLabelStyles = ComponentsOverrides['MuiFormLabel'];

export function createMuiFormLabelStyles(
  overrides?: Partial<FormLabelStyles>,
): Partial<FormLabelStyles> {
  return {
    root: {
      fontSize: '16px',
      ...(overrides?.root as object),
    },
    ...overrides,
  };
}
