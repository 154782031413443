import type { Container } from 'inversify';

import type { IEnvironmentConfig } from '@esurance/default-config';

import { ConstantDependency } from './ConstantDependency';
import type { IApplicationConfigRepository } from './repositories/interfaces';
import { ApplicationConfigRepository } from './repositories';
import type { IApplicationConfig } from './models';

const DEFAULT_LOCALSTORAGE_PREFIX = '';
const DEFAULT_RECAPTCHA_SITE_KEY = '';
const DEFAULT_SENTRY_PUBLIC_DSN = '';

export function initEnvironmentConstants(
  inversifyContainer: Container,
  config: IEnvironmentConfig,
): void {
  inversifyContainer.bind<string>(ConstantDependency.AffinityApiRoot)
    .toConstantValue(config.AffinityApiRoot);

  inversifyContainer.bind<string>(ConstantDependency.AffinityV3ApiRoot)
    .toConstantValue(config.AffinityV3ApiRoot);

  inversifyContainer.bind<string>(ConstantDependency.BackofficeApiRoot)
    .toConstantValue(config.BackofficeApiRoot);

  inversifyContainer.bind<string>(ConstantDependency.BackofficeV3ApiRoot)
    .toConstantValue(config.BackofficeV3ApiRoot);

  inversifyContainer.bind<string>(ConstantDependency.ProductEngineApiRoot)
    .toConstantValue(config.ProductEngineApiRoot);

  inversifyContainer.bind<string>(ConstantDependency.LocalStoragePrefix)
    .toConstantValue(config.LocalStoragePrefix || DEFAULT_LOCALSTORAGE_PREFIX);

  inversifyContainer.bind<string>(ConstantDependency.EsuranceUrl)
    .toConstantValue(config.EsuranceUrl);

  inversifyContainer.bind<string>(ConstantDependency.SwicaUrl)
    .toConstantValue(config.SwicaUrl);

  inversifyContainer.bind<string>(ConstantDependency.CockpitUrl)
    .toConstantValue(config.CockpitUrl);

  inversifyContainer.bind<string>(ConstantDependency.GastroSocialUrl)
    .toConstantValue(config.GastroSocialUrl);

  inversifyContainer.bind<string>(ConstantDependency.GastronomieUrl)
    .toConstantValue(config.GastronomieUrl);

  inversifyContainer.bind<string | undefined>(ConstantDependency.GastroShopAbsoluteUrl)
    .toConstantValue(config.GastroShopAbsoluteUrl);
}

export function initApplicationConstants(
  inversifyContainer: Container,
  applicationConfig: IApplicationConfig,
): void {
  inversifyContainer.bind<string>(ConstantDependency.RecaptchaSiteKey)
    .toConstantValue(applicationConfig.recaptchaSiteKey || DEFAULT_RECAPTCHA_SITE_KEY);

  inversifyContainer.bind<string>(ConstantDependency.SentryPublicDsn)
    .toConstantValue(applicationConfig.sentryPublicDsn || DEFAULT_SENTRY_PUBLIC_DSN);

  inversifyContainer.bind<string>(ConstantDependency.KeycloakUrl)
    .toConstantValue(applicationConfig.keycloakUrl);

  inversifyContainer.bind<string>(ConstantDependency.KeycloakRealm)
    .toConstantValue(applicationConfig.keycloakRealm);

  inversifyContainer.bind<string>(ConstantDependency.KeycloakClientId)
    .toConstantValue(applicationConfig.keycloakClientId);
}

export async function initConstants(
  inversifyContainer: Container,
  config: IEnvironmentConfig,
  applicationConfigRepository: IApplicationConfigRepository = new ApplicationConfigRepository(
    config.ApplicationConfigUrl,
  ),
): Promise<void> {
  initEnvironmentConstants(inversifyContainer, config);

  const applicationConfig = await applicationConfigRepository.get();
  initApplicationConstants(inversifyContainer, applicationConfig);
}
