import React from 'react';
import type { RadioProps } from '@mui/material';
import { Radio as MUIRadio } from '@mui/material';

interface IProps {
  defaultChecked?: boolean;
  checked?: boolean;
  required?: boolean;
  name?: string;
  id?: string;
  value: string;
  readOnly?: boolean;
  onChange?: (value: string, checked: boolean) => void;
}

export class Radio extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      name,
      id: radioId,
      readOnly,
      checked,
      defaultChecked,
      required,
      value,
    } = this.props;
    const id = radioId || `${name}radiobutton`;

    const props: Partial<RadioProps> = {
      id,
      name,
      required,
      value,
      readOnly,
    };

    if (Object.prototype.hasOwnProperty.call(this.props, 'checked')) {
      props.checked = checked;
    } else {
      props.checked = defaultChecked;
    }

    return (
      <MUIRadio onChange={(e) => this.handleOptionChange(e)} {...props} />
    );
  }

  private handleOptionChange = (
    event: React.SyntheticEvent<HTMLInputElement>,
  ): void => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange(value, event.currentTarget.checked);
    }
  };

  private onImgClick = (): void => {
    const { onChange, value, checked } = this.props;
    if (onChange) {
      onChange(value, !checked);
    }
  };
}
