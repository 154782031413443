import _ from 'lodash';
import React from 'react';

import { _t } from '../../service';

interface IProps {
  value?: number;
  name: string;
  id?: string;
  label?: string;
  readOnly?: boolean;
  onChange?: (value: number, checked: boolean) => void;
}

interface IState {
  selected: number | null;
}

interface ILable {
  title: string;
  rate: number;
}

export class Rating extends React.Component<IProps, IState> {
  public state: IState = {
    selected: null,
  };

  public componentDidMount(): void {
    const { onChange, value = 0 } = this.props;

    if (!onChange) {
      this.setState({
        selected: value > 0 ? Math.round(value * 10) / 10 : 0,
      });
    }
  }

  public render(): JSX.Element {
    const { label, readOnly } = this.props;
    return (
      <fieldset className="rating">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label>
          {label || _t('product_details-general_info-rating-label')}
        </label>
        <div className="star-wrapper">
          {readOnly ? this.renderStars() : this.renderInputs()}
        </div>
      </fieldset>
    );
  }

  private renderStars = (): JSX.Element => {
    const { value } = this.props;
    return (
      <div className="view">
        <div
          className="value"
          style={{ width: `${this.renderStarProgress()}px` }}
          title={`${value}`}
        />
      </div>
    );
  };

  // TODO: Refactore this
  private renderStarProgress = (): number => {
    const { value = 0 } = this.props;
    const rating = value * 10;

    switch (rating > 0) {
    case rating === 50:
      return 140; // 5 stars
    case rating >= 45 && rating < 50:
      return 130; // 4,5 stars
    case rating >= 40:
      return 110; // 4 stars
    case rating >= 35:
      return 100; // 3,5 stars
    case rating >= 30:
      return 90; // 3 stars
    case rating >= 25:
      return 70; // 2,5 stars
    case rating >= 20:
      return 60; // 2 stars
    case rating >= 15:
      return 40; // 1,5 stars
    case rating >= 10:
      return 20; // One star
    case rating >= 5:
      return 10; // A half star
    default:
      return 0;
    }
  };

  private renderInputs = (): JSX.Element[] => {
    const { name, id: ratingId } = this.props;
    const id = ratingId || `${name}rating`;

    const labels: ILable[] = [
      {
        rate: 5,
        title: _t('product_details-general_info-rating-very_good'),
      },
      {
        rate: 4,
        title: _t('product_details-general_info-rating-good'),
      },
      {
        rate: 3,
        title: _t('product_details-general_info-rating-normal'),
      },
      {
        rate: 2,
        title: _t('product_details-general_info-rating-bad'),
      },
      {
        rate: 1,
        title: _t('product_details-general_info-rating-very_bad'),
      },
    ];

    return _.map(labels, (label: ILable, n: number) => (
      <React.Fragment key={label.rate}>
        <input
          type="radio"
          name={name}
          id={`${id}-${n + 1}`}
          value={label.rate}
          onClick={() => this.handleChangeRating(label.rate)}
          {...this.checkedProp(label.rate)}
        />
        <label
          title={label.title}
          htmlFor={`${id}-${n + 1}`}
          aria-label={label.title}
        />
      </React.Fragment>
    ));
  };

  private checkedProp = (value: number): { checked: boolean } => {
    const { selected } = this.state;
    return { checked: selected === value };
  };

  private handleChangeRating = (value: number): void => {
    this.setState({ selected: value });
  };
}
