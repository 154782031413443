import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type InputBaseStyles = ComponentsOverrides['MuiInputBase'];

export function createMuiInputBaseStyles(
  overrides?: Partial<InputBaseStyles>,
): Partial<InputBaseStyles> {
  return {
    root: {
      fontSize: '16px',
      ...(overrides?.root as object),
    },
    input: {
      fontSize: '16px',
      ...(overrides?.input as object),
    },
    ...overrides,
  };
}
