import React from 'react';
import classesNames from 'classnames';
import { styled, Typography } from '@mui/material';

import { TranslationWithTags } from '@esurance/ui-components';

interface IProps {
  description: string;
  className?: string;
}

const TermsDescriptionTextWrapper = styled(Typography)((styles) => ({
  color: styles.theme.palette.text.tertiary,
  '& a': {
    color: styles.theme.palette.text.tertiary,
  },
}));

export class TermsDescription extends React.Component<IProps> {
  public render(): JSX.Element {
    const {
      description,
      className,
    } = this.props;

    const rootClasses = classesNames(className, 'terms-content');

    return (
      <div className={rootClasses}>
        <TermsDescriptionTextWrapper
          className="description"
        >
          <TranslationWithTags translationKey={description} />
        </TermsDescriptionTextWrapper>
      </div>
    );
  }
}
