import { EventEmitter } from 'events';

import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import i18next from 'i18next';

import { isNewCustomerFlowData, isUpsellingFlowData } from '../utils';
import { DependencyType } from '../ioc/DependencyType';
import type {
  ISessionContactData,
  ISessionData,
  ISessionIdentityData,
  SessionFlowData,
} from '../repositories/interfaces';
import { IAuthRepository } from '../repositories/interfaces';

import type { IAuthEventHandlerMap, IAuthManager } from './interfaces';
import { AuthEvent, IKeycloakManager, KeycloakAction } from './interfaces';

@injectable()
export class AuthManager implements IAuthManager {
  private eventEmitter: EventEmitter = new EventEmitter();

  private readonly requestMinValiditySeconds = 120;

  public constructor(
    @inject(DependencyType.KeycloakManager)
    private keycloakManager: IKeycloakManager,
    @inject(DependencyType.AuthRepository)
    private authRepository: IAuthRepository,
  ) {
    makeAutoObservable(this);
    // TODO: Keycloak: remove after migration to http-only cookie
    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      if (this.isAuthenticated) {
        await this.keycloakManager.updateToken(this.requestMinValiditySeconds);

        if (!config.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {};
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${this.keycloakManager.token}`;
      }

      return config;
    });

    this.keycloakManager.on('ready', () => this.init());
  }

  public get isAuthenticated(): boolean {
    return this.keycloakManager.isAuthenticated;
  }

  public get isRegistrationCompleted(): boolean {
    return !!this.contact;
  }

  public isInitialized = false;

  private onInit() {
    this.isInitialized = true;
  }

  public async init(): Promise<void> {
    if (this.keycloakManager.isAuthenticated) {
      await this.loadLoginData();
    }
    this.onInit();
  }

  public async login(redirectUri?: string): Promise<void> {
    if (this.keycloakManager.isAuthenticated) {
      throw Error('User is already logged in, can\'t login again');
    }
    // Next line redirects browser to auth.esurance.ch and after login
    // user will be redirected back and the whole app reloads
    // so this.init() will be called again and initialize login data
    const locale = i18next.language;
    await this.keycloakManager.login(redirectUri, locale);
  }

  public async logout(): Promise<void> {
    await this.keycloakManager.logout();
    this.eventEmitter.emit(AuthEvent.Logout);
  }

  public async refreshSessionData(): Promise<void> {
    await this.keycloakManager.updateToken();
    this.sessionData = await this.authRepository.getSessionData();
    this.eventEmitter.emit(AuthEvent.SessionDataChanged);
  }

  public async impersonate(userIdentityId: string): Promise<void> {
    await this.authRepository.impersonate(userIdentityId);
  }

  public sessionData: ISessionData | null = null;

  private async loadLoginData(): Promise<void> {
    const { token } = this.keycloakManager;
    if (token === undefined) {
      throw Error('User must be authenticated before getting token payload');
    }

    try {
      this.sessionData = await this.authRepository.getSessionData();

      // await this.constantRepository.load(); // TODO: move into AuthEvent.Login handler

      // await this.socketManager.init(); // TODO: move into AuthEvent.Login handler

      const onLoginListeners = this.eventEmitter.listeners(AuthEvent.Login);
      await Promise.all(onLoginListeners.map((listener) => listener()));
    } catch (e) {
      this.eventEmitter.emit(AuthEvent.Error, e);
      throw e;
    }
  }

  public get userId(): number | never {
    if (!this.isAuthenticated || !this.isRegistrationCompleted || !this.contact) {
      throw Error('User is not authenticated or registration is not completed');
    }
    return this.contact.id;
  }

  public isPartnerContact(): boolean {
    return !!this.contact?.isLinkedToPartner;
  }

  public isUpsellingSession(): boolean {
    return isUpsellingFlowData(this.flow);
  }

  public get isNewCustomerUpsellingSession(): boolean {
    return isNewCustomerFlowData(this.flow);
  }

  public on<T extends keyof IAuthEventHandlerMap>(
    event: T, handler: IAuthEventHandlerMap[T],
  ): void {
    this.eventEmitter.on(event, handler);
  }

  public off<T extends keyof IAuthEventHandlerMap>(
    event: T, handler: IAuthEventHandlerMap[T],
  ): void {
    this.eventEmitter.off(event, handler);
  }

  public get contact(): ISessionContactData | undefined {
    return this.sessionData?.contact || undefined;
  }

  public get flow(): SessionFlowData | undefined {
    return this.sessionData?.flow || undefined;
  }

  public get identity(): ISessionIdentityData | undefined {
    return this.sessionData?.identity || undefined;
  }

  public redirectToChangePasswordPage(): void {
    return this.keycloakManager.loginAction({
      action: KeycloakAction.UpdatePassword,
      email: this.identity?.email,
    });
  }

  public get token(): string | undefined {
    return this.keycloakManager.token;
  }
}
