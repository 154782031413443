import { parsePhoneNumberFromString } from 'libphonenumber-js';

import type { IValidator } from '../interfaces';

export class Phone implements IValidator {
  constructor(public errorMessage: string = 'input_default-invalid-message') {}

  public validate(value: string): boolean {
    const regExp = /[a-zA-Z]/g;

    if (regExp.test(value)) {
      return false;
    }

    const phoneNumber = parsePhoneNumberFromString(
      value,
      'CH',
    );
    return Boolean(phoneNumber && phoneNumber.isValid());
  }
}
