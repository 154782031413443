// TODO: ESD-5926: this service requires refactoring.
//  Add to DI container, cover with tests
/* eslint-disable class-methods-use-this, no-console */

import type {
  CentrifugeError,
  CentrifugeJoinResponse,
  CentrifugeLeaveResponse,
  CentrifugeUnsubscribeResponse,
  ICentrifugeMessage,
  ICentrifugeSubscribeResponse,
} from '@esurance/entities';

import type { IEventManager } from './interfaces';
import { EventName } from './interfaces';

interface ISocketListener {
  error(message: CentrifugeError): void;
  join(message: CentrifugeJoinResponse): void;
  leave(message: CentrifugeLeaveResponse): void;
  message(message: ICentrifugeMessage): void;
  subscribe(message: ICentrifugeSubscribeResponse): void;
  unsubscribe(message: CentrifugeUnsubscribeResponse): void;
}

export class SocketListener implements ISocketListener {
  public constructor(private eventManager: IEventManager) {}

  public error(message: CentrifugeError): void {
    // TODO: use logger, sentry for example
    console.debug('sock', 'error', message.channel, message);
  }

  public join(message: CentrifugeJoinResponse): void {
    // TODO: use logger, sentry for example. Task: ESD-9838
    console.debug('sock', 'join', message.channel, message);
  }

  public leave(message: CentrifugeLeaveResponse): void {
    // TODO: use logger, sentry for example. Task: ESD-9838
    console.debug('sock', 'leave', message.channel, message);
  }

  public message = (message: ICentrifugeMessage): void => {
    // TODO: use logger, sentry for example. Task: ESD-9838
    console.debug('sock', 'message', message);
    if (message.data.event === 'mandateSigned') {
      this.eventManager.emit(EventName.SocketMandateSigned);
    }
  };

  public subscribe(message: ICentrifugeSubscribeResponse): void {
    // TODO: use logger, sentry for example. Task: ESD-9838
    console.debug('sock', 'subscribe', message.channel, message);
  }

  public unsubscribe(message: CentrifugeUnsubscribeResponse): void {
    // TODO: use logger, sentry for example. Task: ESD-9838
    console.debug('sock', 'unsubscribe', message.channel, message);
  }
}
