import type { FormHelperTextProps } from '@mui/material';

export function createMaterialFormHelperTextTheme(
  overrides?: Partial<FormHelperTextProps>,
): Partial<FormHelperTextProps> {
  return {
    style: {
      fontStyle: 'italic',
      fontSize: '12px',
    },
    ...overrides,
  };
}
