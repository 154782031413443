import './Banner.styl';

import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography } from '@mui/material';

interface IProps {
  children?: JSX.Element | Element;
  text?: string;
  bannerTitle?: string;
  highlightedText?: string;
  className?: string;
}

class BannerComponent extends React.Component<IProps & WithTranslation> {
  public render(): JSX.Element {
    const {
      children,
      bannerTitle,
      highlightedText,
      text,
      t: translate,
      className,
    } = this.props;

    return (
      <div className={classNames('banner-wrapper', className)}>
        <div className="banner-content">
          <>
            <Typography variant="h1" sx={{ fontWeight: 'bold' }} className="banner-title">
              {highlightedText && (
                <span className="highlighted-text">
                  {translate(highlightedText)}
                </span>
              )}
            &nbsp;
              {bannerTitle && translate(bannerTitle)}
            </Typography>
            {text && (
              <p className="banner-item">{translate(text)}</p>
            )}
            {children}
          </>
        </div>
      </div>
    );
  }
}

export const Banner = withTranslation()(BannerComponent);
