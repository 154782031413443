import React from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SaveSessionIcon from '@mui/icons-material/SaveOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { observer } from 'mobx-react';

import { Tooltip } from '@esurance/ui-components';

const Container = styled('div')<{ isGastrosuisse?: boolean, isSaveSessionButtonShow: boolean }>(({ theme, isGastrosuisse, isSaveSessionButtonShow }) => ({
  backgroundColor: isGastrosuisse
    ? theme.palette.primary.light
    : theme.palette.alert.standardInfo.background,
  width: '100%',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
    display: isSaveSessionButtonShow ? 'flex' : 'none',
    height: '60px',
    marginTop: theme.spacing(3.5),
  },
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 5),
  maxWidth: '1040px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));

const Title = styled('h4')<{ isGastrosuisse?: boolean }>(({ theme, isGastrosuisse }) => ({
  color: theme.palette.text.primary,
  fontWeight: isGastrosuisse ? theme.typography.h4.fontWeight : theme.typography.h3.fontWeight,
  fontSize: isGastrosuisse ? theme.typography.h2.fontSize : theme.typography.h3.fontSize,
  fontFamily: isGastrosuisse ? theme.typography.h4.fontFamily : theme.typography.h1.fontFamily,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const SaveSessionSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const SaveSessionButton = styled('button')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: theme.typography.body2.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  backgroundColor: 'inherit',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  border: 0,
}));

interface IProps {
  title: string,
  isGastro: boolean,
  isSaveSessionButtonShow?: boolean,
  saveSessionButtonClick?: () => void,
}

export const SurveyBanner = observer((props: IProps) => {
  const [translate] = useTranslation();

  const {
    title,
    isSaveSessionButtonShow = false,
    saveSessionButtonClick,
    isGastro,
  } = props;

  return (
    <Container isGastrosuisse={isGastro} isSaveSessionButtonShow={isSaveSessionButtonShow}>
      <Content>
        <Title isGastrosuisse={isGastro}>
          {translate(title)}
        </Title>

        {
          isSaveSessionButtonShow && saveSessionButtonClick && (
            <SaveSessionSection>
              <SaveSessionButton onClick={() => saveSessionButtonClick()}>
                <SaveSessionIcon color="primary" fontSize="medium" sx={{ mr: 1 }} />
                {translate('survey_banner.save_session_button_text')}
              </SaveSessionButton>

              <Tooltip
                title={translate('survey_banner.save_session_tooltip_text')}
                place="top-start"
              >
                <HelpIcon color="secondary" fontSize="small" sx={{ ml: 1 }} />
              </Tooltip>
            </SaveSessionSection>
          )
        }
      </Content>
    </Container>
  );
});
