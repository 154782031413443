import type { MouseEvent, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { Tooltip } from '../Tooltip/Tooltip';

interface IFormItemProps {
  htmlFor?: string;
  label?: React.ReactNode;
  bottomLabel?: React.ReactNode;
  className?: string;
  required?: boolean;
  tooltip?: string;
  errorMessage?: string;
  showError?: boolean;
  children: ReactNode;
}

export class FormItem extends React.Component<IFormItemProps> {
  public static defaultProps: Partial<IFormItemProps> = {
    tooltip: '',
  };

  public render(): JSX.Element {
    const {
      label,
      htmlFor,
      children,
      className,
      required,
      bottomLabel,
      tooltip,
      errorMessage,
      showError,
    } = this.props;

    const fieldClassNames = classNames('es-form-item', className, {
      required: required || false,
    });
    const errorClassNames = classNames('error', {
      visible: showError,
    });

    return (
      <div className={fieldClassNames}>
        {label && (
          <div className="label">
            <label htmlFor={htmlFor}>
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              )}
            </label>
          </div>
        )}

        <div className="control-wrapper">{children}</div>

        {bottomLabel && (
          <div className="bottom-label">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <label
              htmlFor={htmlFor}
              onMouseDown={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
            >
              {bottomLabel}
            </label>
          </div>
        )}

        {errorMessage && (
          <div className={errorClassNames}>{errorMessage}</div>
        )}
      </div>
    );
  }
}
