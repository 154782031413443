import type { ReactNode } from 'react';
import React from 'react';
import { resolve } from 'inversify-react';
import type { Theme } from '@mui/material';
import {
  Box,
  Fade,
  Modal as MuiModal,
  Backdrop,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';

import {
  IEventManager,
  DependencyType,
  IHistoryManager,
  EventName,
} from '@esurance/services';

import { Loader } from '../Loader/Loader';

interface ICoverModalProps {
  overlap?: boolean;
  theme: Theme;
}

interface IBackdropModalProps {
  isBorderless: boolean
}

interface IProps {
  isClosable?: boolean;
  isBorderless?: boolean;
  loading?: boolean;
  loadingText?: string;
  overlap?: boolean;
  setCustomCloseModal?: () => void;
  children: ReactNode;
}

const CoverModal = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'overlap' },
)(({ overlap, theme }: ICoverModalProps) => ({
  maxWidth: 650,
  maxHeight: '95%',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 10000,
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  overflow: 'auto',
  borderRadius: '4px',

  [theme.breakpoints.down('md')]: {
    maxWidth: 'auto',
  },

  [theme.breakpoints.down('sm')]: {
    maxHeight: overlap ? 'calc(100% - 30px)' : '100%',
    maxWidth: overlap ? 'calc(100% - 30px)' : '100%',
    height: '100%',
  },
}));

const BackdropModal = styled(Backdrop)(({ isBorderless }: IBackdropModalProps) => ({
  backgroundColor: isBorderless ? 'white' : 'rgba(192, 192, 192, 0.41)',
  boxShadow: 'none',
}));

const LoadingText = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '32px',
  width: 'calc(100% - 60px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 40,

  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 20px)',
  },
}));

export class Modal extends React.Component<IProps> {
  public static defaultProps = {
    isClosable: true,
    isBorderless: false,
    overlap: false,
  };

  @resolve(DependencyType.EventManager)
  private eventManager: IEventManager;

  @resolve(DependencyType.HistoryManager)
  private historyManager: IHistoryManager;

  public render(): JSX.Element {
    const {
      children,
      isClosable,
      isBorderless,
      loading,
      loadingText,
      overlap,
    } = this.props;

    const coverModalProps = { overlap };

    return (
      <MuiModal
        open
        onClose={(event, reason) => this.setCloseModal(event, reason)}
        closeAfterTransition
        BackdropComponent={(props: any) => (
          <BackdropModal
            {...props}
            timeout={500}
            isBorderless={!!isBorderless}
            open
          />
        )}
      >
        <Fade in>
          <CoverModal {...coverModalProps}>
            {isClosable && (
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 3.75,
                  right: 3.75,
                }}
                onClick={() => this.setCloseModal()}
              />
            )}

            <Loader loading={loading} />

            {loading && loadingText && <LoadingText>{loadingText}</LoadingText>}

            {children}
          </CoverModal>
        </Fade>
      </MuiModal>
    );
  }

  setCloseModal = (e?: object, reason?: string): void => {
    const { setCustomCloseModal, isClosable } = this.props;

    if (reason === 'backdropClick' && !isClosable) {
      return;
    }

    if (setCustomCloseModal) {
      setCustomCloseModal();
    }

    this.eventManager.emit(EventName.CloseModal);
  };
}
