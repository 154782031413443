import './ByCompanyLogo.styl';

import React from 'react';
import classesNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ProductTypes } from '@esurance/legacy-constants';

interface ICompanyLogoProps {
  source: string | undefined;
  productType: ProductTypes;
}

// TODO: Not DRY. The same implementation is in shared package
function isHealthProduct(typeName: ProductTypes): boolean {
  const healthTypes = [
    ProductTypes.Krankentaggeld,
    ProductTypes.Unfall,
    ProductTypes.Krankenkasse,
  ];
  return healthTypes.includes(typeName);
}

// TODO: Not DRY. The same implementation is in shared package
function isNotMembership(typeName: ProductTypes): boolean {
  return typeName !== ProductTypes.Haushalt;
}

export function ByCompanyLogo(props: ICompanyLogoProps): JSX.Element | null {
  const [translate] = useTranslation();

  const { source, productType } = props;
  if (!source) {
    return null;
  }

  const classes = classesNames('logo', 'cart-product-header-logo', {
    swica: isHealthProduct(productType),
  });

  return (
    <div className={classes}>
      {isNotMembership(productType)
      && translate('basket_selected-item_company-logo_by')}
      <img src={source} alt="Logo" />
    </div>
  );
}
