import React from 'react';
import { Box, Checkbox, styled } from '@mui/material';

import { TranslationWithTags } from '@esurance/ui-components';

interface IProps {
  onToggleAgreement: () => void;
}

const TermsConditionsRoot = styled(Box)(({ theme }) => ({
  display: 'flex',

  a: {
    color: theme.palette.primary.main,
  },
}));

const TermsConditionsToggle = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1.5),
}));

const TermsConditionsDescription = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  lineHeight: '24px',
}));

const TermsConditionsAdditionalInformation = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.tertiary,
  lineHeight: '24px',
}));

export class TermsConditions extends React.Component<IProps> {
  public render() {
    const { onToggleAgreement } = this.props;

    return (
      <TermsConditionsRoot className="TermsConditions-root">
        <Box>
          <TermsConditionsToggle
            className="TermsConditions-toggle"
            onChange={onToggleAgreement}
          />
        </Box>

        <Box>
          <TermsConditionsDescription>
            <TranslationWithTags
              translationKey="terms_conditions.description"
              wrapLess
            />
          </TermsConditionsDescription>
          <TermsConditionsAdditionalInformation>
            <TranslationWithTags
              translationKey="terms_conditions.additional_information"
              wrapLess
            />
          </TermsConditionsAdditionalInformation>
        </Box>
      </TermsConditionsRoot>
    );
  }
}
