import { inject } from 'inversify';

import type { OptionType } from '@esurance/ui-components';

import { ICompanySuggestionRepository } from '../repositories';
import { DependencyType as AutocompletesDependencyType } from '../ioc/DependencyType';

import { AbstractAutocomplete } from './AbstractAutocomplete';

export class CompanySuggestionAutocomplete extends AbstractAutocomplete {
  public constructor(
    @inject(AutocompletesDependencyType.CompanySuggestionRepository)
    private companySuggestionRepository: ICompanySuggestionRepository,
    useDebounce = true,
  ) {
    super(useDebounce);
  }

  protected fetchNewOptionsList = async (): Promise<OptionType[]> => {
    const searchString = this.value.label;
    if (!searchString) {
      return [];
    }
    const suggestions = await this.companySuggestionRepository.getSuggestions(searchString);

    return (suggestions || []).map((suggestion) => ({
      label: suggestion.name,
      value: suggestion.uri,
    }));
  };
}
