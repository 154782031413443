import classNames from 'classnames';
import React from 'react';
import './switch.styl';
import { useTranslation } from 'react-i18next';

interface ISwitchItem<T> {
  title: string;
  value: T;
}

export type ISwitchItems<T> = ISwitchItem<T>[];

interface IProps<T> {
  onChange: (value: T) => void;
  items: ISwitchItems<T>;
  selectedValue: T;
}

type WithToString = { toString: () => string };

export function Switch<T extends WithToString>(props: IProps<T>): JSX.Element {
  const [translate] = useTranslation();

  const { items } = props;

  const buttons = items.map((item) => {
    const cls = classNames({
      active: props.selectedValue === item.value,
      'switch-block': true,
    });

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={cls}
        key={item.value.toString()}
        onClick={() => props.onChange(item.value)}
      >
        {translate(item.title)}
      </div>
    );
  });
  return <div className="switch">{buttons}</div>;
}
