import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { InputAdornment, styled } from '@mui/material';

import type { ITextFieldProps } from '@esurance/ui-components';
import { TextField } from '@esurance/ui-components';

import { moneyFormater } from '../../service/money';

export interface IMoneyProps extends ITextFieldProps {
  className?: string;
  onChangeValue?: (value: number | null) => void;
  type?: 'text';
  valid?: boolean;
  validationMessage?: string;
  touched?: boolean;
  value?: string;
  defaultValue?: string;
}

interface IState {
  output: string;
  value?: number;
  props?: IMoneyProps;
}

const MAX_SAFE_INTEGER = Number.MAX_VALUE
  ? Number.MAX_VALUE
  : 9007199254740991;

/**
 * Replace all values except numbers with empty string. Works for:
 * 0.00
 * 12'123.23
 * 1`2'1A23.23
 */
export function toMoneyValue(value: string): string {
  return value.replace(/(?!\.)\D+/g, '');
}

function toMoneyOutput(value: string): string {
  return moneyFormater(value, false, '', 0);
}

export function moneyInputToStateData(inputValue: string): IState {
  const tempValue = toMoneyValue(inputValue);
  if (tempValue === '') {
    return {
      output: '',
      value: undefined,
    };
  }
  const numberValue = _.toInteger(tempValue);
  const output = toMoneyOutput(numberValue.toString());
  return {
    output,
    value: numberValue,
  };
}

const FieldContainer = styled('div')({
  '& .MuiOutlinedInput-input': {
    border: '0 !important',
    boxShadow: 'none !important',
  },
});

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& .MuiTypography-body1': {
    fontSize: '16px',

    '.Mui-error &': {
      color: theme.palette.error.main,
    },
  },
}));

/**
 * @deprecated
 */
export class InputMoney extends React.Component<IMoneyProps, IState> {
  public state: IState = {
    output: '',
    value: 0,
    props: undefined,
  };

  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IMoneyProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  public focus(): void {
    this.inputRef.current?.focus();
  }

  public static getDerivedStateFromProps(props: IMoneyProps, state: IState): IState | null {
    if (_.isEqual(props, state.props)) {
      return state;
    }
    const value = _.isNil(props.defaultValue)
      ? props.value
      : props.defaultValue;

    if (!_.isNil(value)) {
      return {
        ...moneyInputToStateData((value).toString()),
        props,
      };
    }
    return null;
  }

  public render(): JSX.Element {
    const {
      className,
      readOnly,
      name,
    } = this.props;
    const { output, value } = this.state;
    const rootClassName = classNames(className, 'money-input', {
      readonly: readOnly,
    });

    const props = _.omit(this.props, 'onChangeValue');

    return (
      <>
        <input
          type="hidden"
          name={`${name}_money`}
          value={value}
        />
        <FieldContainer>
          <TextField
            {...props}
            className={rootClassName}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            type="text"
            value={output}
            inputRef={this.inputRef}
            startAdornment={<StyledInputAdornment position="start">CHF</StyledInputAdornment>}
          />
        </FieldContainer>
      </>
    );
  }

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): boolean | undefined => {
    const { output } = this.state;
    const availableKeys = [
      49, // 1
      50, // 2
      51, // 3
      52, // 4
      53, // 5
      54, // 6
      55, // 7
      56, // 8
      57, // 9
      48, // 0
      9, // Tab
      8, // Backspace
      46, // Delete
      37, // ArrowLeft
      39, // ArrowRight
      96, // Num 0
      97, // Num 1
      98, // Num 2
      99, // Num 3
      100, // Num 4
      101, // Num 5
      102, // Num 6
      103, // Num 7
      104, // Num 8
      105, // Num 9
    ];

    if (!availableKeys.includes(event.keyCode)) {
      if (
        event.currentTarget.selectionStart === 0
        && event.key === '-'
        && !output.startsWith('-')
      ) {
        return;
      }
      event.preventDefault();
      // eslint-disable-next-line consistent-return
      return false;
    }
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const { onChangeValue } = this.props;

    if (
      event.target.value.indexOf('.') !== event.target.value.lastIndexOf('.')
    ) {
      // TODO: o_0 what is this? Why do we need it?
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState(this.state);
    }
    const { output } = this.state;

    const nextState = moneyInputToStateData(event.target.value);

    let caret = event.target.selectionStart || 0;
    if (nextState.output.length - output.length === 2) {
      caret += 1;
    }
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    if ((nextState.value || 0) <= MAX_SAFE_INTEGER) {
      this.setState(nextState);

      if (onChangeValue) {
        onChangeValue(nextState.value as number);
      }
    }
  };
}
