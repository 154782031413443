import type { RadioProps } from '@mui/material';

export function createMaterialRadioTheme(
  overrides?: Partial<RadioProps>,
): Partial<RadioProps> {
  return {
    color: 'primary',
    ...overrides,
  };
}
