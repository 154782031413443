import './AppHeader.styl';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MenuIcon from '@mui/icons-material/MenuRounded';

import type { IconColor } from '@esurance/legacy-constants';

import type { INaverVisibility } from '../../hooks';
import { useIsMobile, useNavVisibility } from '../../hooks';

export const AppHeaderClassName = 'global-header';

export interface IAppHeaderProps {
  logo?: JSX.Element;
  /**
   * Logo image URL
   */
  logoUrl?: string;
  /**
   * Logo image link url
   */
  logoLink?: string;
  /**
   * Logo image alt value
   */
  logoTitle?: string;
  /**
   * CSS Classes for the root and menu items container elements
   */
  className?: string;
  /**
   * Company Link which is used as Logo
   */
  companyLink?: string;
  /**
   * Render props function which returns menu actions
   *
   * @param naverVisibility - naver visibility state and actions
   */
  renderActions?: (naverVisibility: INaverVisibility) => JSX.Element;

  /**
   * Render props function which returns menu items rendered on the middle
   *
   * @param containerClassName
   */
  renderItems?: (containerClassName: string) => JSX.Element;
  /**
   * Menu icons color
   */
  iconColor?: IconColor;

  children?: JSX.Element;

  navVisibility?: INaverVisibility
}

export function AppHeader(props: IAppHeaderProps): JSX.Element {
  const {
    renderItems,
    renderActions,
    className,
    logoUrl,
    logoLink = '/',
    logoTitle,
    companyLink,
    children,
    logo,
    // required for tests
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navVisibility = useNavVisibility(),
  } = props;
  const { isNavVisible, navActions } = navVisibility;
  const isMobile = useIsMobile();

  const actionsClassName = classNames('actions', {
    open: isNavVisible,
  });

  const headerClassName = classNames(AppHeaderClassName, className, {
    open: isNavVisible,
  });

  const logoLinkWrapper = (childrenProps: React.ReactNode): JSX.Element => ((/:\/\//.test(logoLink))
    ? <a className="logo" href={logoLink}>{childrenProps}</a>
    : <Link className="logo" to={logoLink}>{childrenProps}</Link>);

  const openCloseIcon = isMobile && (
    isNavVisible
      ? (<CloseIcon color="primary" onClick={navActions.toggleNav} />)
      : (<MenuIcon color="primary" onClick={navActions.toggleNav} />)
  );

  return (
    <header className={headerClassName}>
      <div className="header-content">
        {logo && logoLinkWrapper(logo)}
        {(logoUrl && logoTitle) && logoLinkWrapper(
          <img src={logoUrl} alt={logoTitle} title={logoTitle} />,
        )}
        {companyLink && logoLinkWrapper(companyLink)}
        {renderItems && renderItems('items')}
        <menu>
          {openCloseIcon}
          {renderActions && (
            <div className={actionsClassName}>{renderActions(navVisibility)}</div>
          )}
        </menu>
        {children}
      </div>
    </header>
  );
}
