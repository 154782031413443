import './radio-list.styl';

import classNames from 'classnames';
import type { MouseEvent } from 'react';
import React from 'react';

import { _tf } from '../../../service';

/**
 * @deprecated
 */
interface IItem {
  value: string;
  title: string;
}

/**
 * @deprecated
 */
interface IProps {
  items: IItem[];
  selected?: string;
  onChange: (value: string, title?: string) => void;
}

/**
 * @deprecated
 */
export class RadioList extends React.PureComponent<IProps> {
  public render(): JSX.Element {
    const { items, selected } = this.props;

    const list = items.map((item) => {
      const active = selected === item.value;
      const classes = classNames({
        active,
      });

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          key={item.value}
          className={classes}
          onClick={(event) => this.handleOptionChange(event, item)}
          onKeyPress={(event) => this.handleKeyPress(event, item, active)}
          tabIndex={-1}
        >
          {_tf(item.title)}
        </div>
      );
    });

    const radioClasses = classNames('radio-list-old', {
      invalid: !selected,
    });

    return <div className={radioClasses}>{list}</div>;
  }

  private handleOptionChange = (
    event: MouseEvent<HTMLDivElement>,
    item: IItem,
  ): void => {
    event.preventDefault();
    const { onChange } = this.props;
    onChange(item.value, item.title);
  };

  private handleKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>,
    item: IItem,
    active: boolean,
  ): void => {
    const { onChange } = this.props;
    const keyCodes = [32, 13]; // Space or enter

    if (!keyCodes.includes(event.keyCode)) {
      return;
    }

    if (!active) {
      onChange(item.value, item.title);
    }
  };
}
