import React from 'react';
import { styled } from '@mui/material';

// TODO:
//  1. Move ProductTypeIconMap to this component
//  2. reuse it in all other places
//  3. Write tests

export interface IProductLogoProps {
  url: string;
  alt: string;
}

const ProductLogoImg = styled('img')({
  width: '32px',
});

export function ProductLogo({ alt, url }: IProductLogoProps): JSX.Element {
  return (
    <ProductLogoImg src={url} alt={alt} />
  );
}
