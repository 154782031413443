import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { TranslationWithTags } from '@esurance/ui-components';

interface IProps {
  bullets: string;
}

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const StyledBulletIcon = styled(CheckCircleIcon)(({ theme }) => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: theme.spacing(1),
}));

const StyledBulletText = styled(Typography)({
  display: 'inline-block',
  verticalAlign: 'middle',
});

export function Bullets({ bullets }: IProps) {
  const [translate] = useTranslation();

  const bulletItems = translate(bullets, bullets)
    .replaceAll('<li>', '/n')
    .replaceAll('</li>', '/n')
    .split('/n')
    .filter((item) => !!item.trim())
    .map((item, i) => (
      <Box key={i}>
        <StyledBulletIcon color="success" />
        <StyledBulletText variant="body1">
          <TranslationWithTags translationKey={item} />
        </StyledBulletText>
      </Box>
    ));
  return (
    <Wrapper data-test="bullet-list">
      {bulletItems}
    </Wrapper>
  );
}
