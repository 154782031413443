import { Collapse, styled } from '@mui/material';

interface ICollapsableParameterProps {
  hasTopSpacing?: boolean
}

export const CollapsableParameter = styled(
  Collapse,
  { shouldForwardProp: (prop) => prop !== 'hasTopSpacing' },
)<ICollapsableParameterProps>(
  ({ hasTopSpacing = false }: ICollapsableParameterProps) => ({ theme }) => ({
  // margin/padding fixes label truncation
    marginTop: hasTopSpacing ? theme.spacing(1.5) : theme.spacing(-1),
    paddingTop: theme.spacing(1),
  }),
);
