import type { Theme } from '@mui/material';
import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type MuiButtonStyles = ComponentsOverrides['MuiButton'];

export function createMuiButtonStyles(
  theme: Theme,
  overrides?: Partial<MuiButtonStyles>,
): Partial<MuiButtonStyles> {
  return {
    root: {
      textTransform: 'none',
      ...(overrides?.root as object),
    },
    sizeLarge: {
      ...theme.typography.largeButton,
    },
    sizeSmall: {
      ...theme.typography.smallButton,
    },
    textSecondary: {
      color: theme.palette.text.tertiary,
    },
    outlinedSecondary: {
      borderColor: theme.palette.text.tertiary,
      color: theme.palette.text.primary,
    },
    ...overrides,
  };
}
