import React from 'react';
import { Field } from 'react-final-form';

import type { OptionType, ValueType } from '@esurance/ui-components';
import {
  FormItem, Select, valueToOption,
} from '@esurance/ui-components';
import { LANGUAGES } from '@esurance/legacy-constants';
import { _t } from '@esurance/shared/service';

interface IProps {
  name: string,
  label: string,
  onChange: (selected: ValueType<OptionType>) => void,
}

export function LanguageSelect(props: IProps): JSX.Element | null {
  const { name, label, onChange } = props;
  const languageList: OptionType<string>[] = LANGUAGES
    .map((lng) => ({
      label: _t(`lang_name-${lng}`),
      value: lng,
    }));

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormItem
          label={_t(label)}
          htmlFor={input.name}
        >
          <Select
            name={input.name}
            onChange={onChange}
            options={languageList}
            value={valueToOption(input.value, languageList)}
            placeholder={_t('dropdown-select-value-placeholder')}
          />
          {meta.touched && meta.error && (
            <div className="v-error">{meta.error}</div>
          )}
        </FormItem>
      )}
    </Field>
  );
}
