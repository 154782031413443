import type { IAvailableApplicationDto, IUserPermissionsDto, IAvailableTenantDto } from '../interfaces';
import type {
  IAvailableApplicationData,
  IUserPermissionsData,
  IAvailableTenantData,
} from '../../models/interfaces';

export function parseUserPermissionsDto(data: IUserPermissionsDto): IUserPermissionsData {
  return {
    tenants: (data.tenants || []).map(parseTenantDto),
  };
}

export function parseTenantDto(data: IAvailableTenantDto): IAvailableTenantData {
  return {
    name: data.name,
    availableApplications: data.availableApplications.map(parseAvailableApplicationDto),
  };
}

export function parseAvailableApplicationDto(
  data: IAvailableApplicationDto,
): IAvailableApplicationData {
  return {
    title: data.title,
    type: data.type,
    url: data.url,
  };
}
