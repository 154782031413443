import type { ComponentsOverrides } from '@mui/material/styles/overrides';

type FormControlStyles = ComponentsOverrides['MuiFormControl'];

export function createMuiFormControlLabelStyles(
  overrides?: FormControlStyles,
): FormControlStyles {
  return {
    root: {
      marginLeft: '-9px',
    },
    ...overrides,
  };
}
