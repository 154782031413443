import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';

export abstract class AbstractCRUD<T> {
  protected abstract root: string;

  public get(id: number | string): Promise<T> {
    return axios
      .get<T>(`${diContainer.backofficeApiRoot}/${this.root}/${id}`)
      .then((response) => response.data);
  }

  public create(data: T): Promise<boolean> {
    return axios
      .post<boolean>(`${diContainer.backofficeApiRoot}/${this.root}`, data)
      .then(() => true);
  }

  public update(id: number | string, data: T): Promise<boolean> {
    return axios
      .put<boolean>(`${diContainer.backofficeApiRoot}/${this.root}/${id}`, data)
      .then(() => true);
  }

  public delete(id: number | string): Promise<boolean> {
    return axios
      .delete(`${diContainer.backofficeApiRoot}/${this.root}/${id}`)
      .then(() => true);
  }
}
