import React from 'react';
import cn from 'classnames';

import './InlineLayout.styl';

interface IOptions {
  value: string;
  title: string;
}

interface IProps {
  list: IOptions[];
  onLanguageSelect: (value: string) => void;
  selected: string;
}

export function InlineLayout({ list, selected, onLanguageSelect }: IProps) {
  const items = list.map((item) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={cn({ selected: item.value === selected })}
      key={item.value}
      onClick={(e) => {
        e.preventDefault();

        if (onLanguageSelect) {
          onLanguageSelect(item.value);
        }
      }}
    >
      {item.title}
    </li>
  ));

  return (
    <div className="inline-layout">
      <ul className="list">{items}</ul>
    </div>
  );
}
