import type { ISessionContactData, ISessionContactDto } from '../interfaces';

export function parseSessionContactData(
  dto: ISessionContactDto,
): ISessionContactData {
  return {
    isAdmin: dto.is_admin,
    language: dto.language,
    labels: dto.labels,
    id: dto.id,
    isLinkedToPartner: dto.linked_to_partner,
    partnerContactId: dto.partner_contact_id,
    org: dto.org,
  };
}
