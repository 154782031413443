import type React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { DependencyType } from '../ioc/DependencyType';
import type { IAuthManager } from '../managers/interfaces';

export const LogoutPageView: React.FC = () => {
  const authManager = useInjection<IAuthManager>(DependencyType.AuthManager);
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
    if (authManager.isAuthenticated) {
      authManager.logout();
    }
  }, []);

  return null;
};

export default LogoutPageView;
