import classNames from 'classnames';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import type { ReactNode } from 'react';
import React from 'react';

import { Radio } from './radio';
import './radio-group.styl';

interface IOption {
  value: string;
  title: string;
}

interface IProps {
  label?: string;
  className?: string;
  defaultSelected?: string;
  selected?: string;
  options: readonly IOption[];
  onChange?: (value: string, checked: boolean) => void;
  validationMessage?: string;
  required?: boolean;
  name?: string;
  readOnly?: boolean;
  inline?: boolean;
  rendererIcons?: ((value: string) => ReactNode) | React.ReactNode;
  id?: string;
}

interface IState {
  selected?: string;
}

class RadioGroupComponent extends React.Component<IProps & WithTranslation, IState> {
  public static defaultProps: Partial<IProps> = {
    className: '',
  };

  public state: IState = {
    selected: undefined,
  };

  public componentDidMount(): void {
    const { onChange, selected, defaultSelected } = this.props;

    if (!onChange) {
      this.setState({
        selected: selected || defaultSelected,
      });
    }
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { onChange, defaultSelected } = this.props;

    if (!onChange && prevProps.defaultSelected !== defaultSelected) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selected: defaultSelected,
      });
    }
  }

  public render(): JSX.Element {
    const {
      name,
      label,
      readOnly,
      required,
      rendererIcons,
      options: optionsData,
      inline,
      className: rootClassName,
      selected,
      validationMessage,
      id,
      t: translate,
    } = this.props;

    const className = classNames('radio-item', {
      readOnly,
    });

    const options = _.map(optionsData, (item) => {
      let radioId = `${item.value}`;
      if (name) {
        radioId = `${name}-${radioId}`;
      }
      if (id) {
        radioId = `${id}-${radioId}`;
      }

      return (
        <div className={className} key={item.value} data-test={`${radioId}-container`}>
          {rendererIcons
          && (_.isFunction(rendererIcons)
            ? rendererIcons(item.value)
            : rendererIcons)}
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>
            <Radio
              id={radioId}
              name={name || undefined}
              value={item.value}
              onChange={this.handleChange}
              required={required}
              readOnly={readOnly}
              {...this.checkedProp(item)}
            />

            {translate(item.title)}
          </label>
        </div>
      );
    });

    const optionsClassNames = classNames('radio-group', {
      inline,
      readonly: readOnly,
    });

    const fieldsetClassNames = classNames(
      'radio-group-fieldset',
      rootClassName,
      {
        required: required && !readOnly,
      },
    );

    return (
      <fieldset className={fieldsetClassNames}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        {label && <label>{label}</label>}

        <div className={optionsClassNames}>{options}</div>
        <div
          className="error"
          style={{
            opacity: required && _.isEmpty(selected) ? 1 : 0,
          }}
        >
          {validationMessage && (
            <>{translate(validationMessage)}</>
          )}
        </div>
      </fieldset>
    );
  }

  private checkedProp = (item: IOption): { checked?: boolean; defaultChecked?: boolean } => {
    const { selected, defaultSelected, onChange } = this.props;
    const { selected: selectedInState } = this.state;

    if (onChange) {
      return selected
        ? { checked: selected === item.value }
        : { defaultChecked: defaultSelected === item.value };
    }

    return { checked: selectedInState === item.value };
  };

  private handleChange = (value: string, checked: boolean): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value, checked);
    } else {
      this.setState({
        selected: value,
      });
    }
  };
}

export const RadioGroup = withTranslation()(RadioGroupComponent);
