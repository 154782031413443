import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type { IAction } from '@esurance/entities';

class Action {
  public getAll(orgId: string | number): Promise<IAction[]> {
    return axios
      .get<IAction[]>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/user-services`,
      )
      .then((response) => response.data);
  }
}

export const action = new Action();
