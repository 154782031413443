import { urlPattern } from '@esurance/legacy-constants';

import type { IValidator } from '../interfaces';

export class Url implements IValidator {
  constructor(public errorMessage: string = 'url-invalid-message') {}

  public validate(value: string): boolean {
    return Boolean(value.match(urlPattern));
  }
}
