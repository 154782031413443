import React from 'react';
import {
  Container,
  Divider,
  Stack,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { TermsConditions } from '../TermsConditions/TermsConditions';
import { BasketSubmit } from '../BasketSubmit/BasketSubmit';

interface IProps {
  total: string;
  onToggleAgreement: () => void;
}

export class ShopBasketFooter extends React.Component<IProps> {
  public render() {
    const { total, onToggleAgreement } = this.props;

    return (
      <Box className="ShopBasketFooter-root">
        <Divider />

        <Container maxWidth="lg">
          <Stack direction="row" py={7.5} className="ShopBasketFooter-root">
            <Grid container spacing={2}>
              <Grid xs={6.3}>
                <Stack mt={2.5}>
                  <TermsConditions onToggleAgreement={onToggleAgreement} />
                </Stack>
              </Grid>
              <Grid xs={5.2} ml="auto">
                <BasketSubmit total={total} />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    );
  }
}
