import type { Theme } from '@mui/material';
import { CssBaseline, ThemeProvider } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';
import type { Interpolation } from '@emotion/react';
import { GlobalStyles } from '@mui/system';

import { backofficeTheme } from './backofficeTheme';
import { inaTheme } from './inaTheme';
import { defaultMaterialTheme } from './defaultMaterialTheme';
import { baseTheme } from './baseTheme';
import { shopTheme } from './shopTheme';
import { ThemeName } from './constants/themeName';

interface IProps{
  theme: ThemeName;
  children: ReactNode;
  href?: string;
}

const globalStyles: Interpolation<Theme> = (theme) => ({
  ':root': {
    '--primary-color': theme.palette.primary.main,
  },
});

export const EsuranceThemeProvider = ({ children, theme, href }: IProps) => {
  const urlTheme = getThemeNameFromUrl(href || '');
  const themeObject = urlTheme ? getThemeByName(urlTheme) : getThemeByName(theme);
  return (
    <ThemeProvider theme={themeObject}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />

      {children}
    </ThemeProvider>
  );
};

const getThemeByName = (themeName: ThemeName) => themeList[themeName];

export function getThemeNameFromUrl(href: string): ThemeName | null {
  for (const value of Object.values(ThemeName)) {
    if (href.includes(`theme=${value}`)) {
      return value;
    }
  }
  return null;
}

const themeList: { [key in ThemeName]: Theme } = {
  [ThemeName.Material]: defaultMaterialTheme,
  [ThemeName.Base]: baseTheme,
  [ThemeName.Shop]: shopTheme,
  [ThemeName.Ina]: inaTheme,
  [ThemeName.Backoffice]: backofficeTheme,
};
