import { expandMobXArrayIfRequired } from './expandMobXArrayIfRequired';

export function setValueInLimitedLengthMobXArray<T>(
  array: T[],
  index: number,
  newValue: T,
  maxLength: number,
): void {
  if (index >= maxLength) {
    throw new RangeError(`Given index ${index} is outside array. Max length = ${maxLength}.`);
  }

  expandMobXArrayIfRequired(array, index);

  // eslint-disable-next-line no-param-reassign
  array[index] = newValue;
}
