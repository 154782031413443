import { inject, injectable } from 'inversify';
import axios from 'axios';
import i18next from 'i18next';

import { ConstantDependency } from '@esurance/constants';
import type { ITermsContentResponseDto } from '@esurance/entities';

import type { IAuthRepository, ISessionData, ISessionDto } from './interfaces';
import { parseSessionData } from './parsers/parseSessionData';

interface IGetJwtTokenResponseDto {
  accessToken: string;
  userId: number;
}

function getLangHeader(): object {
  return {
    'Accept-Language': i18next.language.toLowerCase(),
  };
}

@injectable()
export class AuthRepository implements IAuthRepository {
  private headers = {};

  public constructor(
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) {}

  public getJWTForPreview(oneTimeToken: string): Promise<string> {
    return axios
      .get<IGetJwtTokenResponseDto>(
        `${this.backofficeApiRoot}/user/preview/jwt`,
        {
          params: {
            one_time_token: oneTimeToken,
          },
        },
      )
      .then((response) => response.data.accessToken);
  }

  public async getSessionData(): Promise<ISessionData> {
    const response = await axios.get<ISessionDto>(`${this.backofficeApiRoot}/user/who-am-i`);
    return parseSessionData(response.data);
  }

  public async impersonate(userId: string): Promise<void> {
    await axios.post(getImpersonationUrl(this.backofficeApiRoot, userId));
  }

  public getAgreeTerms(): Promise<string> {
    return axios
      .get<ITermsContentResponseDto>(
        `${this.backofficeApiRoot}/terms_and_conditions/current`,
        {
          headers: {
            ...this.headers,
            ...getLangHeader(),
          },
        },
      )
      .then((response) => response.data.text);
  }

  public isTermsAccepted(): Promise<boolean> {
    return axios
      .get<boolean>(
        `${this.backofficeApiRoot}/terms_and_conditions/is_accepted`,
        {
          headers: this.headers,
        },
      )
      .then((response) => response.data);
  }

  public acceptTerms(): Promise<boolean> {
    return axios
      .post(
        `${this.backofficeApiRoot}/terms_and_conditions/agree`,
        undefined,
        {
          headers: this.headers,
        },
      )
      .then(() => true);
  }
}

function getImpersonationUrl(apiRoot: string, userId: string): string {
  return `${apiRoot}/admin/user/identity/${userId}/impersonate`;
}
