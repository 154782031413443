import type { TabsProps } from '@mui/material';

export function createMaterialTabsTheme(
  overrides?: Partial<TabsProps>,
): Partial<TabsProps> {
  return {
    textColor: 'primary',
    indicatorColor: 'primary',
    ...overrides,
  };
}
