import { createRoot } from 'react-dom/client';

import { diContainer, initServices, inversifyContainer } from '@esurance/ioc-base';
import { getApplicationConfig } from '@esurance/shared/application/getApplicationConfig';
import { AuthEvent } from '@esurance/auth';

import { App } from './App';
import { configureContainer } from './ioc/configureContainer';

export async function bootstrap(): Promise<void> {
  await configureContainer(inversifyContainer);
  diContainer.authManager.on(AuthEvent.Login, onLogin);

  await initServices(getApplicationConfig({
    translation: {
      applicationTranslationNamespace: 'app_selector',
    },
    historyManager: {
      basename: diContainer.applicationName,
    },
  }));

  const rootElement = document.getElementById('root') as HTMLElement;
  const domRoot = createRoot(rootElement);
  domRoot.render(App());
}

function onLogin(): void {
  const { authManager } = diContainer;

  if (
    authManager.isAuthenticated
    && authManager.isRegistrationCompleted
    && !authManager.contact?.isAdmin
    && !authManager.contact?.isLinkedToPartner
  ) {
    // eslint-disable-next-line no-console
    console.error('User is not an admin or partner. Redirecting to cockpit...');
    window.location.replace('/cockpit');
  }
}
