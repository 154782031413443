import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { globalLoader } from '@esurance/shared/service';
import { DependencyType, LogoutPageView } from '@esurance/auth';
import type { IAuthManager } from '@esurance/auth';
import { useInjection } from '@esurance/ioc-base';

import { AppSelectorView, Footer, Header } from '../components';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100vh',
  background: theme.palette.background.paper,
}));

export const Layout: React.FC = () => {
  const authManager = useInjection<IAuthManager>(DependencyType.AuthManager);

  useEffect(() => {
    globalLoader.stop();

    if (!authManager.isAuthenticated) {
      authManager.login();
    }
  }, []);

  if (!authManager.isAuthenticated) {
    return null;
  }

  return (
    <Root>
      <Routes>
        <Route path="/logout" element={<LogoutPageView />} />
      </Routes>
      <Header />
      <AppSelectorView />
      <Footer />
    </Root>
  );
};
