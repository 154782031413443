import { useState } from 'react';

export interface IAppHeaderNavActions {
  hideNav: () => void;
  toggleNav: () => void;
}

export interface INaverVisibility {
  isNavVisible: boolean;
  navActions: IAppHeaderNavActions;
}

/**
 * Creates navigation visibility hook with all available actions
 */
export function useNavVisibility(): INaverVisibility {
  const [isNavVisible, setNavVisible] = useState(false);

  function hideNav(): void {
    setNavVisible(false);
  }

  function toggleNav(): void {
    setNavVisible(!isNavVisible);
  }

  return {
    isNavVisible,
    navActions: {
      hideNav,
      toggleNav,
    },
  };
}
