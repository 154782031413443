import type { FocusEvent } from 'react';
import React from 'react';

import type { ISurveyQuestionOptionsText } from '@esurance/entities';

import { _t } from '../../../service';
import { NextQuestionButton } from '../component/next-question';
import { RadioList } from '../component/radio-list';
import { Input } from '../../form/input';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionOptionsText;
}

interface IState {
  changes: {
    answer: string;
    comment: string;
  };
}

class ListText extends React.Component<IProps> {
  public state: IState = {
    changes: {
      answer: '',
      comment: '',
    },
  };

  public render(): JSX.Element {
    const { item } = this.props;
    const { changes } = this.state;
    const { answer } = changes;

    const options = [...item.options].map((option) => ({
      title: option.value,
      value: option.value,
    }));

    return (
      <BaseQuestion title={item.title}>
        <RadioList
          items={options}
          selected={answer}
          onChange={this.handleSelect}
        />

        {this.renderInput(item)}

        <NextQuestionButton
          disabled={!answer}
          itemClass=".question"
          onSubmit={this.handleSubmit}
        />
      </BaseQuestion>
    );
  }

  private handleSelect = (value: any): void => {
    this.handleChange({
      answer: value,
    });
  };

  private handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    this.handleChange({
      comment: event.currentTarget.value,
    });
  };

  private handleChange = (change: any): void => {
    const { changes } = this.state;
    this.setState({
      changes: Object.assign(changes, change),
    });
  };

  private handleSubmit = (): void => {
    const { onChange, item } = this.props;
    const { changes } = this.state;
    onChange(item, changes);
  };

  private renderInput(item: ISurveyQuestionOptionsText): JSX.Element {
    return (
      <Input
        type="text"
        label={_t('survey-question_options-comment')}
        placeholder={item.placeholder}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default ListText;
