import { injectable } from 'inversify';

import type { IUserAgentManager } from './interfaces';

@injectable()
export class UserAgentManager implements IUserAgentManager {
  private get userAgent(): string {
    return window.navigator.userAgent;
  }

  public get isInternetExplorer(): boolean {
    return this.userAgent.indexOf('Trident') !== -1;
  }

  public get isEdge(): boolean {
    return this.userAgent.indexOf('Edge') !== -1;
  }

  public get isSafari(): boolean {
    return (
      this.userAgent.indexOf('Safari') !== -1
      && this.userAgent.indexOf('Chrome') === -1
    );
  }
}
