import type { IEnvironmentConfig } from './IEnvironmentConfig';

export const defaultEnvironmentConfig: IEnvironmentConfig = {
  AffinityApiRoot: '/affinity/api/v2',
  AffinityV3ApiRoot: '/affinity/api/v3',
  BackofficeApiRoot: '/api/v2',
  BackofficeV3ApiRoot: '/api/v3',
  ApplicationConfigUrl: '/api/v2/ui-config',
  ProductEngineApiRoot: '/product-engine/api/v1',
  XGridLicenseKey: 'dff1e0b8e1bda4a6be7bf4ac9567db0eT1JERVI6MjIzNjAsRVhQSVJZPTE2NDcwMTQ3NTMwMDAsS0VZVkVSU0lPTj0x',
  EsuranceUrl: 'https://esurance.ch',
  SwicaUrl: 'https://www.swica.ch',
  GastroSocialUrl: 'https://www.gastrosocial.ch/',
  GastronomieUrl: 'https://www.esurance.ch/gastronomie/',
  CockpitUrl: '/cockpit-classic/home',
  KeycloakUrl: 'https://auth.dev-esurance.ch/auth',
  KeycloakRealm: 'esurance',
  KeycloakClientId: 'esurance-no-registration-client',
} as const;
