import type { CardProps } from '@mui/material';

export function createMaterialCardTheme(
  overrides?: Partial<CardProps>,
): Partial<CardProps> {
  return {
    variant: 'outlined',
    ...overrides,
  };
}
