import React from 'react';
import _ from 'lodash';
import type { RouteProps } from 'react-router';
import { Route } from 'react-router';

import type { IRoutes } from '@esurance/entities';
import { PrivateRoute, PublicRoute, RouteElementWrapper } from '@esurance/auth';
import type { IHistoryManager } from '@esurance/services';
import { DependencyType } from '@esurance/services';
import { useInjection } from '@esurance/ioc-base';

type BreadcrumbsComponent = React.ComponentType<{ route: IRoutes }>;

export function renderRoutes(
  list: IRoutes[],
  BreadcrumbsContainer?: BreadcrumbsComponent,
): (JSX.Element | null)[] {
  return list.map((route: IRoutes, index: number) => {
    const RouteContainerWithBreadcrumbs = (route: IRoutes) => {
      const historyManager = useInjection<IHistoryManager>(DependencyType.HistoryManager);
      const singleRoute = renderSingleRoute(route);
      historyManager.setCurrentRoute(route);

      if (!singleRoute) {
        return null;
      }

      return (
        <>
          {BreadcrumbsContainer && (
            <BreadcrumbsContainer route={route} />
          )}
          {singleRoute}
        </>
      );
    };
    return (
      <Route key={index} {...route} element={<RouteContainerWithBreadcrumbs {...route} />} />
    );
  });
}

function renderSingleRoute(route: IRoutes): JSX.Element | null {
  const {
    condition = true,
  } = route;
  const conditionResult = _.isFunction(condition) ? condition() : condition;

  return conditionResult ? routeItem(route) : null;
}

function routeItem(route: IRoutes): JSX.Element {
  const {
    isPublic = true,
    isPublicForAll = false,
    element,
    children,
  } = route;

  const RouteWrapperComponent = getRouteWrapperComponent(isPublic, isPublicForAll);

  function getKeyFromRoute(r: IRoutes): string {
    if (r.path === undefined) {
      return 'none';
    }
    return Array.isArray(r.path) ? r.path[0] : r.path;
  }

  return (
    <RouteWrapperComponent key={getKeyFromRoute(route)}>
      {element || children}
    </RouteWrapperComponent>
  );
}

function getRouteWrapperComponent(
  isPublic: boolean,
  isPublicForAll: boolean,
): React.FC<RouteProps> {
  let component: React.ComponentClass | React.FunctionComponent = PrivateRoute;
  if (isPublic) {
    component = isPublicForAll ? RouteElementWrapper : PublicRoute;
  }
  return component;
}
