import type { KeyboardEvent } from 'react';
import React from 'react';

import type { ISurveyQuestionText } from '@esurance/entities';

import { Input } from '../../form/input';
import { NextQuestionButton } from '../component/next-question';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionText;
}

interface IState {
  value: string;
}

class QuestionText extends React.Component<IProps, IState> {
  public state: IState = {
    value: '',
  };

  public render(): JSX.Element {
    const { item } = this.props;
    const { value } = this.state;

    return (
      <BaseQuestion title={item.title}>
        <Input
          type="text"
          label=""
          placeholder={item.placeholder}
          onKeyUp={this.handleChange}
        />

        <NextQuestionButton
          disabled={!value}
          itemClass=".question"
          onSubmit={this.handleSubmit}
        />
      </BaseQuestion>
    );
  }

  private handleChange = (event: KeyboardEvent<HTMLInputElement>): void => {
    this.setState({
      value: event.currentTarget.value,
    });
  };

  private handleSubmit = (): void => {
    const { onChange, item } = this.props;
    const { value } = this.state;
    onChange(item, {
      comment: value,
    });
  };
}

export default QuestionText;
