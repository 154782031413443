import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import { Dropdown } from '@esurance/ui-components';
import type { ISelectOptions } from '@esurance/entities';

import { _t } from '../../service';

import { Label } from './_components/Label';
import './select.styl';

interface IProps {
  label?: string;
  options: ISelectOptions[];
  selected?: string;
  loading?: boolean;
  defaultSelected?: string;
  onSelect?: (value: string) => void;
  multiple?: boolean;
  tip?: string;
  required?: boolean;
  readOnly?: boolean;
  name?: string;
  className?: string;
  placeholder?: string;
  validationMessage?: string;
  onBlur?: (event: any) => void;
  touched?: boolean;
  invalid?: boolean;
  formatTitle?: (title: string) => string;
}

interface IState {
  selected?: string;
}

export class Select extends React.Component<IProps, IState> {
  public static defaultProps = {
    className: '',
    multiple: false,
    placeholder: '',
    validationMessage: '',
  };

  public state: IState = {
    selected: undefined,
  };

  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  public componentDidMount(): void {
    const { defaultSelected } = this.props;
    if (defaultSelected) {
      this.setState({
        selected: defaultSelected,
      });
    }
  }

  // TODO: ESD-8674: rewrite the logic using mobx model
  public componentDidUpdate(previousProps: Readonly<IProps>): void {
    const { selected: currentSelected } = this.props;
    if (!_.isEqual(currentSelected, previousProps.selected)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selected: currentSelected,
      });
    }
  }

  public focus(): void {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  public render(): JSX.Element {
    const {
      name,
      selected: selectedInProps,
      className,
      readOnly,
      required,
      tip,
      label,
      loading,
      options,
      validationMessage,
      touched,
      invalid,
    } = this.props;
    const { selected: selectedInState } = this.state;
    const id = `${name}select`;
    const selected = !_.isEmpty(selectedInProps)
      ? selectedInProps
      : selectedInState;

    const fieldsetClassNames = classNames(
      'select-fieldset',
      className,
      {
        readOnly,
        required: required && !readOnly,
      },
    );

    const errorClasses = classNames('error', {
      visible: invalid && touched,
    });

    const labelProps = { id, label, tip };

    return (
      <fieldset className={fieldsetClassNames}>
        <Label {...labelProps} />

        <Dropdown
          loading={loading}
          list={options}
          selected={selected}
          renderTrigger={this.renderTrigger}
          onClick={this.handleDropdownChange}
        />

        <div className={errorClasses}>
          {_.isEmpty(validationMessage)
            ? _t('select_default-invalid-message')
            : validationMessage}
        </div>
      </fieldset>
    );
  }

  private renderTrigger = (
    selected: string,
    label: string,
    labelClass: string,
    arrow: JSX.Element,
    onToggle: (e: React.FocusEvent<HTMLElement>) => void,
    isTouched: boolean,
  ): JSX.Element => {
    const {
      touched,
      invalid,
      name,
      placeholder,
      required,
      formatTitle,
    } = this.props;
    const inputClasses = classNames(labelClass, {
      touched: isTouched || touched,
      invalid,
    });

    let valueText = _.isEmpty(selected) ? '' : label;
    if (formatTitle) {
      valueText = formatTitle(valueText);
    }

    return (
      <div className="trigger-wrapper">
        <input
          name={name}
          type="text"
          value={selected || ''}
          onChange={() => true}
          className="hidden"
        />

        <input
          name={`${name}select-input`}
          type="text"
          value={valueText}
          onChange={() => true}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            e.currentTarget.blur();
            onToggle(e);
          }}
          className={inputClasses}
          placeholder={placeholder || label}
          required={required}
          ref={this.inputRef}
        />

        {arrow}
      </div>
    );
  };

  private handleDropdownChange = (value: string): void => {
    const { onSelect } = this.props;
    this.setState({
      selected: value,
    });

    if (onSelect) {
      onSelect(value);
    }
  };
}
