import { inject, injectable } from 'inversify';

import { ConstantDependency } from '@esurance/constants';

import { DependencyType } from '../DependencyType';

import type {
  IURLTrackingParametersManager,
  IURLTrackingParameters,
  ILocalStorageManager,
} from './interfaces';
import { ILocalStorageManagerFactory } from './interfaces';
import { LocalStorageManager } from './LocalStorageManager';

@injectable()
export class URLTrackingParametersManager implements IURLTrackingParametersManager {
  private loading = false;

  private loaded = false;

  private readonly localStorageManager: ILocalStorageManager<IURLTrackingParameters>;

  constructor(
    @inject(ConstantDependency.LocalStoragePrefix)
    private localStoragePrefix: string,
    @inject(DependencyType.LocalStorageManagerFactory)
    private localStorageManagerFactory?: ILocalStorageManagerFactory,
  ) {
    if (localStorageManagerFactory) {
      this.localStorageManager = localStorageManagerFactory.create<IURLTrackingParameters>(
        `${localStoragePrefix}utm`,
      );
    } else {
      this.localStorageManager = new LocalStorageManager<IURLTrackingParameters>(`${localStoragePrefix}utm`);
    }
  }

  public load() {
    this.loading = true;

    const searchParams = new URLSearchParams(window.location.search);
    const urlTrackingParams = this.parameters;

    for (const [key, value] of searchParams.entries()) {
      const maybeUrlTrackingParameter = key.replace('utm_', '');
      if (maybeUrlTrackingParameter in urlTrackingParams) {
        urlTrackingParams[maybeUrlTrackingParameter as keyof IURLTrackingParameters] = value;
      }
    }

    this.localStorageManager.save(urlTrackingParams);
    this.loaded = true;
    this.loading = false;
  }

  public get parameters(): IURLTrackingParameters {
    if (!this.loaded && !this.loading) {
      throw Error(
        'You\'re trying to access parameters before they loaded from URL. '
        + 'You must call load() method first.',
      );
    }

    return {
      ...createEmptyUrlTrackingParameters(),
      ...(this.localStorageManager.load() || {}),
    };
  }
}

function createEmptyUrlTrackingParameters(): IURLTrackingParameters {
  return {
    source: null,
    campaign: null,
    content: null,
    medium: null,
    term: null,
  };
}
