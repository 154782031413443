import type { TagParser, TagParserResult } from '../types';

export function parseNode(node: Node, tagParsers: TagParser[]): TagParserResult {
  if (node.nodeType === node.TEXT_NODE) {
    return node.textContent;
  }
  if (node.nodeType === node.ELEMENT_NODE) {
    return parseTag(node as Element, tagParsers);
  }
  throw Error(`Unknown nodeType [${node.nodeType}]`);
}

function parseTag(element: Element, tagParsers: TagParser[]): TagParserResult {
  const children: TagParserResult[] = [];
  element.childNodes.forEach((child) => {
    children.push(parseNode(child, tagParsers));
  });

  for (const tagParser of tagParsers) {
    const maybeElement = tagParser(element, children);
    if (maybeElement != null) return maybeElement;
  }

  return element.outerHTML;
}
