import i18next from 'i18next';

import type { ITranslatableForm } from '@esurance/catalog';

import type { IValidator } from '../interfaces';

export class UniqueLokaliseKey implements IValidator {
  constructor(
    private context: ITranslatableForm,
    public errorMessage: string = 'translation-key-exists-invalid-message',
  ) {}

  public validate(): boolean {
    if (!this.context?.translationKey) return true;
    return !i18next.exists(this.context.translationKey);
  }
}
