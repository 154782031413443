import type { i18n, InitOptions } from 'i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';
import { inject, injectable } from 'inversify';

import { AuthEvent, DependencyType as AuthDependencyType, IAuthManager } from '@esurance/auth';
import { ConstantDependency } from '@esurance/constants';

import { DependencyType } from '../DependencyType';

import type {
  ITranslationConfig,
  ITranslationManager,
  LanguageChangedHandler,
} from './interfaces';
import {
  IAccountRepository,
} from './interfaces';
import { axios } from './axios-wrapper';

export function getInvalidateTranslationCacheEndpointUrl(apiRoot: string): string {
  return `${apiRoot}/admin/translation-backoffice/invalidate-cache`;
}

@injectable()
export class TranslationManager implements ITranslationManager {
  private config: ITranslationConfig;

  public constructor(
    @inject(AuthDependencyType.AuthManager)
    private authManager: IAuthManager,
    @inject(DependencyType.AccountRepository)
    private accountRepository: IAccountRepository,
    @inject(ConstantDependency.BackofficeApiRoot)
    private backofficeApiRoot: string,
  ) {
  }

  // eslint-disable-next-line class-methods-use-this
  public get i18n(): i18n {
    return i18next;
  }

  private initLanguageForUnauthorizedUsers() {
    const { authManager, config } = this;
    if (!authManager.isAuthenticated && config.languages.length) {
      const defaultLanguage = config.languages[0];
      this.changeLanguage(defaultLanguage);
    }
  }

  private initLanguageFromUrlSearchParam() {
    const { searchParams } = new URL(window.location.href);
    const urlLang = searchParams.get('lng');

    if (urlLang) {
      this.changeLanguage(urlLang);
    }
  }

  private initLoginHandler() {
    const { authManager, config } = this;
    authManager.on(AuthEvent.Login, () => {
      const userLanguage = (
        authManager.contact?.language
        || authManager.identity?.att.locale
      );

      if (!_.includes(config.languages, userLanguage)) {
        const defaultLanguage = config.languages[0];
        this.changeLanguage(defaultLanguage);
      } else if (userLanguage) {
        this.changeLanguage(userLanguage);
      }
    });
  }

  private initLanguageChangedHandler() {
    const { authManager, accountRepository } = this;
    this.onLanguageChanged(async (lng: string) => {
      const userLanguage = (
        authManager.contact?.language
        || authManager.identity?.att.locale
      );

      if (
        authManager.isAuthenticated
        && authManager.isRegistrationCompleted
        && userLanguage !== lng
      ) {
        await accountRepository.updateSettings({
          language: lng,
        });
        await authManager.refreshSessionData();
      }
    });
  }

  public async init(config: ITranslationConfig, defaultLanguage?: string): Promise<void> {
    this.config = config;
    const {
      languages,
      loadPath,
      additionalNamespaces = [],
      applicationTranslationNamespace,
    } = config;
    const fallbackLng = config.languages;

    const options: InitOptions = {
      backend: {
        crossDomain: false,
        loadPath,
      },
      lng: defaultLanguage,
      debug: false,
      detection: {
        lookupQuerystring: 'lng',
        order: [
          'querystring',
          'localStorage',
          'cookie',
          'navigator',
          'htmlTag',
        ],
      },
      fallbackLng,
      defaultNS: applicationTranslationNamespace,
      ns: applicationTranslationNamespace
        ? [applicationTranslationNamespace, ...additionalNamespaces]
        : [...additionalNamespaces],
      preload: languages,
      react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
      },
      supportedLngs: [...languages],
      nonExplicitSupportedLngs: false,
      keySeparator: false,
    };
    if (config.fallbackNamespace) {
      options.fallbackNS = config.fallbackNamespace;
    }

    this.initLoginHandler();
    this.initLanguageChangedHandler();

    await i18next
      .use(LanguageDetector)
      .use(XHR)
      .use(initReactI18next)
      .init(options);

    this.initLanguageForUnauthorizedUsers();
    this.initLanguageFromUrlSearchParam();
  }

  public changeLanguage(language: string): void {
    const { languages } = this.config;
    if (_.includes(languages, language)) {
      i18next.changeLanguage(language);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public onLanguageChanged(handler: LanguageChangedHandler): void {
    i18next.on('languageChanged', handler);
  }

  public invalidateTranslationCache(): Promise<void> {
    return axios.post(
      getInvalidateTranslationCacheEndpointUrl(this.backofficeApiRoot),
    );
  }
}
