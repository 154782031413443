import React from 'react';
import { Link } from 'react-router-dom';
import classesNames from 'classnames';

import companyLogo from '../assets/img/company-logo.svg';

interface IHeaderLogoProps {
  src?: string;

  link: string;
  isAbsoluteLinkUrl?: boolean;

  title: string;
  className?: string;
}

export class HeaderLogo extends React.Component<IHeaderLogoProps> {
  static defaultProps: Partial<IHeaderLogoProps> = {
    isAbsoluteLinkUrl: false,
  };

  render(): JSX.Element {
    const { src, title } = this.props;

    return this.renderLinkOrAbsoluteLink(
      <img src={src || companyLogo} alt={title} title={title} />,
    );
  }

  private renderLinkOrAbsoluteLink(image: React.ReactElement): JSX.Element {
    const { isAbsoluteLinkUrl, link } = this.props;
    const classes = classesNames('logo', this.props.className);

    if (isAbsoluteLinkUrl) {
      return (
        <a className={classes} href={link}>
          {image}
        </a>
      );
    }

    return (
      <Link className={classes} to={link}>
        {image}
      </Link>
    );
  }
}
