import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import {
  styled,
  Tooltip as MUITooltip,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { useTheme } from '@mui/material/styles';
import { sanitize } from 'dompurify';

import { Printd } from '@esurance/ui-components';

import { parserForTag } from './helpers';
import { ALLOWED_TAGS } from './constants';

interface IProps {
  content: string;
  tooltipI18nKey: string;
}

interface ITooltipProps {
  onClose: () => void;
  markup: string;
}

const getTooltipStyles = (theme: Theme) => ({
  display: 'block',
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  fontSize: '12px',
  lineHeight: '18px',
  borderRadius: 0,
  border: '1px solid #d0d8da',
  boxShadow: '6px 9px 20px 0 rgba(204, 213, 221, 0.4)',

  [theme.breakpoints.up('xs')]: {
    maxWidth: '300px',
  },
});

const ScrollableWrapper = styled('div')(() => ({
  maxHeight: '300px',
  overflowY: 'auto',
  padding: '5px',
  '& > *:not(:first-child)': {
    marginTop: '5px',
  },
  '& h1': {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  '& ul': {
    listStyle: 'disc',
    marginLeft: 20,
  },
}));

function TooltipTitleRaw({ onClose, markup }: ITooltipProps) {
  const { t: translate } = useTranslation();
  const htmlMarkup = sanitize(translate(markup), { ALLOWED_TAGS });

  const print = () => {
    const element = document.createElement('div');
    const printer = new Printd();

    element.innerHTML = htmlMarkup;
    printer.print(element);
  };

  return htmlMarkup.length > 400
    ? (
      <>
        <ScrollableWrapper dangerouslySetInnerHTML={{ __html: htmlMarkup }} />
        <Box sx={{
          padding: '1px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        >
          <Button startIcon={<PrintIcon />} onClick={print}>
            {translate('text-with-tooltip-print')}
          </Button>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    )
    : (
      <Box
        dangerouslySetInnerHTML={{ __html: htmlMarkup }}
        sx={{
          padding: '11px 16px',
        }}
      />
    );
}

export function TooltipTag({ content, tooltipI18nKey }: IProps) {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const openTooltip = () => setOpen(true);
  const closeTooltip = () => setOpen(false);

  return (
    <MUITooltip
      componentsProps={{
        tooltip: {
          sx: getTooltipStyles(theme),
        },
      }}
      title={(
        <TooltipTitleRaw onClose={closeTooltip} markup={tooltipI18nKey} />
      )}
      placement="top"
      open={open}
      onOpen={openTooltip}
      onClose={closeTooltip}
    >
      <Box
        component="span"
        sx={{
          textDecoration: 'underline',
        }}
      >
        {content}
      </Box>
    </MUITooltip>
  );
}

export const tooltipTagParser = parserForTag('tooltip', (element) => (
  <TooltipTag
    content={element.textContent || 'empty_content'}
    tooltipI18nKey={element.getAttribute('key') || 'unknown_key'}
    key={element.getAttribute('key') || 'unknown_key'}
  />
));
