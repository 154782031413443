import React from 'react';

import type { ISurveyQuestionOptions } from '@esurance/entities';

import { RadioList } from '../component/radio-list';

import type { IBaseProps } from './base';
import BaseQuestion from './base';

interface IProps extends IBaseProps {
  item: ISurveyQuestionOptions;
}

interface IState {
  selected?: string;
}

class List extends React.Component<IProps> {
  public state: IState = {
    selected: undefined,
  };

  public render(): JSX.Element {
    const { item } = this.props;
    const { selected } = this.state;
    const options = item.options.map((option) => ({
      title: option.value,
      value: option.value,
    }));

    return (
      <BaseQuestion title={item.title}>
        <RadioList
          items={options}
          selected={selected}
          onChange={this.handleSelect}
        />
      </BaseQuestion>
    );
  }

  private handleSelect = (value: string): void => {
    const { onChange, item } = this.props;
    this.setState({
      selected: value,
    });

    onChange(item, {
      answer: value,
    });
  };
}

export default List;
