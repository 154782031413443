import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

import { Tooltip } from '../Tooltip/Tooltip';

export interface ILabelWithTooltipProps {
  label: string | number | React.ReactElement;
  tooltip?: string;
  dataTest?: string;
  tooltipIconDataTest?: string;
  tooltipDataTest?: string;
}

const TooltipIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  fontSize: '1.25rem',
  verticalAlign: 'text-bottom',
  height: '20px',
  color: theme.palette.border.regular,
}));

const Wrapper = styled(Typography)({
  whiteSpace: 'break-spaces',
  display: 'flex',
  alignItems: 'center',
});

const TooltipWithMargin = styled(Tooltip)({
  marginLeft: '5px',
});

export function LabelWithTooltip({
  label,
  tooltip,
  dataTest,
  tooltipIconDataTest,
  tooltipDataTest,
}: ILabelWithTooltipProps): JSX.Element {
  return (
    <Wrapper variant="body2" data-test={dataTest}>
      {label}
      {tooltip && (
        <TooltipWithMargin title={tooltip} data-test={tooltipDataTest}>
          <TooltipIcon data-test={tooltipIconDataTest || 'tooltip-icon'} />
        </TooltipWithMargin>
      )}
    </Wrapper>
  );
}
