import type { TooltipProps } from '@mui/material';

export function createMaterialTooltipTheme(
  overrides?: Partial<TooltipProps>,
): Partial<TooltipProps> {
  return {
    placement: 'left',
    enterTouchDelay: 0,
    leaveTouchDelay: 90000,
    disableFocusListener: true,
    ...overrides,
  };
}
