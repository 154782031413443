import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { breakpointsValues } from './constants/materialThemeBreakbointsValues';
import {
  createMaterialAlertTheme,
  createMaterialCheckboxTheme,
  createMaterialSvgIconTheme,
  createMaterialFormHelperTextTheme,
  createMaterialTextFieldTheme,
  createMaterialTooltipTheme,
  createMuiAutocompleteStyles,
  createMuiButtonStyles,
  createMuiFormControlStyles,
  createMuiFormHelperTextStyles,
  createMuiFormLabelStyles,
  createMuiInputBaseStyles,
  createMuiInputLabelStyles,
  createMuiMenuItemStyles,
  createMuiSwitchStyles,
  createMuiTextFieldStyles,
  createMuiFormControlLabelStyles,
  createMaterialButtonTheme,
  createMuiAlertStyles,
} from './helpers';
import { baseTheme } from './baseTheme';

const mainTheme = createTheme(deepmerge(baseTheme, {
  components: {
    MuiSvgIcon: {
      defaultProps: createMaterialSvgIconTheme(),
      styleOverrides: {
        colorPrimary: {
          color: '#17a0cf',
        },
        colorSecondary: {
          color: '#ccc',
        },
        colorAction: {
          color: '#333',
        },
        colorDisabled: {
          color: '#999',
        },
        colorError: {
          color: '#e26578',
        },
        fontSizeSmall: {
          fontSize: '20px',
        },
        fontSizeInherit: {
          fontSize: '35px',
        },
        fontSizeLarge: {
          fontSize: '45px',
        },
        root: {
          fontSize: '25px',

          '& path': {
            color: 'inherit',
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: createMuiAutocompleteStyles(),
    },
    MuiFormControl: {
      styleOverrides: createMuiFormControlStyles(),
    },
    MuiFormControlLabel: {
      styleOverrides: createMuiFormControlLabelStyles(),
    },
    MuiFormLabel: {
      styleOverrides: createMuiFormLabelStyles(),
    },
    MuiInputBase: {
      styleOverrides: createMuiInputBaseStyles(),
    },
    MuiInputLabel: {
      styleOverrides: createMuiInputLabelStyles(),
    },
    MuiMenuItem: {
      styleOverrides: createMuiMenuItemStyles(),
    },
    MuiTooltip: {
      defaultProps: createMaterialTooltipTheme(),
    },
    MuiCheckbox: {
      defaultProps: createMaterialCheckboxTheme(),
    },
    MuiFormHelperText: {
      defaultProps: createMaterialFormHelperTextTheme(),
      styleOverrides: createMuiFormHelperTextStyles(),
    },
    MuiTextField: {
      defaultProps: createMaterialTextFieldTheme(),
      styleOverrides: createMuiTextFieldStyles(),
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'SourceSansPro',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: 1.25,
    },
    h5: {
      fontSize: '18px',
    },
    body2: {
      fontSize: '16px',
    },
    caption: {
      color: baseTheme.palette.text ? baseTheme.palette.text.tertiary : undefined,
    },
    caption2: {
      fontSize: '15px',
    },
  },
  breakpoints: {
    values: {
      ...breakpointsValues,
    },
  },
}));

export const shopTheme = createTheme(mainTheme, {
  components: {
    MuiButton: {
      styleOverrides: createMuiButtonStyles(mainTheme),
      defaultProps: createMaterialButtonTheme(),
    },
    MuiSwitch: {
      styleOverrides: createMuiSwitchStyles(mainTheme.palette),
    },
    MuiAlert: {
      defaultProps: createMaterialAlertTheme(),
      styleOverrides: createMuiAlertStyles(mainTheme),
    },
  },
});
