import type { MouseEvent } from 'react';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { _t } from '../../service';
import './pagination.styl';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

interface IProps {
  current: number;
  listLength: number;
  perPage?: number;
  range?: number;
  onChange: (current: number) => void;
}

export class Pagination extends React.Component<IProps> {
  public render(): JSX.Element | null {
    const {
      current, listLength, perPage = 10, range = 5,
    } = this.props;
    const pageCount = Math.ceil(listLength / perPage);
    const prevPage = Math.max(1, current - 1);
    const nextNext = Math.min(pageCount, current + 1);
    const pageRange = this.range(
      current,
      pageCount,
      Math.min(pageCount, range),
    );
    const pageList = _.map(pageRange, (page: number) => this.renderPaginationItem(current, page));

    if (pageCount > 1) {
      return (
        <nav className="pagination">
          <ul className="inner">
            <li
              className="page-item prev"
              onClick={this.handleClick.bind(this, prevPage)}
            >
              <span>{_t('pagination-caption-prev')}</span>
            </li>

            {pageList}

            <li
              className="page-item next"
              onClick={this.handleClick.bind(this, nextNext)}
            >
              <span>{_t('pagination-caption-next')}</span>
            </li>
          </ul>
        </nav>
      );
    }

    return null;
  }

  private renderPaginationItem = (current: number, page: number): JSX.Element => {
    const classes = classNames('page-item', {
      active: current === page,
    });

    return (
      <li
        key={page}
        className={classes}
        onClick={this.handleClick.bind(this, page)}
      >
        <span>{page}</span>
      </li>
    );
  };

  /**
   * Generate range of visible pages in pagination
   * @param {number} page
   * @param {number} total
   * @param {number} range
   * @returns {number[]}
   */
  private range = (page: number, total: number, range: number): number[] => {
    /**
     * Изначально вычисляется номер первой видимой страницы, для этого от текущей страницы
     * отнимается половина видимого диапазона страниц, и выбирается большее между единицей
     * и полученным числом.
     * Далее это число ограничивается, всего страниц минус видимый диапазон страниц.
     *
     * Пример1: текущая страница 2, видимый диапазон 10 страниц, всего страниц 50.
     *          [половина видимого диапазона] = 10 / 2
     *          [текущая первая видимая страница] = 2 - [половина видимого диапазона] = -3 < 1 = 1
     *          [максимальная первая видимая страница] = 50 + 1 - 10 = 41
     *
     *          start = Math.min(
     *            [максимальная первая видимая страница],
     *            [текущая первая видимая страница]
     *          ),
     *          т.е. первая видимая страница будет 1
     *
     * Пример2: текущая страница 50, видимый диапазон 10 страниц, всего страниц 50.
     *          [половина видимого диапазона] = 10 / 2
     *          [текущая первая видимая страница] = 50 - [половина видимого диапазона] = 45 > 1 = 45
     *          [максимальная первая видимая страница] = 50 + 1 - 10 = 41
     *
     *          Math.min([максимальная первая видимая страница], [текущая первая видимая страница]),
     *          start = Math.min(41, 45)
     *          т.е. первая видимая страница будет 45
     *
     * Далее к первой странице добавляется видимый диапазон и получается последная видимая страница.
     *
     * TODO: Translate to English
     * @type {number}
     */
    const start = Math.min(
      total + 1 - range,
      Math.max(1, page - Math.floor(range / 2)),
    );
    const end = start + range;
    return _.range(start, end);
  };

  private handleClick = (page: number, e: MouseEvent<HTMLLIElement>): void => {
    e.preventDefault();
    const { onChange } = this.props;

    onChange(page);
  };
}
