import { ConstantDependency as BaseConstantDependency } from '@esurance/constants';

export const ConstantDependency = {
  ...BaseConstantDependency,

  AffinityName: Symbol.for('AffinityName'),

  AffinityBmi: Symbol.for('AffinityBmi'),

  /**
   * Probably deprecated
   * @deprecated
   */
  AffinityLabel: Symbol.for('AffinityLabel'),

  AffinityLogoLink: Symbol.for('AffinityLogoLink'),

  AffinityLoginUrl: Symbol.for('AffinityLoginUrl'),

  AffinityDseLink: Symbol.for('AffinityDseLink'),

  AffinityZvLink: Symbol.for('AffinityZvLink'),

  IsLoginRequiredForCheckout: Symbol.for('IsLoginRequiredForCheckout'),

  ConsultantHeaderName: Symbol.for('ConsultantHeaderName'),
} as const;
