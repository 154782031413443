import type { FieldValue, IValidator } from '../interfaces';

const MAX_TEXT_LENGTH = 200;

export class MaxLength implements IValidator {
  constructor(
    private maxTextLength: number = MAX_TEXT_LENGTH,
    public errorMessage: string = 'max-length-invalid-message',
  ) {}

  public validate(value: FieldValue): boolean {
    const normalizedValue = value || '';
    if (normalizedValue.length > MAX_TEXT_LENGTH) return false;
    return true;
  }
}
