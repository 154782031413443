import _ from 'lodash';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpRounded';

import { Loader } from '@esurance/ui-components';
import './panel.styl';

interface IProps {
  title?: string | (() => JSX.Element);
  loading?: boolean;
  stretch?: boolean;
  className?: string;
  sticky?: boolean;
  fixedWidth?: boolean;
  renderChildOnLoading?: boolean;
  renderActions?: (() => ReactNode) | React.ReactNode;
  customHeader?: () => JSX.Element | null;
  isCollapsable?: boolean;
  children?: ReactNode | (() => ReactNode);
}

interface IState {
  expanded: boolean;
}

interface IHeaderProps {
  title?: string | (() => JSX.Element);
  actions: (() => ReactNode) | React.ReactNode;
  custom?: () => JSX.Element | null;
  expanded: boolean;
  toggleCollapsed: () => void;
}

function Header(props: IHeaderProps): JSX.Element | null {
  const {
    title,
    actions = null,
    custom = null,
    expanded = true,
    toggleCollapsed,
  } = props;

  const renderPanelTitle = (): JSX.Element | null => {
    if (_.isString(title)) {
      return <div className="title">{title}</div>;
    }
    if (!title) {
      return null;
    }

    return <div className="element">{title()}</div>;
  };

  if (custom) {
    return custom();
  }

  if (title) {
    return (
      <header>
        {renderPanelTitle()}
        <div className="actions">
          {actions && expanded && (_.isFunction(actions) ? actions() : actions)}
          {!expanded && (
            <ArrowDropUpIcon
              fontSize="small"
              color="disabled"
              onClick={() => toggleCollapsed()}
            />
          )}
        </div>
      </header>
    );
  }

  return null;
}

interface IFooterProps {
  isCollapsable: boolean;
  expanded: boolean;
  toggleCollapsed: () => void;
}

function Footer(props: IFooterProps): JSX.Element {
  const { isCollapsable, expanded, toggleCollapsed } = props;
  const showExpandedToggle = isCollapsable && expanded;
  const isEmpty = !showExpandedToggle;

  if (isEmpty) {
    return (<></>);
  }

  return (
    <footer className="panel-footer">
      {showExpandedToggle && (
        <ArrowDropUpIcon
          fontSize="small"
          color="disabled"
          onClick={() => toggleCollapsed()}
        />
      )}
    </footer>
  );
}

export class Panel extends React.Component<IProps, IState> {
  public state: IState = {
    expanded: true,
  };

  public render(): JSX.Element {
    const {
      fixedWidth,
      loading,
      stretch,
      title,
      sticky,
      renderActions,
      customHeader,
      isCollapsable = false,
      className,
    } = this.props;

    const { expanded } = this.state;

    const classes = classNames(
      {
        'fixed-width': fixedWidth,
        panel: true,
        'panel-loading': loading,
        sticky,
        stretch,
      },
      className,
    );

    return (
      <div className={classes}>
        <Header
          title={title}
          actions={renderActions}
          custom={customHeader}
          expanded={expanded}
          toggleCollapsed={this.toggleCollapsed}
        />

        {expanded && (
          <>
            <main>
              <>
                <Loader loading={loading} />
                {this.contentToRender()}
              </>
            </main>
            <Footer
              isCollapsable={isCollapsable}
              expanded={expanded}
              toggleCollapsed={this.toggleCollapsed}
            />
          </>
        )}

      </div>
    );
  }

  private toggleCollapsed = (): void => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  private contentToRender = (): ReactNode | undefined => {
    const { children } = this.props;

    return _.isFunction(children) ? children() : children;
  };
}
