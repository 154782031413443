import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import type { IAuthManager } from '../managers/interfaces';
import { DependencyType } from '../ioc/DependencyType';

// TODO: AD: need to rewrite from scratch
// TODO: KEYCLOAK: refactor, split into separate components
export const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const authManager = useInjection<IAuthManager>(DependencyType.AuthManager);

  if (!authManager.isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
      />
    );
  }

  return <RouteElementWrapper>{children}</RouteElementWrapper>;
};

export const PublicRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const authManager = useInjection<IAuthManager>(DependencyType.AuthManager);

  if (!authManager.isAuthenticated) {
    return <RouteElementWrapper>{children}</RouteElementWrapper>;
  }

  return (
    <Navigate
      to="/"
      state={{ from: location }}
    />
  );
};

export const RouteElementWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>{children}</>
);
