import './image-uploader.styl';

import _ from 'lodash';
import React from 'react';
import { resolve } from 'inversify-react';
import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';

import { DependencyType, INotificationManager } from '@esurance/services';
import { AlertTypes, SIZE_15_MB } from '@esurance/legacy-constants';

import defaultAvatar from '../../../img/default-user-avatar.svg';
import { _t } from '../../service';

interface IProps {
  description?: string;
  defaultImage?: string;
  buttonLabel?: string;
  multiple?: boolean;
  imageUrl?: string;
  onUpload: (file: File) => void;
  onDelete?: () => void;
}

interface IState {
  imageUrl?: string;
}

export class ImageUploader extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    buttonLabel: _t('drag-n-drop_button-label'),
    defaultImage: defaultAvatar,
    description: _t('image-uploader_description'),
    imageUrl: '#',
    multiple: false,
  };

  @resolve(DependencyType.NotificationManager)
  private notificationManager: INotificationManager;

  constructor(props: IProps) {
    super(props);
    this.state = { imageUrl: props.imageUrl };
  }

  public render(): JSX.Element {
    const {
      defaultImage,
      multiple,
      description,
      buttonLabel,
    } = this.props;
    const { imageUrl } = this.state;

    const source = _.isEmpty(imageUrl)
      ? defaultImage
      : imageUrl;

    return (
      <div className="upload image-uploader">
        <Dropzone
          accept={{ 'image/*': [] }}
          onDrop={this.handleDrop}
          multiple={multiple}
          maxSize={SIZE_15_MB}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="drag-n-drop" aria-disabled="false" {...getRootProps()}>
              <input className="input-zone" {...getInputProps()} />
              <p>{description}</p>
              <button type="button" className="btn with-border mini no-responsive">
                {buttonLabel}
              </button>
            </div>
          )}
        </Dropzone>
        <div className="preview-wrapper">
          <img className="preview" src={source} alt="Preview" />
          {!_.isEmpty(imageUrl) && (
            <IconButton onClick={this.handleDeleteAvatar}><CloseIcon /></IconButton>
          )}
        </div>
      </div>
    );
  }

  private handleDrop = (accepted: File[]): void => {
    const avatar = _.first(accepted);
    if (!avatar) {
      return;
    }
    const { onUpload } = this.props;

    try {
      const source = URL.createObjectURL(avatar);
      this.setState({ imageUrl: source });
      onUpload(avatar);
    } catch {
      this.notificationManager.raise({
        closeable: true,
        content: _t('drag-n-drop_upload-error'),
        onTop: true,
        type: AlertTypes.Error,
      });
    }
  };

  private handleDeleteAvatar = (): void => {
    const newState = { ...this.state };
    const { onDelete } = this.props;
    newState.imageUrl = '';
    this.setState({ ...newState });
    if (onDelete) {
      onDelete();
    }
  };
}
