import _ from 'lodash';

import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type {
  IPolicyDetails,
  IPolicy,
  IPolicyDto,
  IPolicyResponseDto,
  IPolicyDocument,
  IObjectOfInsurance,
  IPolicyDocumentDto,
  IProductType,
  IRequestPolicyDto,
} from '@esurance/entities';
import type {
  ClaimType,
} from '@esurance/legacy-constants';
import { DATE_TIME_FORMAT_BACKEND, parseDate } from '@esurance/shared/utils/dates';

function mapPolicyList(
  item: IPolicyDto,
): IPolicy {
  return {
    company: item.company_name,
    emergency: {
      claimUrl: item.company_details ? item.company_details.claim_url : '',
      international: item.company_details
        ? item.company_details.hotline_international
        : '',
      switzerland: item.company_details ? item.company_details.hotline_ch : '',
    },
    uuid: item.uuid,
    logo: item.logo_url,
    mainProductType: item.main_product_type_id,
    objectsOfInsurance: trancateObjectsOfInsurance(item.objects_of_insurance),
    premium: item.price,
    status: item.status,
    cockpitStatus: item.cockpit_status,
  };
}

function mapPolicy(item: IPolicyResponseDto): IPolicyDetails {
  return {
    cancelable: item.cancellable,
    cancellationPeriod: item.cancellation_period,
    company: item.company ? item.company.name : '',
    companyId: item.company ? item.company.id : -1,
    documents: mapDocuments(item.documents),
    emergency: {
      claimUrl: item.company ? item.company.claim_url : '',
      international: item.company ? item.company.hotline_international : '',
      switzerland: item.company ? item.company.hotline_ch : '',
    },
    endDate: parseDate(item.end_date, DATE_TIME_FORMAT_BACKEND),
    uuid: item.uuid,
    isMain: item.is_main,
    logo: item.company ? item.company.logo_url : '',
    mainProductType: item.main_product_type_id,
    number: item.number,
    objectsOfInsurance: convertObjectsOfInsuranceToString(item.objects_of_insurance),
    premium: item.price,
    priceParameters: item.price_parameters,
    products: item.products,
    startDate: item.start_date ? parseDate(item.start_date, DATE_TIME_FORMAT_BACKEND) : null,
    status: item.status,
    cockpitStatus: item.cockpit_status,
    tariffConfigValues: item.tariff_config_values,
    versions: item.versions.map((v) => ({
      uuid: v.uuid,
      isParent: v.is_parent,
      startDate: parseDate(v.start_date, DATE_TIME_FORMAT_BACKEND),
      updated: parseDate(v.created_at, DATE_TIME_FORMAT_BACKEND),
    })),
  };
}

function convertObjectsOfInsuranceToString(
  objects: IObjectOfInsurance[],
): string {
  return objects
    .map((object) => object.name)
    .join(', ');
}

function trancateObjectsOfInsurance(
  objects: IObjectOfInsurance[],
): string {
  return objects
    .filter((object, index) => index < 2)
    .map((object) => object.name)
    .join(', ') + (objects.length > 2 ? '...' : '');
}

function mapDocuments(
  docs: IPolicyDocumentDto[],
): IPolicyDocument[] {
  return _.map(docs, (doc) => ({
    displayName: doc.display_name,
    fileType: doc.file_type,
    name: doc.name,
    type: doc.type,
    url: doc.url,
  }));
}

function mapPolicySuggestions(id: number): IProductType | undefined {
  return diContainer.constantRepository.getProductById(id);
}

class Policy {
  public getAll(orgId: number): Promise<IPolicy[]> {
    return axios
      .get<IPolicyDto[]>(
        `${diContainer.backofficeApiRoot}/organization/${orgId}/policies`,
      )
      .then((response) => response.data.map(mapPolicyList));
  }

  public get(policyUuid: string): Promise<IPolicyDetails> {
    return axios
      .get<IPolicyResponseDto>(
        `${diContainer.backofficeApiRoot}/policy/${policyUuid}`,
      )
      .then((response) => mapPolicy(response.data));
  }

  public async policySuggestions(
    accountId: number,
  ): Promise<IProductType[]> {
    const response = await axios
      .get<number[]>(
        `${diContainer.backofficeApiRoot}/organization/${accountId}/pre-added-policies`,
      );

    return response.data
      .map((id) => mapPolicySuggestions(id))
      .filter((item) => Boolean(item)) as IProductType[];
  }

  public deletePolicySuggestion(
    id: number,
    accountId: number,
  ): Promise<boolean> {
    return axios
      .delete(
        `${diContainer.backofficeApiRoot}/organization/${accountId}/pre-added-policies/${id}`,
      )
      .then((response) => response.data);
  }

  public requestPolicy(
    data: IRequestPolicyDto,
  ): Promise<boolean> {
    return axios
      .post(`${diContainer.backofficeApiRoot}/policy/request`, data)
      .then(() => true);
  }

  public openClaim(
    policyUuid: string,
    claimType: ClaimType,
  ): Promise<boolean> {
    return axios
      .post(`${diContainer.backofficeApiRoot}/policy/${policyUuid}/claim`, {
        type_id: claimType,
      })
      .then(() => true);
  }

  public requestOptimization(
    optimizationId: number | string,
    accept: boolean,
  ): Promise<boolean> {
    return axios
      .post(
        `${diContainer.backofficeApiRoot}/optimization/${optimizationId}/user-decision`,
        {
          decision: accept ? 'accepted' : 'declined',
        },
      )
      .then(() => true);
  }
}

export const policy = new Policy();
