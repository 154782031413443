import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import type {
  IFormField,
} from '@esurance/domain-components';
import type { ValueType } from '@esurance/ui-components';
import {
  FormItem,
  Select as BaseSelect,
} from '@esurance/ui-components';

export interface ISelectOption {
  value: string;
  title: string;
}

interface IProps {
  name?: string;
  label?: string;
  loading: boolean;
  model: IFormField<string>;
  options: ISelectOption[];
  className?: string;
  required?: boolean;
}

@observer
class SelectView extends React.Component<IProps & WithTranslation> {
  public render(): JSX.Element {
    const {
      name,
      label,
      loading,
      options,
      className,
      required,
      t: translation,
      model,
    } = this.props;

    const classes = classNames(className, {
      invalid: model.isInvalid,
      touched: model.isTouched,
    });

    return (
      <FormItem
        htmlFor={name}
        required={required}
        label={label}
        className={classes}
      >
        <BaseSelect
          isClearable
          isSearchable
          isLoading={loading}
          inputId={name}
          onChange={this.handleChange}
          options={options}
          getOptionLabel={(option: ISelectOption) => option.title}
          getOptionValue={(option: ISelectOption) => option.value}
          placeholder={translation('dropdown-select-value-placeholder')}
          hideDropdownIndicator
        />
      </FormItem>
    );
  }

  private handleChange = (option: ValueType<ISelectOption>): void => {
    const { model } = this.props;
    if (option === null) return;
    const { value } = option as ISelectOption;
    model.change(value);
  };
}

export const Select = withTranslation()(SelectView);
