import Raven from 'raven-js';
import type { AxiosRequestConfig } from 'axios';
import axiosBase from 'axios';
import _ from 'lodash';
import fingerprintjs2 from 'fingerprintjs2';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorHandler = (error: any) => void;
let errorHandler: ErrorHandler = () => undefined;

export function setErrorHandler(handler: ErrorHandler): void {
  errorHandler = handler;
}

const fingerprintPromise = fingerprintjs2.getPromise().then((fingerprintData) => {
  const values = fingerprintData.map((component) => component.value);
  return fingerprintjs2.x64hash128(values.join(''), 31);
});

const isTestMode = window?.location?.href?.indexOf('test=true') > -1;
const trackingId = uuidv4();

axiosBase.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (!config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {};
  }
  // eslint-disable-next-line no-param-reassign
  config.headers['Fingerprint-Hash'] = await fingerprintPromise;
  // eslint-disable-next-line no-param-reassign
  config.headers['x-tracking-id'] = trackingId;
  if (isTestMode) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-TEST-REQUEST'] = true;
  }
  return config;
});

axiosBase.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-prototype-builtins
    if (response.data?.hasOwnProperty('data')) {
      response.data = response.data.data;
    }
    return response;
  },
  (reject) => {
    if (!reject.response) return reject;
    const { config } = reject.response;

    if (!_.isUndefined(config.errorHandle) && config.errorHandle === false) {
      return reject;
    }

    errorHandler(reject);

    const details = `${reject.response.status} - [${reject.config.method}] ${reject.config.url}`;
    const exception = new Error('API request failed');
    exception.name = `API Issue: ${details}`;
    exception.stack = reject.stack;
    Raven.captureException(exception, { extra: reject });

    throw reject;
  },
);

export const axiosWrapper = axiosBase;
export const axios = axiosBase;
