import { axios } from '@esurance/services';
import { diContainer } from '@esurance/ioc-base';
import type { IInsurance } from '@esurance/entities';

class Insurance {
  public getList(): Promise<IInsurance[]> {
    return axios
      .get<IInsurance[]>(
        `${diContainer.backofficeApiRoot}/insurances`,
      )
      .then((response) => response.data);
  }
}

export const insurance = new Insurance();
