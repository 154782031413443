import type { Container } from 'inversify';

import type {
  ConfigRepository,
  ISentryManager,
  ITranslationManager,
  INotificationManager,
  IEventManager,
  IAccountRepository,
  IConstantRepository,
  IAxiosManager,
  IUserAgentManager,
  IHistoryManager,
  ISocketManager,
  FeatureManager,
  IRecaptchaManager,
} from '@esurance/services';
import {
  DependencyType,
} from '@esurance/services';
import type { IAuthManager, IAuthRepository, IKeycloakManager } from '@esurance/auth';
import {
  DependencyType as AuthDependencyType,
} from '@esurance/auth';
import { ConstantDependency } from '@esurance/constants';

import { inversifyContainer } from './inversifyContainer';

export class DiContainer {
  protected inversifyContainer: Container;

  public constructor(ioc: Container) {
    this.inversifyContainer = ioc;
  }

  public get authManager(): IAuthManager {
    return this.inversifyContainer.get<IAuthManager>(AuthDependencyType.AuthManager);
  }

  public get authRepository(): IAuthRepository {
    return this.inversifyContainer.get<IAuthRepository>(AuthDependencyType.AuthRepository);
  }

  public get configRepository(): ConfigRepository {
    return this.inversifyContainer.get<ConfigRepository>(DependencyType.ConfigRepository);
  }

  public get sentryManager(): ISentryManager {
    return this.inversifyContainer.get<ISentryManager>(DependencyType.SentryManager);
  }

  public get translationManager(): ITranslationManager {
    return this.inversifyContainer.get<ITranslationManager>(DependencyType.TranslationManager);
  }

  public get eventManager(): IEventManager {
    return this.inversifyContainer.get<IEventManager>(DependencyType.EventManager);
  }

  public get notificationManager(): INotificationManager {
    return this.inversifyContainer.get<INotificationManager>(DependencyType.NotificationManager);
  }

  public get accountRepository(): IAccountRepository {
    return this.inversifyContainer.get<IAccountRepository>(DependencyType.AccountRepository);
  }

  public get constantRepository(): IConstantRepository {
    return this.inversifyContainer.get<IConstantRepository>(DependencyType.ConstantRepository);
  }

  public get axiosManager(): IAxiosManager {
    return this.inversifyContainer.get<IAxiosManager>(DependencyType.AxiosManager);
  }

  public get userAgentManager(): IUserAgentManager {
    return this.inversifyContainer.get<IUserAgentManager>(DependencyType.UserAgentManager);
  }

  public get affinityApiRoot(): string {
    return this.inversifyContainer.get<string>(ConstantDependency.AffinityApiRoot);
  }

  public get affinityV3ApiRoot(): string {
    return this.inversifyContainer.get<string>(ConstantDependency.AffinityV3ApiRoot);
  }

  public get backofficeApiRoot(): string {
    return this.inversifyContainer.get<string>(ConstantDependency.BackofficeApiRoot);
  }

  public get backofficeV3ApiRoot(): string {
    return this.inversifyContainer.get<string>(ConstantDependency.BackofficeV3ApiRoot);
  }

  public get historyManager(): IHistoryManager {
    return this.inversifyContainer.get<IHistoryManager>(DependencyType.HistoryManager);
  }

  public get socketManager(): ISocketManager {
    return this.inversifyContainer.get<ISocketManager>(DependencyType.SocketManager);
  }

  public get featureManager(): FeatureManager {
    return this.inversifyContainer.get<FeatureManager>(DependencyType.FeatureManager);
  }

  public get recaptchaManager(): IRecaptchaManager {
    return this.inversifyContainer.get<IRecaptchaManager>(DependencyType.RecaptchaManager);
  }

  public get applicationName(): string {
    return this.inversifyContainer.get<string>(ConstantDependency.ApplicationName);
  }

  public get keycloakManager(): IKeycloakManager {
    return this.inversifyContainer.get<IKeycloakManager>(AuthDependencyType.KeycloakManager);
  }
}

export const diContainer = new DiContainer(inversifyContainer);
