import React from 'react';
import type { SvgIconProps } from '@mui/material';
import PhoneIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailIcon from '@mui/icons-material/AlternateEmailRounded';
import MailIcon from '@mui/icons-material/EmailRounded';
import NotificationIcon from '@mui/icons-material/NotificationsRounded';
import QuestionIcon from '@mui/icons-material/QuestionMarkRounded';

import { SupportIcon } from '@esurance/shared';
import type { ActivityTypes } from '@esurance/legacy-constants';

interface IProps extends SvgIconProps {
  type?: ActivityTypes,
}

export const ActivityIcon = (props: IProps) => {
  const { type, fontSize, color } = props;

  if (type === 'call') {
    return <PhoneIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'email') {
    return <EmailIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'mail') {
    return <MailIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'task') {
    return <SupportIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'notification') {
    return <NotificationIcon fontSize={fontSize} color={color} />;
  }

  if (type === 'follow-up') {
    return <QuestionIcon fontSize={fontSize} color={color} />;
  }

  return <PhoneIcon fontSize={fontSize} color={color} />;
};
