import type { IFormSelectOption } from '../../../components/forms';

export const FORM_FIELD_MARKER = Symbol('FORM_FIELD_MARKER');

export interface IFormField<T> {
  value: T;
  title: string;
  options?: IFormSelectOption<T>[];
  change: (newValue: T) => void;
  label?: string;

  isTouched: boolean;
  isChanged: boolean;
  isEmpty: boolean;
  touch: () => void;

  isInvalid: boolean;
  isRequired: boolean;
  isDisabled: boolean;
  showErrorMessage: boolean;
  errorMessage?: string;

  setCustomError(error: string | undefined): void;
  clearCustomError(): void;

  reset: () => void;
  hasDefaultValue: boolean;
}
