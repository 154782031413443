import Raven from 'raven-js';
import type { ReactNode } from 'react';
import React from 'react';

import { Modal } from '@esurance/ui-components';

import { _t } from '../service';

interface IProps {
  borderless?: boolean;
  overlap?: boolean;
  canClose?: boolean;
  explicit?: boolean;
  children: ReactNode;
}

interface IState {
  error?: Error;
}

export class Boundary extends React.Component<IProps, IState> {
  public static defaultProps = {
    borderless: true,
    canClose: false,
    explicit: false,
    overlap: true,
  };

  public state: IState = {};

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  public render(): React.ReactNode {
    const {
      explicit,
      borderless,
      canClose,
      overlap,
      children,
    } = this.props;

    const { error } = this.state;

    if (error || explicit) {
      return (
        <Modal
          isBorderless={borderless}
          isClosable={canClose}
          overlap={overlap}
        >
          <p className="text-center">
            <span className="default-title">Oops..</span>
            <br />
            <br />
            {_t('exception')}
            <br />
            <br />
            {Raven.lastEventId() && (
              <button
                type="button"
                className="btn primary mini"
                onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
              >
                {_t('exception_button')}
              </button>
            )}
          </p>
        </Modal>
      );
    }
    return children;
  }
}
