import type { CheckboxProps } from '@mui/material';
import {
  Box,
  Checkbox as MUICheckbox,
} from '@mui/material';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import React from 'react';

import type { MuiIconSize } from '@esurance/legacy-constants';

import type { ILabelProps } from './_components/Label';
import { Label } from './_components/Label';
import './checkbox.styl';

interface ICheckboxError {
  visible: boolean;
  message: JSX.Element | string;
}

export interface IProps {
  name: string;
  value?: string;
  onChange?: (checked: boolean, value: string) => void;
  label?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  required?: boolean;
  classNames?: string[];
  labelInfo?: () => JSX.Element;
  error?: ICheckboxError;
  size?: MuiIconSize;
  readOnly?: boolean;
  checkboxItemData?: any;
  tip?: string;
  disabled?: boolean;
  color?: CheckboxProps['color']
}

export function Checkbox({
  readOnly,
  required,
  defaultChecked,
  value,
  name,
  size,
  tip,
  error,
  disabled,
  color,
  checkboxItemData,
  classNames: classes,
  label,
  labelInfo,
  onChange,
  checked,
}: IProps): JSX.Element {
  const containerClasses = classNames('checkbox-container', classes, {
    disabled,
    readonly: readOnly,
    required: required && !readOnly,
  }).trim();
  const checkboxClasses = classNames('checkbox', { checked });

  const addProps: Partial<CheckboxProps> = {};
  const labelProps: ILabelProps = {
    label,
    tip,
    extraLabel: labelInfo && labelInfo(),
  };

  if (onChange) {
    addProps.onChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        onChange(event.currentTarget.checked, value || '');
      }
    };
    addProps.checked = checked;
  } else if (checked !== undefined) {
    addProps.checked = checked;
  } else {
    addProps.defaultChecked = defaultChecked;
  }

  return (
    <div className={containerClasses}>
      <label>
        <div className={checkboxClasses} {...checkboxItemData}>
          <MUICheckbox
            disabled={disabled || false}
            required={required}
            value={value}
            name={name}
            readOnly={readOnly}
            size={size}
            color={color}
            {...addProps}
          />
        </div>

        <div className="checkbox-description">
          <Label {...labelProps} />
          {error && <CheckboxError visible={error.visible} message={error.message} />}
        </div>
      </label>
    </div>
  );
}

Checkbox.defaultProps = {
  checkboxItemData: {},
  classNames: [],
};

function CheckboxError({ visible, message }: ICheckboxError): JSX.Element | null {
  if (!visible || !message) {
    return null;
  }

  return (
    <Box
      sx={{
        // TODO: THEMING: $error-color = $darkish-pink = #e26578
        color: '#e26578',
        marginTop: '9px',
      }}
    >
      {message}
    </Box>
  );
}
