import './Tree.styl';

import type { MouseEvent } from 'react';
import React from 'react';

interface ITreeNode<T extends ITreeNode<T>> {
  children?: T[];
}

interface IProps<T> {
  treeData: T[];
  getTreeNodeTitle: (node: T) => React.ReactNode;
  onClick?: (node: T) => void;
}

export class Tree<T extends ITreeNode<T>> extends React.Component<IProps<T>> {
  public render(): JSX.Element {
    const { treeData } = this.props;

    return (
      <div className="tree">
        {this.renderTreeNodes(treeData)}
      </div>
    );
  }

  private renderTreeNodes = (treeNodes: T[]): JSX.Element[] => {
    const { getTreeNodeTitle } = this.props;

    return treeNodes.map((node) => (
      <div className="tree-treenode">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className="tree-treenode-title"
          onClick={(e) => this.handleNodeClick(e, node)}
        >
          {getTreeNodeTitle(node)}
        </div>

        {node.children && (
          <div className="tree-treenode-children">
            {this.renderTreeNodes(node.children)}
          </div>
        )}
      </div>
    ));
  };

  private handleNodeClick = (e: MouseEvent<HTMLDivElement>, node: T): void => {
    e.preventDefault();
    e.stopPropagation();

    const { onClick } = this.props;

    if (onClick) onClick(node);
  };
}
