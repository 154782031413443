import type { IApplicationConfig } from '../models/IApplicationConfig';

import type { IApplicationConfigDto } from './interfaces';

export function parseApplicationDto(dto: IApplicationConfigDto): IApplicationConfig {
  return {
    recaptchaSiteKey: dto.recaptcha_site_key,
    sentryPublicDsn: dto.sentry_public_dsn,
    keycloakUrl: dto.keycloak_url,
    keycloakRealm: dto.keycloak_realm,
    keycloakClientId: dto.keycloak_client_id,
  };
}
