import type { MouseEvent } from 'react';
import React from 'react';
import { Box, Button, styled } from '@mui/material';

import type { OptionType } from '../types';

const RadioListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const OptionItem = styled(
  Button,
  { shouldForwardProp: (prop) => prop !== 'isActive' },
)<{ isActive: boolean }>(({ theme, isActive }) => ({
  textAlign: 'left',
  backgroundColor: isActive ? theme.palette.primary.light : theme.palette.background.paper,
  border: `1px solid ${isActive ? theme.palette.primary.main : theme.palette.border.regular}`,
  justifyContent: 'start',
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 3),
  '&:active,&:focus,&:hover': {
    borderColor: theme.palette.border.regularHover,
  },
}));

interface IProps<V> {
  options: ReadonlyArray<OptionType<V>>;
  value: OptionType<V> | null;
  onChange: (option: OptionType<V>) => void;
  className?: string;
}

export class RadioList<V = string> extends React.PureComponent<IProps<V>> {
  public render(): JSX.Element {
    const { className, options, value } = this.props;

    const renderedOptions = options.map((option, index) => {
      const isActive = Boolean(value && value.value === option.value);

      return (
        <OptionItem
          isActive={isActive}
          key={option.label + index}
          data-test={`option_${option.value}`}
          onClick={(event) => this.handleOptionChange(event, option)}
          size="medium"
        >
          {option.label}
        </OptionItem>
      );
    });

    return (
      <RadioListContainer className={className} data-test="radio-list">
        {renderedOptions}
      </RadioListContainer>
    );
  }

  private handleOptionChange = (
    event: MouseEvent,
    option: OptionType<V>,
  ): void => {
    event.preventDefault();
    const { onChange } = this.props;
    onChange(option);
  };
}
