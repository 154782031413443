import './Checkbox.styl';

import type { ChangeEventHandler } from 'react';
import React from 'react';
import classNames from 'classnames';

import type { IconColor } from '@esurance/legacy-constants';

export interface ICheckboxProps {
  value?: string;
  checked: boolean;
  indeterminate?: boolean;
  color?: IconColor;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

export function Checkbox(
  props: React.PropsWithChildren<ICheckboxProps>,
): JSX.Element {
  const {
    checked,
    indeterminate,
    children,
    onChange,
    color,
    disabled,
  } = props;
  const className = classNames('es-checkbox', color, {
    'es-checkbox-checked': checked,
    disabled,
  });

  return (
    <div className={className}>
      <span className="es-checkbox-wrapper">
        <input
          checked={checked}
          className="es-checkbox-input"
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          ref={(el) => {
            // eslint-disable-next-line no-param-reassign
            if (el) el.indeterminate = !!indeterminate;
          }}
        />
        <div className="es-checkbox-inner" />
      </span>

      {children && <span className="es-checkbox-label">{children}</span>}
    </div>
  );
}
