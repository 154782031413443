import './Loader.styl';

import React from 'react';
import classNames from 'classnames';

import { LoaderPosition } from '@esurance/legacy-constants';

interface ILoaderProps {
  loading?: boolean;
  position?: LoaderPosition;
  className?: string;
}
export function Loader({
  loading = false,
  position = LoaderPosition.Absolute,
  className,
}: ILoaderProps): JSX.Element {
  return (
    <div
      className={classNames(
        'loader',
        className,
        position === LoaderPosition.Absolute ? '' : position,
        {
          visible: loading,
        },
      )}

    />
  );
}
