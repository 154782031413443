import type { TextFieldProps } from '@mui/material';

export function createMaterialTextFieldTheme(
  overrides?: Partial<TextFieldProps>,
): Partial<TextFieldProps> {
  return {
    variant: 'outlined',
    ...overrides,
  };
}
