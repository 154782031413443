import './badge.styl';

import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import { BadgeType } from '@esurance/legacy-constants';

interface IProps {
  style?: BadgeType | string;
  children: ReactNode;
}

export class Badge extends React.Component<IProps> {
  public static defaultProps = {
    style: BadgeType.Default,
  };

  public render(): JSX.Element {
    const { style, children } = this.props;
    const classes = classNames('badge', style);

    return <span className={classes}>{children}</span>;
  }
}
