import React from 'react';
import './html-preview.styl';

export class HtmlPreview extends React.Component<React.PropsWithChildren> {
  public render(): JSX.Element {
    const { children } = this.props;

    return (
      <div className="html-preview">
        <div className="html-preview-inner">{children}</div>
      </div>
    );
  }
}
