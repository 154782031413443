import classnames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import './tip.styl';
import { sanitize } from 'dompurify';

/**
 * @deprecated
 */
interface IProps {
  content: string;
  width: number | string;
  children: ReactNode;
}

/**
 * @deprecated
 */
interface IState {
  hover: boolean;
  leftPosition?: number;
}

/**
 * @deprecated
 */
export class Tip extends React.Component<IProps, IState> {
  public state: IState = {
    hover: false,
    leftPosition: undefined,
  };

  private tipDiv: HTMLDivElement | null = null;

  public render(): JSX.Element {
    const { width: widthProps, children, content } = this.props;
    const { leftPosition, hover } = this.state;
    const width = typeof widthProps === 'string' ? widthProps : `${widthProps}px`;

    const styles = {
      left: leftPosition,
      width,
    };

    const classes = classnames('tip-wrap', {
      hover,
    });

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        ref={(tipDiv) => {
          this.tipDiv = tipDiv;
          return tipDiv;
        }}
        className="tip"
        onClick={this.handleClick}
        onMouseOver={this.handleTipPopup}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <div className={classes}>{children}</div>
        <div className="tip-popup">
          <div style={styles} className="tip-popup-content">
            {sanitize(content)}
          </div>
        </div>
      </div>
    );
  }

  private handleClick = (e: React.SyntheticEvent<any>): void => {
    e.preventDefault();
    e.stopPropagation();
    const { hover } = this.state;

    if (hover) {
      this.setState({ hover: false });
    } else {
      this.handleTipPopup();
    }
  };

  private handleTipPopup = (): void => {
    if (this.tipDiv && this.tipDiv.parentElement) {
      const { width } = this.props;
      const parentDiff = this.tipDiv.offsetLeft - this.tipDiv.parentElement.offsetLeft;
      const left = parentDiff > width
        ? -width + 15
        : Math.min(-parentDiff, parentDiff);

      this.setState({ hover: true, leftPosition: left });
    }
  };
}
