import type {
  IConfigRepositoryConfig,
  ITranslationConfig,
  IHistoryManagerConfig,
} from '@esurance/services';

import type { DiContainer } from './DiContainer';
import { diContainer } from './DiContainer';

export interface IServicesConfig {
  translation: ITranslationConfig;
  configRepository: Partial<IConfigRepositoryConfig>;
  historyManager: IHistoryManagerConfig;
  keycloakManager?: boolean;
}

export async function initServices(
  config: IServicesConfig,
  container: DiContainer = diContainer,
): Promise<void> {
  const {
    translation,
    configRepository,
    historyManager,
  } = config;
  container.configRepository.init(configRepository);

  if (config.keycloakManager === undefined || config.keycloakManager) {
    await container.keycloakManager.init();
  }

  container.historyManager.init(historyManager);
  container.axiosManager.init();
  container.recaptchaManager.init();

  await Promise.all([
    container.translationManager.init(translation),
    container.socketManager.init(),
    container.constantRepository.init(),
  ]);
}
