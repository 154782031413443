import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { _t } from '../../service';
import './password-input.styl';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  placeholder?: string;
  validationMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class PasswordInput extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    placeholder: '',
    validationMessage: '',
  };

  public state = {
    type: 'password',
  };

  private input: HTMLInputElement | null;

  public render(): JSX.Element {
    const {
      label,
      name,
      placeholder,
      validationMessage,
      ...props
    } = this.props;

    const { required, readOnly } = this.props;
    const { type } = this.state;
    const toggleClassNames = classNames({
      disabled: !this.passwordVisible(),
      eye: true,
      icon: true,
    });

    return (
      <fieldset
        className={classNames({
          required: required && !readOnly,
        })}
      >
        <label htmlFor={name}>{label}</label>
        <div className="input-control password">
          <input
            ref={(input) => {
              this.input = input;
              return input;
            }}
            placeholder={placeholder}
            name={name}
            id={name}
            {...props}
            onChange={this.handleInputChange}
            type={type}
          />

          <input
            autoComplete="off"
            type="password"
            className="off-autocomplete"
          />
          <div className="toggle">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <i
              className={toggleClassNames}
              onClick={this.togglePasswordVisibility}
            />
          </div>
        </div>

        <div className="error">
          {_.isEmpty(validationMessage)
            ? _t('input_default-invalid-message')
            : validationMessage}
        </div>
      </fieldset>
    );
  }

  private passwordVisible(): boolean {
    const { type } = this.state;
    return type !== 'password';
  }

  private handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }
  };

  private togglePasswordVisibility = (): void => {
    this.setState({
      type: this.passwordVisible() ? 'password' : 'text',
    });
  };
}

export default PasswordInput;
