import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import { ConstantDependency } from '@esurance/constants';

import { DependencyType } from '../DependencyType';

import type {
  ConfigKey, IConfig, IConfigRepository, IConfigRepositoryConfig,
} from './interfaces';

/* eslint-disable  @typescript-eslint/no-explicit-any */

function getLocalStorageConfigKey(localStoragePrefix: string): string {
  return `${localStoragePrefix}_CONFIG_SETTINGS`;
}

function getLocalStorageTokenKey(localStoragePrefix: string): string {
  return `${localStoragePrefix}_CONFIG_ACCESS_TOKEN`;
}

@injectable()
export class ConfigRepository implements IConfigRepository {
  private settings: IConfig = {};

  private config: IConfigRepositoryConfig;

  public init(config: Partial<IConfigRepositoryConfig>): void {
    this.config = {
      configKey: getLocalStorageConfigKey(this.localStoragePrefix),
      tokenKey: getLocalStorageTokenKey(this.localStoragePrefix),
      ...config,
    };

    this.load();
  }

  public constructor(
    @inject(DependencyType.LocalStorage)
    private storage: Storage,
    @inject(ConstantDependency.LocalStoragePrefix)
    private localStoragePrefix: string,
  ) {
    makeAutoObservable(this);
  }

  private load(): void {
    const { configKey } = this.config;
    const strData = this.storage.getItem(configKey);
    this.settings = strData ? JSON.parse(strData) : this.settings;
  }

  public get get(): Readonly<IConfig> {
    return this.settings;
  }

  public set(key: ConfigKey, value: IConfig[ConfigKey]): void {
    this.settings = { ...this.settings, [key]: value };
    this.save();
  }

  private save(): void {
    const strData = JSON.stringify(this.settings);
    this.storage.setItem(this.config.configKey, strData);
  }

  public clearConfig(): void {
    this.settings = {};
    this.storage.removeItem(this.config.configKey);
  }
}
