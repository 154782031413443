import type { ILocalStorageManager, LocalStorageDataValidator } from './interfaces';

export class LocalStorageManager<T> implements ILocalStorageManager<T> {
  public constructor(
    private key: string,
    private validate?: LocalStorageDataValidator<T>,
  ) {}

  public save(value: T): boolean {
    if (!this.isValidData(value)) {
      // eslint-disable-next-line no-console
      console.error('LocalStorageManager: fail to save invalid data');
      return false;
    }
    const data = JSON.stringify(value);
    localStorage.setItem(this.key, data);
    return true;
  }

  public load(): T | null {
    let result: T | null = null;

    try {
      const value = localStorage.getItem(this.key);
      if (value) {
        try {
          result = JSON.parse(value);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.debug('LocalStorageManager: Fail to parse data:', this.key, value);
        } finally {
          if (result && !this.isValidData(result)) {
            // eslint-disable-next-line no-console
            console.warn('LocalStorageManager: invalid data in storage.');
            result = null;
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('localStorage is not available', e);
    }

    return result;
  }

  public clear(): void {
    localStorage.removeItem(this.key);
  }

  private isValidData(data: T): boolean {
    return !this.validate || this.validate(data);
  }
}
