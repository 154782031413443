import type { IValidator } from '../interfaces';

export class ZipCode implements IValidator {
  constructor(public errorMessage: string = 'postal-code-invalid-message') {}

  public validate(value: string): boolean {
    return (!!value
      && value.trim().length === 4
      && Boolean(value.match(/\d{4}/)));
  }
}
