import type { IconButtonProps } from '@mui/material';

export function createMaterialIconButtonTheme(
  overrides?: Partial<IconButtonProps>,
): Partial<IconButtonProps> {
  return {
    size: 'small',
    ...overrides,
  };
}
