export enum FeatureName {
  insuranceCompanyManagement = 'insuranceCompanyManagement',
  organizationManagement = 'organizationManagement',
  partnerManagement = 'partnerManagement',
  contactManagement = 'contactManagement',
  policyManagement = 'policyManagement',
  activitiesManagement = 'activitiesManagement',
  settings = 'settings',
  dashboardManagement = 'dashboardManagement',
  ordersManagement = 'ordersManagement',
}

export type FeatureConfig = {
  [featureName in FeatureName]?: {
    enabled?: boolean;
    defaultFeature?: boolean;
    options?: Record<string, unknown>;
  };
};

export interface IFeatureManager {
  init(config: FeatureConfig): void;
  isEnabled(featureName: FeatureName): boolean;
  getOptions<T extends Record<string, unknown>>(featureName: FeatureName): T;
  defaultPath: string;
}
