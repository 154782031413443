import React from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { _t } from '@esurance/shared/service';
import { Icon } from '@esurance/ui-components';
import type {
  IconColor,
  IconSize,
  IconType,
  ProductTypes,
} from '@esurance/legacy-constants';
import {
  ProductTypeIconMap,
} from '@esurance/legacy-constants';
import './ProductComparisonTable.styl';

interface IProps {
  data: IComparisonItem[];
  name: string;
  type: ProductTypes;
  logoIconSize?: IconSize;
  iconColor?: IconColor;
}

export interface IComparisonItem {
  title: string;
  subtitle: string;
  productInclude: boolean;
  productIncludeInfo: string;
  standardInclude: boolean;
  standardIncludeInfo: string;
}

class ProductComparisonTableView extends React.Component<IProps & WithTranslation> {
  public render(): JSX.Element {
    const {
      data,
      name,
      type,
      logoIconSize,
      t: translate,
      iconColor,
    } = this.props;
    const icon = ProductTypeIconMap[type];

    const rows = data.map((item, i) => (
      // eslint-disable-next-line
      <tr key={i}>
        <td className="item-description">
          <p className="title">{translate(item.title)}</p>
          <p className="subtitle">{translate(item.subtitle)}</p>
        </td>
        <td className="item-value product">
          <Icon
            type={this.getIconType(item.productInclude)}
            size="xs"
            iconSize="xs"
            color={this.getIconColor(item.productInclude)}
          />
          <span className="include-info">{translate(item.productIncludeInfo)}</span>
        </td>
        <td className="item-value standard">
          <Icon
            type={this.getIconType(item.standardInclude)}
            size="xs"
            iconSize="xs"
            color={this.getIconColor(item.standardInclude)}
          />
          <span className="include-info">{translate(item.standardIncludeInfo)}</span>
        </td>
      </tr>
    ));

    return (
      <table className="product-comparison-table">
        <thead>
          <tr>
            <th />
            <th>
              <Icon
                type={icon}
                color={iconColor}
                size={logoIconSize || 's'}
                iconSize={logoIconSize || 's'}
              />
              {translate(name)}
            </th>
            <th>{_t('product-market-comparison_standard')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  private getIconType(include: boolean): IconType {
    return include ? 'check-mark' : 'cross-mark';
  }

  private getIconColor(include: boolean): IconColor {
    return include ? 'lime-green' : 'strong-red';
  }
}

export const ProductComparisonTable = withTranslation()(ProductComparisonTableView);
