import classNames from 'classnames';
import _ from 'lodash';
import type { ChangeEvent } from 'react';
import React from 'react';
import type {
  TextFieldProps,
  InputProps,
} from '@mui/material';
import {
  TextField,
  InputAdornment,
  FormControl,
} from '@mui/material';

import { _t } from '../../service';

import { Label } from './_components/Label';
import './input.styl';

/**
 * @deprecated
 */
interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  defaultValue?: any;
  extraLabel?: JSX.Element;
  label?: string;
  name?: string;
  prefix?: React.ReactNode;
  tip?: string;
  type?: string | 'textarea';
  urlinput?: boolean;
  valid?: any;
  validationMessage?: string;
  value?: any;
}

/**
 * @deprecated
 */
export interface IInputProps extends IProps {
  value?: string | string[] | number;
  defaultValue?: string | string[] | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean;
}

/**
 * @deprecated
 */
interface IState {
  touched: boolean;
}

/**
 * @deprecated
 */
export class Input extends React.Component<IInputProps, IState> {
  public static defaultProps = {
    defaultValue: '',
    validationMessage: '',
  };

  public state: IState = {
    touched: false,
  };

  private input?: HTMLInputElement | HTMLTextAreaElement;

  public static getDerivedStateFromProps(nextProps: IInputProps, prevState: IState): IState | null {
    if (prevState.touched !== nextProps.touched) {
      return { touched: !!nextProps.touched };
    }
    return null;
  }

  public componentWillUnmount(): void {
    this.input = undefined;
  }

  public focus(): void {
    if (this.input) {
      this.input.focus();
    }
  }

  public render(): JSX.Element {
    const {
      label,
      className,
      validationMessage,
      valid = true,
      name,
      ...rest
    } = this.props;

    const {
      type,
      required,
      readOnly,
      urlinput,
      prefix,
    } = this.props;
    const { touched } = this.state;
    const id = `${name}${type}`;

    const InputProps: InputProps = {
      ref: this.setRef,
    };

    if (prefix) {
      InputProps.startAdornment = (
        <InputAdornment position="start">{prefix}</InputAdornment>
      );
    }

    // @ts-ignore
    const propsToPass: Partial<TextFieldProps> = {
      ...(_.omit(rest, 'touched', 'urlinput')),
      className: classNames({
        invalid: !valid,
        touched,
      }),
      id,
      name,
      InputProps,
      onBlur: this.handleBlur,
    };

    const controlled = 'value' in this.props && 'onChange' in this.props;

    if (controlled) {
      delete propsToPass.defaultValue;
    } else {
      delete propsToPass.value;
    }

    const classes = classNames(className, {
      required: required && !readOnly,
      'url-input': urlinput,
    });

    const errorMessage = (!valid && touched) && (
      validationMessage || _t('input_default-invalid-message')
    );

    return (
      <FormControl className={classes}>
        <Label
          id={id}
          label={label}
          extraLabel={this.props.extraLabel}
          tip={this.props.tip}
        />
        <TextField
          variant="outlined"
          error={!!errorMessage}
          helperText={errorMessage}
          {...propsToPass}
        />
      </FormControl>
    );
  }

  private setRef = (input: any): void => {
    this.input = input;
  };

  private handleBlur = (event: any): void => {
    const { onBlur } = this.props;
    if (this.input) {
      this.setState({
        touched: true,
      });

      if (onBlur) {
        onBlur(event);
      }
    }
  };
}
