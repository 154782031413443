import type { ButtonProps } from '@mui/material';

export function createMaterialButtonTheme(
  overrides?: Partial<ButtonProps>,
): Partial<ButtonProps> {
  return {
    disableRipple: true,
    ...overrides,
  };
}
