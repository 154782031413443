import './BreadcrumbsContainer.styl';

import React from 'react';
import classNames from 'classnames';

import type { IRoutes } from '@esurance/entities';

interface IBreadcrumbsContainerState {
  isScrollDirectionDown: boolean;
  scrollPosition: number;
}

interface IBreadcrumbsContainerProps {
  route: IRoutes;
}

export class BreadcrumbsContainer
  extends React.Component<IBreadcrumbsContainerProps, IBreadcrumbsContainerState> {
  public state = {
    isScrollDirectionDown: false,
    scrollPosition: window.pageYOffset,
  };

  public componentDidMount = (): void => {
    window.addEventListener('scroll', this.handleScroll);
  };

  public componentWillUnmount = (): void => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  private handleScroll = (): void => {
    const { scrollPosition } = this.state;

    const curScrollPosition = window.pageYOffset;
    const isScrollDirectionDown = curScrollPosition > scrollPosition && curScrollPosition > 0;

    this.setState(() => ({
      scrollPosition: curScrollPosition,
      isScrollDirectionDown,
    }));
  };

  public render(): JSX.Element | null {
    const { isScrollDirectionDown } = this.state;
    const { route } = this.props;
    const { breadcrumbs } = route;
    if (!breadcrumbs || breadcrumbs.length === 0) {
      return null;
    }
    const props = { route };

    const items = breadcrumbs.map((BreadcrumbItem, index: number) => {
      const addSeparator = index < breadcrumbs.length - 1;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>
          <BreadcrumbItem {...props} />
          {addSeparator && (
            <div className="separator" />
          )}
        </span>
      );
    });

    const classes = classNames('breadcrumbs-container', {
      'scroll-direction-down': isScrollDirectionDown,
    });

    return (
      <div className={classes}>
        <div className="breadcrumbs-items">
          {items}
        </div>
      </div>
    );
  }
}
